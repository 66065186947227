<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>业务中心</el-breadcrumb-item>
      <el-breadcrumb-item>专线管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加专线</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="140px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="head">添加专线</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="所属公司:" prop="logisticsCompanyName">
              <el-autocomplete class="dialog-input" style="width:100%;" v-model="addForm.logisticsCompanyName" :fetch-suggestions="querySearch" placeholder="请输入公司名称" :trigger-on-focus="false" @select="handleSelect" clearable></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发布账号:">
              <el-input class="dialog-input" v-model="addForm.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="出发地:" prop="startCity">
              <template>
                <el-cascader clearable style="width:100%;" v-model="addStartCity" :props="defultProp" :options="address" @change="handleChange"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的地:" prop="destinationCity">
              <template>
                <el-cascader clearable style="width:100%;" v-model="addDestinationCity" :props="defultProp" :options="address" @change="handleDestinationChange"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <el-form-item label="状态:" prop="state">
              <el-radio-group v-model="addForm.state" style="margin-top: 7px;">
                <el-radio label="1">发布</el-radio>
                <el-radio label="0">下架</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="goBack">取 消</el-button>
      </span>
    </el-card>
  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      state1: '',
      restaurants: [],
      addStartCity: [],
      addDestinationCity: '',
      // 添加设备表单数据
      addForm: {
        destinationCity: '0',
        destinationDistrict: '',
        destinationProvince: '',
        startCity: '',
        startDistrict: '',
        startProvince: '',
        state: '1',
        logisticsCompanyId: '',
        logisticsCompanyName: '',
        phone: '',
        shipperName: '',
        userId: ''
      },
      addFormRules: {
        destinationCity: [
          { required: true, message: '请选择目的地', trigger: 'change' }
        ],
        startCity: [
          { required: true, message: '请选择出发地', trigger: 'change' }
        ],
        state: [
          { required: true, message: '请选择专线状态', trigger: 'change' }
        ]
      },
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      address: []
    }
  },
  created() {
    // this.getMemberList(this.queryInfo)
    this.address = json
  },
  mounted() {},
  methods: {
    goBack() {
      this.$refs.addFormRef.resetFields()
      this.$router.back(-1)
    },
    querySearch(queryString, cb) {
      this.loadUserList(queryString, cb)
    },
    async loadUserList(str, cb) {
      var dic = {
        pageNum: 1,
        pageSize: 10
      }
      dic.userType = '02.logistics'
      dic.logisticsCompanyName = str
      const { data: res } = await this.$http.get(
        '/user/goodsSource/user/list',
        {
          params: dic
        }
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      res.data.forEach((item) => {
        item.value = item.logisticsCompanyName
      })
      this.restaurants = res.data
      cb(this.restaurants)
    },
    handleSelect(item) {
      this.addForm.logisticsCompanyName = item.logisticsCompanyName
      this.addForm.logisticsCompanyId = item.logisticsCompanyId
      this.addForm.phone = item.phone
      this.addForm.userId = item.id
    },
    handleChange(city) {
      if (city[2] === '全市') {
        this.addStartCity = []
        this.$message.info('请重新选择具体的区县')
        return false
      }
      this.addForm.startProvince = city[0]
      this.addForm.startCity = city[1]
      this.addForm.startDistrict = city[2]
    },
    handleDestinationChange(city) {
      this.addForm.destinationProvince = city[0]
      this.addForm.destinationCity = city[1]
      this.addForm.destinationDistrict = city[2]
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        if (
          this.addForm.userId === '' ||
          this.addForm.logisticsCompanyId === ''
        ) {
          this.$message.info('请输入公司关键字并选择匹配的公司')
          return false
        }
        const { data: res } = await this.$http.post(
          '/logisticsLine/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.$router.back(-1)
      })
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.branchOfficeView {
  width: 400px;
  padding: 20px 20px 0 0;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.lineView {
  width: 300px;
  padding: 20px;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.closeImg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 90%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>

<style lang="less" scoped>
.tabView {
  background-color: white;
  margin-bottom: 10px;
}
.el-radio {
  font-weight: 400;
}
.dialog-row {
  margin-bottom: 0;
}
.dialog-row-title {
  margin-top: 20px;
  margin-bottom: 0;
}

/* .dialog-input >>> .el-input__inner {
  height: 50px;
} */
</style>
