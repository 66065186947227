<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>运营中心</el-breadcrumb-item>
      <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="90px">
        <el-row type="flex">
          <el-col :span="5">
            <el-form-item label="问题类型">
              <el-select v-model="searchForm.category" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="使用意见" value="1"></el-option>
                <el-option label="使用问题" value="2"></el-option>
                <el-option label="其他反馈" value="99"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="状态">
              <el-select v-model="searchForm.state" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="待处理" value="1"></el-option>
                <el-option label="处理中" value="2"></el-option>
                <el-option label="已处理" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" class="el-search-col">
            <el-form-item label="反馈时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">问题数量：{{total}}</div>
        </div>
        <div class="report">
          <el-button size="small" style="width: 100px; font-size: 14px;background-color: #E33434;color:white;" v-if="isAuth('/operation/feedbackManagement/delete')" @click="deleteFeedbacksConfirm">批量删除</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="feedbackList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" @selection-change="handleSelectionChange" stripe>
        <el-table-column type="selection" width="40">
        </el-table-column>
        <el-table-column label="联系方式" align="center" prop="contact"></el-table-column>
        <el-table-column label="问题类型" align="center" prop="categoryText"></el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.url!==''&&scope.row.url!==null" :src="scope.row.url" alt="" style="margin-top:8px;width:30px;height:30px;">
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="问题描述" align="center" prop="description" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待处理</div>
            <div v-else-if="scope.row.state == 2">处理中</div>
            <div v-else>已处理</div>
          </template>
        </el-table-column>
        <el-table-column label="反馈时间" align="center" prop="createTime" min-width="140"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/operation/feedbackManagement/delete')" @click="deleteFeedbackConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" style="margin-left: 20px; color: #1890FF" v-if="isAuth('/operation/feedbackManagement/edit')" @click="showDetailView(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog title="意见反馈" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">问题类型:</span>
            <span class="item-right" v-if="detail.category === 1">使用意见</span>
            <span class="item-right" v-else-if="detail.category === 2">使用问题</span>
            <span class="item-right" v-else>其他反馈</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">问题描述:</span>
            <span class="item-right">{{detail.description}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">反馈图片:</span>
            <div class="item-right">
              <div v-for="(item, index) in detail.images" :key="index">
                <el-image class="image" :src="item" :preview-src-list="detail.images">
                </el-image>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">联系方式:</span>
            <span class="item-right">{{detail.contact}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">状态:</span>
            <span class="item-right" style="width:250px;">
              <el-select v-model="detail.stateText" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="待处理" value="待处理"></el-option>
                <el-option label="处理中" value="处理中"></el-option>
                <el-option label="已处理" value="已处理"></el-option>
              </el-select>
            </span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <span class="line"></span>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left" style="color:#d9d9d9;">来源账号:</span>
            <span class="item-right" style="color:#d9d9d9;">{{detail.phone}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left" style="color:#d9d9d9;">反馈时间:</span>
            <span class="item-right" style="color:#d9d9d9;">{{detail.createTime}}</span>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        category: '',
        createTimeEnd: '',
        createTimeStart: '',
        state: ''
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      feedbackList: [],
      feedbackIds: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      detail: {}
    }
  },
  created() {
    this.getFeedbackList(this.queryInfo)
  },
  methods: {
    async getFeedbackList(dic) {
      const { data: res } = await this.$http.get('/feedback/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.forEach((item) => {
        if (item.category === 1) {
          item.categoryText = '使用意见'
        } else if (item.category === 2) {
          item.categoryText = '使用问题'
        } else {
          item.categoryText = '其他反馈'
        }
        if (item.picUrl !== '' && item.picUrl !== null) {
          if (item.picUrl.indexOf(',') !== -1) {
            item.url = item.picUrl.split(',')[0]
          } else {
            item.url = item.picUrl
          }
        }
      })
      this.feedbackList = arr
      this.total = res.total
    },
    changeTime() {
      console.log(this.dateRange)
      if (this.dateRange !== null) {
        this.searchForm.createTimeStart = this.dateRange[0]
        this.searchForm.createTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.createTimeStart = ''
        this.searchForm.createTimeEnd = ''
      }
    },
    refresh() {
      this.searchForm.createTimeEnd = ''
      this.searchForm.createTimeStart = ''
      this.dateRange = []
      this.$refs.searchFormRef.resetFields()
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.getFeedbackList(this.queryInfo)
    },
    deleteFeedbacksConfirm() {
      if (this.feedbackIds.length === 0) {
        this.$message.error('请选择需要删除的反馈信息！')
      } else {
        this.$confirm('此操作将删除这些反馈信息, 是否继续?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var list = this.feedbackIds
            var arr = []
            list.forEach((item) => {
              arr.push(item.id)
            })
            this.deleteFeedbacks(arr)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    async deleteFeedbacks(ids) {
      var dic = {
        id: ids
      }
      const { data: res } = await this.$http.post('/feedback/delete', dic)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.queryInfo.pageNum = 1
      this.getFeedbackList(this.queryInfo)
    },
    deleteFeedbackConfirm(id) {
      this.$confirm('此操作将删除这个意见反馈, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.feedbackIds = []
          this.feedbackIds.push(id)
          this.deleteFeedbacks(this.feedbackIds)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showDetailView(id) {
      this.getFeedbackDetail(id)
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
      }, 100)
    },
    handleSelectionChange(e) {
      this.feedbackIds = e
    },
    async getFeedbackDetail(id) {
      const { data: res } = await this.$http.get('/feedback/detail/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      var dic = res.data
      if (dic.state === 1) {
        dic.stateText = '待处理'
      } else if (dic.state === 2) {
        dic.stateText = '处理中'
      } else {
        dic.stateText = '已处理'
      }
      dic.images = []
      if (dic.picUrl !== '' && dic.picUrl !== null) {
        if (dic.picUrl.indexOf(',') !== -1) {
          dic.images = dic.picUrl.split(',')
        } else {
          dic.images.push(dic.picUrl)
        }
      }
      this.detail = dic
      this.addDialogVisible = true
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getFeedbackList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getFeedbackList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getFeedbackList(this.searchForm)
    },
    async submitForm() {
      var params = this.detail
      if (params.stateText === '待处理') {
        params.state = 1
      } else if (params.stateText === '处理中') {
        params.state = 2
      } else {
        params.state = 3
      }
      const { data: res } = await this.$http.post('/feedback/save', params)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('修改成功')
      this.addDialogVisible = false
      this.getFeedbackList(this.queryInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.auth-item {
  width: 100%;
  display: flex;
  align-items: center;
}
.item-left {
  width: 15%;
  font-size: 14px;
  text-align: right;
  margin-right: 30px;
}
.item-right {
  width: 80%;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.line {
  width: 100%;
  border-top: 1px solid #eee;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-cascader--mini {
  width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
