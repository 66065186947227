<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>服务协议</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <el-button size="small" type="primary" style="width: 100px; font-size: 14px" @click="showAddView()">添加协议</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="protocolList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="协议类型" align="center" prop="category"></el-table-column>
        <el-table-column label="版本号" align="center" prop="versionCode"></el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime" min-width="130px"></el-table-column>
        <el-table-column label="当前状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">正常</div>
            <div v-else style="color:#E33434">禁用</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="更改状态" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type"></el-switch>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" @click="deleteDeviceConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" @click="showEditView(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog :title="isEdited?'修改协议':'添加协议'" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="95px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <!-- <el-form-item label="协议类型:" prop="category" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.category" placeholder="请输入协议类型"></el-input>
            </el-form-item> -->
            <el-form-item label="协议类型" prop="payMode">
              <el-select v-model="addForm.category" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="" value="用户协议">用户协议</el-option>
                <el-option label="" value="用户隐私协议">用户隐私协议</el-option>
                <el-option label="" value="用户服务协议">用户服务协议</el-option>
                <el-option label="" value="隐私保护政策">隐私保护政策</el-option>
                <el-option label="" value="押金协议">押金协议</el-option>
                <el-option label="" value="会员协议">会员协议</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="版本号:" prop="versionCode" style="height:40px">
              <el-input class="dialog-input" type="number" v-model="addForm.versionCode" placeholder="请输入协议版本号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="状态:" prop="state">
              <el-radio-group v-model="addForm.state" style="margin-top: 7px;">
                <el-radio label="0">禁用</el-radio>
                <el-radio label="1">正常</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="协议内容:" prop="content">
              <el-input class="dialog-input" :rows="4" :autosize="{minRows:4,maxRows:14}" type="textarea" v-model="addForm.content" placeholder="请输入协议内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="isEdited === true" @click="submitForm()">保 存</el-button>
        <el-button type="primary" v-else @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      protocolList: [],
      isEdited: false,
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        category: '',
        content: '',
        state: '1',
        versionCode: ''
      },
      addFormRules: {
        category: [
          { required: true, message: '请选择协议类型', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写协议内容', trigger: 'change' }
        ],
        versionCode: [
          { required: true, message: '请填写版本号', trigger: 'change' }
        ],
        state: [
          { required: true, message: '请选择协议状态', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.getProtocolList(this.queryInfo)
    // this.protocolList = [
    //   {
    //     id: 1,
    //     acount: 'root',
    //     name: 'BOSS',
    //     role: '超级管理员',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 2,
    //     acount: 'admin',
    //     name: '小三',
    //     role: '管理员',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 3,
    //     acount: 'bobo',
    //     name: '产品',
    //     role: '财务',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 4,
    //     acount: 'mimu',
    //     name: '测试',
    //     role: '测试',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 1,
    //     type: false
    //   }
    // ]
  },
  methods: {
    async getProtocolList(dic) {
      const { data: res } = await this.$http.get('/protocol/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.protocolList = res.data
      this.total = res.total
    },
    showAddView() {
      this.addForm.category = ''
      this.addForm.content = ''
      this.addForm.state = ''
      this.addForm.versionCode = ''
      this.addDialogVisible = true
    },
    showEditView(row) {
      console.log(row)
      this.isEdited = true
      this.addForm.category = row.category
      this.addForm.content = row.content
      this.addForm.state = JSON.stringify(row.state)
      this.addForm.id = row.id
      this.addForm.versionCode = row.versionCode
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getProtocolList(this.queryInfo)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getProtocolList(this.queryInfo)
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        const { data: res } = await this.$http.post(
          '/protocol/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        if (this.isEdit) {
          this.$message.success('保存成功')
        } else {
          this.$message.success('添加成功')
        }
        this.$refs.addFormRef.resetFields()
        this.addDialogVisible = false
        this.getProtocolList(this.queryInfo)
      })
    },
    deleteDeviceConfirm(id) {
      this.$confirm('你确定要删除该协议吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async delete(id) {
      const { data: res } = await this.$http.get('/protocol/delete/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功！')
      this.getProtocolList(this.queryInfo)
    }
  }
}
</script>

<style scoped>
.el-radio {
  margin-bottom: 10px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  /* height: 100px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.dialog-input >>> .el-input__inner {
  width: 100%;
  /* height: 40px; */
}
.el-form-item el-form-item--mini {
  width: 100%;
}
</style>
