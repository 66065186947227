<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>业务中心</el-breadcrumb-item>
      <el-breadcrumb-item>货源管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加货源</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="140px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="head">添加货源</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="发货人身份:" prop="userType">
              <el-select v-model="addForm.userType" placeholder="请选择" style="width:100%;" clearable @change="userTypeChange">
                <el-option label="货主" value="04.shipper"></el-option>
                <el-option label="物流公司" value="02.logistics"></el-option>
                <el-option label="车主" value="03.driver"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发货人账号:" prop="phone">
              <el-input class="dialog-input" v-model="addForm.phone" placeholder="发货人手机号" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="发货人昵称:" prop="shipperName">
              <el-autocomplete class="dialog-input" style="width:100%;" v-model="addForm.shipperName" :fetch-suggestions="querySearch" placeholder="请输入发货人昵称或公司名" :trigger-on-focus="false" @select="handleSelect" clearable></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="出发地:" prop="startCity">
              <template>
                <el-cascader clearable style="width:100%;" :value="startAddress" :props="defultProp" :options="address" @change="handleChangeGo"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的地:" prop="destinationCity">
              <template>
                <el-cascader clearable style="width:100%;" v-model="destinationAddress" :props="defultProp" :options="address" @change="handleChangeArrive"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="货物名称:" prop="goodsName">
              <el-input class="dialog-input" v-model="addForm.goodsName" placeholder="请输入货物名称" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="装货时间:" prop="loadingTime">
              <el-date-picker style="width:100%;" v-model="addForm.loadingTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="车辆类型:" prop="vehicleType">
              <el-cascader clearable style="width:100%" class="dialog-input" :props="defultCarProp" :options="carTypeList" @change="handleCarTypeChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货物重量(吨)" prop="goodsWeight">
              <el-input class="dialog-input" v-model="addForm.goodsWeight" placeholder="请输入货物重量" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="装货地址:" prop="loadingAddress">
              <el-input class="dialog-input" v-model="addForm.loadingAddress" placeholder="请输入装货地址" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货物体积(立方米):" prop="goodsVolume">
              <el-input class="dialog-input" v-model="addForm.goodsVolume" placeholder="请输入货物体积" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="运费预算:" prop="freightBudget">
              <el-input class="dialog-input" v-model="addForm.freightBudget" placeholder="请输入运费预算" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式:" prop="contact">
              <el-input class="dialog-input" v-model="addForm.contact" placeholder="请输入联系方式" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <el-form-item label="特别说明:" prop="remarks">
              <el-input class="dialog-input" v-model="addForm.remarks" placeholder="请输入特别说明" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button type="primary" @click="submitForm()">确 定</el-button>
        <el-button @click="goBack">取 消</el-button>
      </span>
    </el-card>
  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      restaurants: [],
      carTypeList: [],
      // 添加设备表单数据
      addForm: {
        shipperName: '',
        phone: '',
        userType: '',
        startProvince: '',
        startCity: '',
        startDistrict: '',
        destinationCity: '',
        destinationDistrict: '',
        destinationProvince: '',
        goodsName: '',
        loadingTime: '',
        loadingAddress: '',
        vehicleType: '',
        goodsWeight: '',
        goodsVolume: '',
        freightBudget: '',
        contact: '',
        remarks: '',
        logisticsCompanyId: '',
        userId: ''
      },
      startAddress: '',
      destinationAddress: '',
      addFormRules: {
        userType: [
          { required: true, message: '请选择发货人身份', trigger: 'change' }
        ],
        startCity: [
          { required: true, message: '请选择出发地', trigger: 'change' }
        ],
        destinationCity: [
          { required: true, message: '请选择目的地', trigger: 'change' }
        ],
        goodsName: [
          { required: true, message: '请填写货物名称', trigger: 'change' }
        ],
        loadingTime: [
          { required: true, message: '请选择装货时间', trigger: 'change' }
        ],
        vehicleType: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        loadingAddress: [
          { required: true, message: '请填写装货地址', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '请填写联系方式', trigger: 'change' },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ]
      },
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      address: [],
      defultCarProp: {
        label: 'name',
        value: 'name',
        children: 'lengthList',
        expandTrigger: 'hover'
      }
    }
  },
  created() {
    this.address = json
    this.carTypeList = [
      {
        name: '小面包车',
        lengthList: [
          {
            name: '2.4米'
          }
        ]
      },
      {
        name: '中面包车',
        lengthList: [
          {
            name: '3.2米'
          }
        ]
      },
      {
        name: '厢式车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '平板车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '高栏车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      }
    ]
  },
  mounted() {
    // this.restaurants = this.loadAll()
  },
  methods: {
    goBack() {
      this.$refs.addFormRef.resetFields()
      this.$router.back(-1)
    },
    querySearch(queryString, cb) {
      if (this.addForm.userType === '') {
        this.$message.error('请选择发货人身份')
        return
      }
      this.loadUserList(queryString, cb)
      // var results = queryString
      //   ? this.restaurants.filter(this.createFilter(queryString))
      //   : this.restaurants
      // 调用 callback 返回建议列表的数据
    },
    userTypeChange(e) {
      this.addForm.userType = e
      this.addForm.logisticsCompanyId = ''
      this.addForm.phone = ''
      this.addForm.userId = ''
      this.restaurants = []
      this.addForm.shipperName = ''
    },
    async loadUserList(str, cb) {
      var dic = {
        pageNum: 1,
        pageSize: 10
      }
      dic.userType = this.addForm.userType
      if (dic.userType === '02.logistics') {
        dic.logisticsCompanyName = str
      } else if (dic.userType !== '') {
        dic.name = str
      }
      const { data: res } = await this.$http.get(
        '/user/goodsSource/user/list',
        {
          params: dic
        }
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      res.data.forEach((item) => {
        if (this.addForm.userType === '02.logistics') {
          item.value = item.logisticsCompanyName
        } else if (this.addForm.userType !== '') {
          item.value = item.name
        }
      })
      this.restaurants = res.data
      cb(this.restaurants)
    },
    handleCarTypeChange(e) {
      this.addForm.vehicleType = e[0] + e[1]
    },
    handleSelect(item) {
      console.log(item)
      this.addForm.logisticsCompanyId = item.logisticsCompanyId
      this.addForm.phone = item.phone
      this.addForm.userId = item.id
    },
    handleChangeGo(city) {
      this.addForm.startProvince = city[0]
      this.addForm.startCity = city[1]
      this.addForm.startDistrict = city[2]
    },
    handleChangeArrive(city) {
      this.addForm.destinationProvince = city[0]
      this.addForm.destinationCity = city[1]
      this.addForm.destinationDistrict = city[2]
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        if (
          this.addForm.userId === '' ||
          this.addForm.logisticsCompanyId === ''
        ) {
          this.$message.info('请输入公司关键字并选择匹配的公司')
          return false
        }
        const { data: res } = await this.$http.post(
          '/goodsSource/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.$router.back(-1)
      })
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.branchOfficeView {
  width: 400px;
  padding: 20px 20px 0 0;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.lineView {
  width: 300px;
  padding: 20px;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.closeImg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 90%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
</style>

<style lang="less" scoped>
.tabView {
  background-color: white;
  margin-bottom: 10px;
}

.dialog-row {
  margin-bottom: 0;
}
.dialog-row-title {
  margin-top: 20px;
  margin-bottom: 0;
}

/* .dialog-input >>> .el-input__inner {
  height: 50px;
} */
</style>
