import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/登录/Login.vue'
import CompanyExamine from '../views/审核中心/CompanyExamine.vue'
import ImageExamine from '../views/审核中心/ImageExamine.vue'
import ImageDetail from '../views/审核中心/ImageExamineDetail.vue'
import NoticeExamine from '../views/审核中心/NoticeExamine.vue'
import OwnerExamine from '../views/审核中心/OwnerExamine.vue'
import OwnerExamineDetail from '../views/审核中心/OwnerExamineDetail.vue'
import SupplyManagement from '../views/业务中心/SupplyManagement.vue'
import LineManagement from '../views/业务中心/LineManagement.vue'
import AddSupply from '../views/业务中心/AddSupply.vue'
import SupplyDetail from '../views/业务中心/SupplyDetail.vue'
import AddLine from '../views/业务中心/AddLine.vue'
import EditLineDetail from '../views/业务中心/EditLineDetail.vue'
import EditSupplyDetail from '../views/业务中心/EditSupplyDetail.vue'
import RechargeReport from '../views/数据报表/RechargeReport.vue'
import ChargeReport from '../views/数据报表/ChargeReport.vue'
import MemberReport from '../views/数据报表/MemberReport.vue'
import RefundDepositReport from '../views/数据报表/RefundDepositReport.vue'
import RevenueReport from '../views/数据报表/RevenueReport.vue'
import WithdrawalReport from '../views/数据报表/WithdrawalReport.vue'
import UserManagement from '../views/用户中心/UserManagement.vue'
import AccountManagement from '../views/系统管理/AccountManagement.vue'
import AgreementManagement from '../views/系统管理/AgreementManagement.vue'
import RoleManagement from '../views/系统管理/RoleManagement.vue'
import SystemMessage from '../views/系统管理/SystemMessage.vue'
import BannerManagement from '../views/运营中心/BannerManagement.vue'
import FeedbackManagement from '../views/运营中心/FeedbackManagement.vue'
import MemberLevelManagement from '../views/运营中心/MemberLevelManagement.vue'
import Main from '../views/首页/Main.vue'
import CompanyExamineDetail from '../views/审核中心/CompanyExamineDetail.vue'
import CompanyManagement from '../views/用户中心/CompanyManagement.vue'
import OwnerManagement from '../views/用户中心/OwnerManagement.vue'
import MemberManagement from '../views/用户中心/MemberManagement.vue'
import AddCompany from '../views/用户中心/AddCompanyDeatil.vue'
import EditCompany from '../views/用户中心/EditCompanyDetail.vue'
import CompanyDetail from '../views/用户中心/CompanyDetail.vue'
import AddOwner from '../views/用户中心/AddOwner.vue'
import OwnerDetail from '../views/用户中心/OwnerDetail.vue'
import EditOwner from '../views/用户中心/EditOwnerDetail.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: 'login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/main',
    children: [
      {
        path: '/examine/companyExamine',
        component: CompanyExamine
      },
      {
        path: '/examine/companyExamineDetail',
        component: CompanyExamineDetail
      },
      {
        path: '/examine/imageExamine',
        component: ImageExamine
      },
      {
        path: '/examine/imageExamineDetail',
        component: ImageDetail
      },
      {
        path: '/examine/noticeExamine',
        component: NoticeExamine
      },
      {
        path: '/examine/ownerExamine',
        component: OwnerExamine
      },
      {
        path: '/examine/ownerExamineDetail',
        component: OwnerExamineDetail
      },
      {
        path: '/business/supplyManagement',
        component: SupplyManagement
      },
      {
        path: '/business/lineManagement',
        component: LineManagement
      },
      {
        path: '/business/addSupply',
        component: AddSupply
      },
      {
        path: '/business/supplyDetail',
        component: SupplyDetail
      },
      {
        path: '/business/addLine',
        component: AddLine
      },
      {
        path: '/business/editSupplyDetail',
        component: EditSupplyDetail
      },
      {
        path: '/business/editLineDetail',
        component: EditLineDetail
      },
      {
        path: '/report/rechargeReport',
        component: RechargeReport
      },
      {
        path: '/report/chargeReport',
        component: ChargeReport
      },
      {
        path: '/report/memberReport',
        component: MemberReport
      },
      {
        path: '/report/refundDepositReport',
        component: RefundDepositReport
      },
      {
        path: '/report/revenueReport',
        component: RevenueReport
      },
      {
        path: '/report/withdrawalReport',
        component: WithdrawalReport
      },
      {
        path: '/user/userManagement',
        component: UserManagement
      },
      {
        path: '/user/ownerManagement',
        component: OwnerManagement
      },
      {
        path: '/user/memberManagement',
        component: MemberManagement
      },
      {
        path: '/user/companyManagement',
        component: CompanyManagement
      },
      {
        path: '/user/addCompany',
        component: AddCompany
      },
      {
        path: '/user/editCompany',
        component: EditCompany
      },
      {
        path: '/user/companyDetail',
        component: CompanyDetail
      },
      {
        path: '/user/addOwner',
        component: AddOwner
      },
      {
        path: '/user/ownerDetail',
        component: OwnerDetail
      },
      {
        path: '/user/editOwner',
        component: EditOwner
      },
      {
        path: '/system/accountManagement',
        component: AccountManagement
      },
      {
        path: '/system/agreementManagement',
        component: AgreementManagement
      },
      {
        path: '/system/roleManagement',
        component: RoleManagement
      },
      {
        path: '/system/systemMessage',
        component: SystemMessage
      },
      {
        path: '/operation/bannerManagement',
        component: BannerManagement
      },
      {
        path: '/operation/feedbackManagement',
        component: FeedbackManagement
      },
      {
        path: '/operation/memberLevelManagement',
        component: MemberLevelManagement
      },
      {
        path: '/main',
        component: Main
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
