<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tabView">
      <el-button size="small" :type="companyTabType" style="margin-left:20px" @click="changeTab(0)">物流公司</el-button>
      <el-button size="small" :type="memberTabType" style="margin:10px 20px" @click="changeTab(1)">会员</el-button>
    </div>
    <!-- 物流顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="会员级别">
              <el-select v-if="tabId==0" v-model="searchForm.memberLevelId" placeholder="请选择" style="width:100%;" disabled>
                <el-option label="押金会员" value="0"></el-option>
              </el-select>
              <el-select v-else v-model="searchForm.memberLevelId" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="普通会员" value="1"></el-option>
                <el-option label="银卡会员" value="2"></el-option>
                <el-option label="金卡会员" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="所在城市">
              <el-cascader clearable class="elCacader" v-model="searchAddress" :props="defultProp" :options="address" @change="handleChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公司名称">
              <el-input v-model="searchForm.companyName" placeholder="请输入公司名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="审核时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 物流卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">物流公司数量：{{total}}</div>
        </div>
        <el-button size="small" type="primary" style="width: 80px; font-size: 14px" @click="addCompany()" v-if="isAuth('/user/companyManagement/add')">添加</el-button>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="ID" align="center" prop="id"></el-table-column>
        <el-table-column label="注册手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="公司名称" align="center" prop="companyName"></el-table-column>
        <el-table-column label="会员级别" align="center" prop="memberName"></el-table-column>
        <el-table-column label="联系人" align="center" prop="contact"></el-table-column>
        <el-table-column label="联系电话" align="center" prop="telephone"></el-table-column>
        <el-table-column label="零钱余额" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.balance == null">0</div>
            <div v-else>{{scope.row.balance}}</div>
          </template>
        </el-table-column>
        <el-table-column label="所在城市" align="center" prop="city"></el-table-column>
        <el-table-column label="申请时间" align="center" prop="createTime" min-width="125"></el-table-column>
        <el-table-column label="审核人" align="center" prop="approveUserName"></el-table-column>
        <el-table-column label="审核时间" align="center" prop="approveTime" min-width="125"></el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待审核</div>
            <div v-else-if="scope.row.state == 2" style="color:#E33434">审核中</div>
            <div v-else-if="scope.row.state == 3" style="color:#E33434">已审核</div>
            <div v-else style="color:#3BC202">已驳回</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-if="scope.row.state == 3||scope.row.state == 0&&isAuth('/user/companyManagement/edit')" @click="editCompanyDetail(scope.row)">编辑</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-else disabled>编辑</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-if="scope.row.state == 3&&isAuth('/user/companyManagement/detail')" @click="showCompanyDetail(scope.row)">查看</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-else disabled>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      companyTabType: 'primary',
      memberTabType: '',
      tabId: 0,
      searchAddress: '',
      searchForm: {
        memberLevelId: '',
        approveTimeEnd: '',
        approveTimeStart: '',
        city: '',
        province: '',
        companyName: ''
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10,
        isMember: 0
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        author: '',
        phone: '',
        nickName: '',
        companyName: '',
        companyAddress: '',
        type: ''
      },
      addFormRules: {
        phone: [{ required: true, message: '请填写手机号', trigger: 'change' }],
        nickName: [
          { required: true, message: '请填写昵称', trigger: 'change' }
        ],
        type: [{ required: true, message: '请选择身份', trigger: 'change' }]
      },
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      address: []
    }
  },
  created() {
    this.getMemberList(this.queryInfo)
    this.searchAddress = this.searchForm.city
    this.address = json
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/logisticsCompany/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.approveTimeStart = this.dateRange[0]
        this.searchForm.approveTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.approveTimeStart = ''
        this.searchForm.approveTimeEnd = ''
      }
    },
    changeTab(id) {
      this.tabId = id
      if (id === 0) {
        this.companyTabType = 'primary'
        this.memberTabType = ''
        this.queryInfo.isMember = 0
      } else {
        this.companyTabType = ''
        this.memberTabType = 'primary'
        this.queryInfo.isMember = 1
      }
      this.getMemberList(this.queryInfo)
    },
    addCompany() {
      this.$router.push({
        path: '/user/addCompany',
        query: { id: 0 }
      })
    },
    editCompanyDetail(row) {
      if (row.state === 0) {
        this.$router.push({
          path: '/user/addCompany',
          query: { id: row.id }
        })
      } else {
        this.$router.push({
          path: '/user/editCompany',
          query: { id: row.id }
        })
      }
    },
    showCompanyDetail(row) {
      this.$router.push({
        path: '/user/companyDetail',
        query: { id: row.id, balance: row.balance }
      })
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.isMember = this.queryInfo.isMember
      this.getMemberList(this.searchForm)
    },
    refresh() {
      this.searchForm.approveTimeStart = ''
      this.searchForm.approveTimeEnd = ''
      this.dateRange = []
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.searchAddress = ''
      this.$refs.searchFormRef.resetFields()
      this.getMemberList(this.queryInfo)
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.isMember = this.queryInfo.isMember
      this.getMemberList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.isMember = this.queryInfo.isMember
      this.getMemberList(this.searchForm)
    },
    handleChange(city) {
      this.searchForm.province = city[0]
      this.searchForm.city = city[1]
      if (city[2] !== '全市') {
        this.searchForm.district = city[2]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.tabView {
  background-color: white;
  margin-bottom: 10px;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
