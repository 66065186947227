<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>运营中心</el-breadcrumb-item>
      <el-breadcrumb-item>广告图管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <el-button size="small" type="primary" style="width: 80px; font-size: 14px"  v-if="isAuth('/operation/bannerManagement/add')" @click="showAddView()">添加广告</el-button>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="bannerList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="名称" align="center" prop="picName"></el-table-column>
        <el-table-column label="图片" align="center">
          <template slot-scope="scope">
            <img :src="scope.row.picUrl" alt="" style="margin-top:8px;width:30px;height:30px;">
          </template>
        </el-table-column>
        <el-table-column label="内部链接" align="center" prop="internalUrl"></el-table-column>
        <el-table-column label="外部链接" align="center" prop="externalUrl"></el-table-column>
        <el-table-column label="播放次序" align="center" prop="sort"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">显示</div>
            <div v-else style="color:#E33434">隐藏</div>
          </template>
        </el-table-column>
        <el-table-column label="更改状态" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" @change="changeType(scope.row)" v-if="isAuth('/operation/bannerManagement/show')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="上传时间" align="center" prop="createTime" min-width="125"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="text" size="medium" style="margin-left: 8px; color: #E33434" v-if="isAuth('/operation/bannerManagement/delete')" @click="deleteBannerConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/operation/bannerManagement/edit')" @click="showEditView(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog :title="isEdited?'修改广告':'添加广告'" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="110px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="轮播图名称:" prop="picName" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.picName" placeholder="请输入轮播图名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="轮播顺序:" prop="sort" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.sort" placeholder="请输入轮播顺序"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="链接状态:">
              <el-radio-group v-model="addForm.urlType" style="margin-top: 7px;">
                <el-radio label="0">无链接</el-radio>
                <el-radio label="1">内部链接</el-radio>
                <el-radio label="2">外部链接</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="内部链接:" prop="internalUrl" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.internalUrl" placeholder="请输入内部链接"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="外部链接:" prop="externalUrl" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.externalUrl" placeholder="请输入外部链接"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="上传广告图:" prop="picUrl">
              <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess" :file-list="fileList" multiple :class="{hide:imageHide}">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <div class="unit">*只能上传jpg/png/jpeg文件，图片尺寸1125*675</div>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="isEdited === true" @click="submitForm()">保 存</el-button>
        <el-button type="primary" v-else @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      uploadUrl: 'https://api.sansongkeji.com/files/upload/logo',
      // uploadUrl: 'http://mirrortest.lzs-iot.com:8806/files/upload/logo',
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        externalUrl: '',
        internalUrl: '',
        picName: '',
        picUrl: '',
        sort: '',
        urlType: ''
      },
      isEdited: false,
      headers: {},
      fileList: [],
      addFormRules: {
        picName: [
          { required: true, message: '请填写轮播图名称', trigger: 'change' }
        ],
        picUrl: [
          { required: true, message: '请上传轮播图片', trigger: 'change' }
        ],
        sort: [{ required: true, message: '请输入轮播次序', trigger: 'change' }]
      },
      imageHide: false
    }
  },
  created() {
    this.headers.Authorization = window.localStorage.getItem('token')
    this.getBannerList(this.queryInfo)
  },
  methods: {
    handleRemove(file, fileList) {
      this.addForm.picUrl = ''
      this.fileList = fileList
      this.imageHide = fileList.length >= 1
    },
    handleSuccess(res, file, fileList) {
      this.imageHide = fileList.length >= 1
      this.addForm.picUrl = res.data
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      var testmsg = /^image\/(jpeg|png|jpg|gif|bmp)$/.test(file.type)
      const isLt1M = file.size / 1024 / 1024 < 2
      if (!testmsg) {
        this.$message.error('上传图片格式不正确！')
      }
      if (!isLt1M) {
        this.$message.warning('上传图片大小不能超过2MB!')
      }
      return isLt1M
    },
    handleExceed() {
      this.$message.error('只能上传一张图片')
    },
    async getBannerList(dic) {
      const { data: res } = await this.$http.get('/adPicture/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.forEach((item) => {
        if (item.state === 0) {
          item.type = false
        } else {
          item.type = true
        }
      })
      this.bannerList = res.data
      this.total = res.total
    },
    showAddView() {
      this.isEdited = false
      this.addForm.externalUrl = ''
      this.addForm.internalUrl = ''
      this.addForm.picName = ''
      this.addForm.id = ''
      this.addForm.picUrl = ''
      this.addForm.sort = ''
      this.addForm.urlType = '0'
      this.fileList = []
      this.imageHide = false
      this.addDialogVisible = true
    },
    showEditView(row) {
      this.fileList = []
      this.addForm.externalUrl = row.externalUrl
      this.addForm.internalUrl = row.internalUrl
      this.addForm.picName = row.picName
      this.addForm.id = row.id
      this.addForm.picUrl = row.picUrl
      this.addForm.sort = row.sort
      this.addForm.urlType = JSON.stringify(row.urlType)
      this.fileList.push({
        url: row.picUrl
      })
      this.imageHide = true
      this.isEdited = true
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.isEdited = false
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 1000)
    },
    async changeType(item) {
      var dic = {}
      dic.id = item.id
      dic.sort = item.sort
      dic.urlType = item.urlType
      dic.picUrl = item.picUrl
      dic.picName = item.picName
      if (item.type) {
        dic.state = '1'
      } else {
        dic.state = '0'
      }
      const { data: res } = await this.$http.post('/adPicture/save', dic)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('设置成功')
      this.getBannerList(this.queryInfo)
    },
    deleteBannerConfirm(id) {
      this.$confirm('此操作将删除该广告, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteBanner(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消设置'
          })
        })
    },
    async deleteBanner(id) {
      const { data: res } = await this.$http.get('/adPicture/delete/' + id, '')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getBannerList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getBannerList(this.queryInfo)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getBannerList(this.queryInfo)
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        const { data: res } = await this.$http.post(
          '/adPicture/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        if (this.isEdited) {
          this.$message.success('修改成功')
        } else {
          this.$message.success('添加成功')
        }
        this.$refs.addFormRef.resetFields()
        this.addDialogVisible = false
        this.getBannerList(this.queryInfo)
        this.isEdited = false
      })
    }
  }
}
</script>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.el-radio {
  font-weight: 400;
}
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.unit {
  width: 50%;
  text-align: center;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.2);
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dialog-row {
  margin-bottom: 0px;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.father {
  height: 146px;
}

.img {
  position: relative;
  background-color: #cccccc;
  width: 146px;
}

.bgView {
  position: absolute;
  width: 146px;
  height: 146px;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete {
  z-index: 999;
}

.el-upload__tip {
  color: red;
}
</style>

<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
