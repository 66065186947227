<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" ref="searchFormRef" :model="searchForm" :rules="searchFormRules" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="登录账号" prop="account">
              <el-input v-model="searchForm.account" placeholder="请输入账号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="真实姓名" prop="name">
              <el-input v-model="searchForm.name" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item style="display: flex;justify-content: center;">
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/system/accountManagement/add')" @click="showEditView()">新建</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="登录账号" align="center" prop="account"></el-table-column>
        <el-table-column label="真实姓名" align="center" prop="name"></el-table-column>
        <el-table-column label="手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="角色" align="center" prop="roleName"></el-table-column>
        <el-table-column label="创建人" align="center" prop="createName"></el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime" min-width="130px"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">正常</div>
            <div v-else style="color:#E33434">禁用</div>
          </template>
        </el-table-column>
        <el-table-column label="更改状态" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" v-if="isAuth('/system/accountManagement/state')" @change="changeType(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/system/accountManagement/delete')" @click="deleteAcountConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/system/accountManagement/reset')" @click="showResetView(scope.row.id)">重置密码</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/system/accountManagement/edit')" @click="editAcountConfirm(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加账号对话框 -->
    <el-dialog :title="isEdited?'编辑账号': '添加账号'" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="95px" size='mini' class="addfrom" label-position="top">
        <el-row class="dialog-row">
          <el-col :span="12" style="height:100%">
            <el-form-item label="登录账号:" prop="account" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.account" placeholder="请输入登录账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height:100%">
            <el-form-item label="部门" style="height:40px" prop="department">
              <el-select class="dialog-input" v-model="addForm.department" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="营销" value="marketing"></el-option>
                <el-option label="客服" value="service"></el-option>
                <el-option label="审核" value="checker"></el-option>
                <el-option label="研发" value="rd"></el-option>
                <el-option label="经理" value="manager"></el-option>
                <el-option label="财务" value="finance"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12" style="height:100%">
            <el-form-item label="姓名:" prop="name" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height:100%">
            <el-form-item label="手机号:" prop="phone" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.phone" placeholder="请输入手机号" @change="phoneInput($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%;width:100%;">
            <el-form-item label="初始登录密码:" prop="password" style="height:40px;width:100%;">
              <el-input style="width:95%;" class="dialog-input" v-model="addForm.password" placeholder="请输入登录密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="角色:" prop="roleId">
              <el-radio-group v-model="addForm.roleId" @change="chooseRole">
                <el-radio v-for="(item, index) in roleList" :key="index" :label="item.id">{{item.roleName}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="isEdited"  @click="submitForm()">保 存</el-button>
        <el-button type="primary" v-else @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>

    <!-- 重置密码对话框 -->
    <el-dialog title="重置密码" :visible.sync="addResetDialogVisible" width="30%" @close="addResetDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addResetForm" ref="addResetFormRef" :rules="addResetFormRules" label-width="70px" size='mini' label-position="left">
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%;">
            <el-form-item label="新密码:" prop="password" style="height:40px">
              <el-input class="dialog-input" v-model="addResetForm.password" placeholder="请输入新密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPassword()">确 定</el-button>
        <el-button @click="addResetDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        account: '',
        name: '',
        phone: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      memberList: [],
      roleList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10,
        type: '01.sys'
      },
      total: 0,
      resetId: '',
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      addResetDialogVisible: false,
      addResetForm: {
        password: ''
      },
      isEdited: false,
      // 添加设备表单数据
      addForm: {
        account: '',
        phone: '',
        name: '',
        department: '',
        password: '123456',
        roleId: ''
      },
      addFormRules: {
        phone: [{ required: true, message: '请填写手机号', trigger: 'change' }],
        nickName: [
          { required: true, message: '请填写昵称', trigger: 'change' }
        ],
        type: [{ required: true, message: '请选择身份', trigger: 'change' }],
        password: [{ min: 6, max: 6, message: '密码长度在 6 个字符', trigger: 'blur' }]
      },
      addResetFormRules: {
        password: [
          { required: true, message: '请填写新的登录密码', trigger: 'change' },
          { min: 6, max: 6, message: '密码长度在 6 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getMemberList(this.queryInfo)
    this.getRoleList()
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/user/account/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.forEach((item) => {
        if (item.state === 0) {
          item.type = false
        } else {
          item.type = true
        }
      })
      this.memberList = arr
      this.total = res.total
    },
    async getRoleList() {
      const { data: res } = await this.$http.get('/role/list', {
        params: {
          pageNum: 1,
          pageSize: 100
        }
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.shift()
      this.roleList = arr
    },
    // 冻结解封账号
    changeType(item) {
      if (item.state === 1) {
        this.$confirm('此操作将设置该账号为禁用状态, 是否继续?', '禁用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.blockAcount(item.id, 0)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消设置'
            })
            this.getMemberList(this.queryInfo)
          })
      } else {
        this.$confirm('此操作将设置该账号为正常状态, 是否继续?', '解禁', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.blockAcount(item.id, 1)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消设置'
            })
            this.getMemberList(this.queryInfo)
          })
      }
    },
    async blockAcount(id, type) {
      const { data: res } = await this.$http.post(
        '/user/state?id=' + id + '&state=' + type
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('设置成功')
      this.getMemberList(this.queryInfo)
    },
    deleteAcountConfirm(id) {
      this.$confirm('此操作将删除该账号, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteAcount(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消设置'
          })
        })
    },
    async deleteAcount(id) {
      const { data: res } = await this.$http.get('/user/delete/' + id, '')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      var dic = JSON.parse(JSON.stringify(this.searchForm))
      dic.pageNum = this.queryInfo.pageNum
      dic.pageSize = this.queryInfo.pageSize
      this.getMemberList(dic)
    },
    showResetView(id) {
      this.addResetDialogVisible = true
      this.resetId = id
    },
    resetPassword() {
      this.$confirm('此操作将重置登录密码, 是否继续?', '重置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resetAcount()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          })
          this.resetId = ''
          this.addResetDialogVisible = false
        })
    },
    async resetAcount() {
      this.$refs.addResetFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写新密码')
          return false
        }
        var dic = {
          id: this.resetId,
          password: this.addResetForm.password
        }
        const { data: res } = await this.$http.post('/user/reset', dic)
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('设置成功')
        this.addResetDialogVisible = false
        this.resetId = ''
        this.getMemberList(this.queryInfo)
      })
    },
    chooseRole(e) {
      this.addForm.roleId = e
    },
    showEditView() {
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.isEdited = false
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 500)
    },
    addResetDialogClosed() {
      setTimeout(() => {
        this.addResetDialogVisible = false
        this.$refs.addResetFormRef.resetFields()
      }, 100)
    },
    editAcountConfirm(id) {
      this.isEdited = true
      this.loadAccountDetail(id)
    },
    async loadAccountDetail(id) {
      const { data: res } = await this.$http.get('/user/detail/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.addForm.account = res.data.account
      this.addForm.id = res.data.id
      this.addForm.roleId = res.data.roleId
      this.addForm.department = res.data.department
      this.addForm.phone = res.data.phone
      this.addForm.name = res.data.name
      this.addForm.type = '01.sys'
      this.addDialogVisible = true
    },
    submitForm() {
      if (this.isEdited) {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            this.$message.info('请填写正确的数据')
            return false
          }
          const { data: res } = await this.$http.post(
            '/user/edit',
            this.addForm
          )
          if (res.code !== 1000) return this.$message.error(res.msg)
          this.$message.success('修改成功')
          this.addDialogVisible = false
          this.isEdited = false
          this.queryInfo.pageNum = 1
          this.getMemberList(this.queryInfo)
        })
      } else {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            this.$message.info('请填写正确的数据')
            return false
          }
          const { data: res } = await this.$http.post('/user/add', this.addForm)
          if (res.code !== 1000) return this.$message.error(res.msg)
          this.$message.success('添加成功')
          this.addDialogVisible = false
          this.queryInfo.pageNum = 1
          this.getMemberList(this.queryInfo)
        })
      }
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    refresh() {
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.$refs.searchFormRef.resetFields()
      this.getMemberList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    phoneInput(e) {
      this.checkPhone(e)
    },
    async checkPhone(phone) {
      const { data: res } = await this.$http.get(
        '/user/check/phone/' + phone,
        ''
      )
      if (res.code !== 1000) {
        this.addForm.phone = ''
        return this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-radio {
  margin-bottom: 10px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.dialog-input >>> .el-input__inner {
  width: 100%;
  height: 40px;
}
.el-form-item el-form-item--mini {
  width: 100%;
}
</style>
