<template >
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div class="header-left">
        <div>
          <img src="../assets/weblogo.png" alt="" />
        </div>
        <span>伞送管理平台</span>
      </div>
      <div class="header-right">
        <img src="../assets/search.png" alt="">
        <img src="../assets/xiaoxi.png" width="12px" height="16px" alt="">
        <span>{{phone}}</span>
        <el-button style="
            background-color: #fff;
            border: none;
            font-size: 16px;
            color: #363636;
            font-weight: 300;
          " type="info" @click="logout">退出</el-button>
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏区域 -->
      <el-aside width="200px">
        <div class="aside-header"></div>
        <el-menu background-color="#3550FF" text-color="rgba(255,255,255,0.65)" :router="true" :default-active="activePath" :collapse-transition="false" active-text-color="#fff" :unique-opened="true" :collapse="false">
          <el-menu-item index="/main" @click="chooseNavState" v-if="!disable">
            <template>
              <img src="../assets/main.png" alt="" class="icon-image">
              <span>首页</span>
            </template>
          </el-menu-item>
          <el-submenu :index="item.id + ''" v-for="item in menuList" :key="item.id">
            <!-- 一级菜单模版区 -->
            <template slot="title">
              <!-- 图标 -->
              <!-- <img v-if="item.name==='首页'" src="../assets/main.png" alt="" class="icon-image"> -->
              <img v-if="item.name==='用户中心'" src="../assets/Shape.png" alt="" class="icon-image">
              <img v-else-if="item.name==='业务中心'" src="../assets/operation.png" alt="" class="icon-image">
              <img v-else-if="item.name==='运营中心'" src="../assets/business.png" alt="" class="icon-image">
              <img v-else-if="item.name==='数据报表'" src="../assets/report.png" alt="" class="icon-image">
              <img v-else-if="item.name==='系统管理'" src="../assets/system.png" alt="" class="icon-image">
              <img v-else-if="item.name==='审核中心'" src="../assets/examine.png" alt="" class="icon-image">
              <!-- 文本 -->
              <span>{{ item.name }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="subItem.route" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem)">
              <template slot="title">
                <!-- 图标 -->
                <!-- <i class="el-icon-menu"></i> -->
                <!-- 文本 -->
                <span>{{ subItem.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 主体区域 -->
      <el-main>
        <div style="margin-bottom:20px;">
          <el-tabs style="height:35px" v-model="editableTabsValue" type="border-card" closable v-if="editableTabs.length" @tab-click='tabClick' @tab-remove='tabRemove'>
            <el-tab-pane :key="index" v-for="(item, index) in editableTabs" :label="item.name" :name="item.name">
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      menuList: [],
      activePath: '',
      phone: 'admin',
      editableTabsValue: '首页',
      editableTabs: [
        {
          title: '首页',
          name: '首页',
          path: '/main'
        }
      ],
      activeIndex: 2,
      disable: false
    }
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.phone = window.localStorage.getItem('userName')
  },
  mounted() {
    var arr = JSON.parse(localStorage.getItem('tabs')) || []
    var acPath = window.sessionStorage.getItem('activePath')
    if (arr.length !== 0) {
      arr.forEach((item) => {
        if (item.path === acPath) {
          this.editableTabsValue = item.name
        }
      })
      this.activePath = acPath
      this.editableTabs = arr
    } else {
      window.sessionStorage.setItem('activePath', '/main')
      this.activePath = '/main'
      this.editableTabsValue = '首页'
    }
    var navigatorStr = window.localStorage.getItem('navigator')
    var list = JSON.parse(navigatorStr)
    var count = 0
    for (var i in list) {
      if (list[i].name === '首页') {
        count++
        list.splice(i, 1)
      }
    }
    if (count === 0) {
      this.disable = true
      var path = list[0].children[0].route
      window.sessionStorage.setItem('activePath', path)
      this.activePath = path
      this.editableTabsValue = list[0].children[0].name
    }
    this.menuList = list
    // this.menuList = [
    //   {
    //     id: 2,
    //     name: '用户中心',
    //     route: '/user',
    //     children: [
    //       {
    //         id: 8,
    //         children: [],
    //         name: '用户管理',
    //         route: '/user/userManagement'
    //       },
    //       {
    //         id: 14,
    //         children: [],
    //         name: '物流公司管理',
    //         route: '/user/companyManagement'
    //       },
    //       {
    //         id: 15,
    //         children: [],
    //         name: '会员卡管理',
    //         route: '/user/memberManagement'
    //       },
    //       {
    //         id: 16,
    //         children: [],
    //         name: '车主管理',
    //         route: '/user/ownerManagement'
    //       }
    //     ]
    //   },
    //   {
    //     id: 3,
    //     name: '业务中心',
    //     route: '/business',
    //     children: [
    //       {
    //         id: 9,
    //         children: [],
    //         name: '货源管理',
    //         route: '/business/supplyManagement'
    //       },
    //       {
    //         id: 17,
    //         children: [],
    //         name: '专线管理',
    //         route: '/business/lineManagement'
    //       }
    //     ]
    //   },
    //   {
    //     id: 4,
    //     name: '运营中心',
    //     route: '/operation',
    //     children: [
    //       {
    //         id: 10,
    //         children: [],
    //         name: '广告图管理',
    //         route: '/operation/bannerManagement'
    //       },
    //       {
    //         id: 18,
    //         children: [],
    //         name: '会员等级管理',
    //         route: '/operation/memberLevelManagement'
    //       },
    //       {
    //         id: 19,
    //         children: [],
    //         name: '意见反馈',
    //         route: '/operation/feedbackManagement'
    //       }
    //     ]
    //   },
    //   {
    //     id: 5,
    //     name: '数据报表',
    //     route: '/report',
    //     children: [
    //       {
    //         id: 11,
    //         children: [],
    //         name: '充值报表',
    //         route: '/report/rechargeReport'
    //       },
    //       {
    //         id: 20,
    //         children: [],
    //         name: '押金报表',
    //         route: '/report/refundDepositReport'
    //       },
    //       {
    //         id: 21,
    //         children: [],
    //         name: '营收报表',
    //         route: '/report/revenueReport'
    //       },
    //       {
    //         id: 22,
    //         children: [],
    //         name: '会员报表',
    //         route: '/report/memberReport'
    //       },
    //       {
    //         id: 23,
    //         children: [],
    //         name: '提现审核',
    //         route: '/report/withdrawalReport'
    //       },
    //       {
    //         id: 24,
    //         children: [],
    //         name: '零钱报表',
    //         route: '/report/chargeReport'
    //       }
    //     ]
    //   },
    //   {
    //     id: 6,
    //     name: '系统管理',
    //     route: '/system',
    //     children: [
    //       {
    //         id: 12,
    //         children: [],
    //         name: '账号管理',
    //         route: '/system/accountManagement'
    //       },
    //       {
    //         id: 25,
    //         children: [],
    //         name: '角色管理',
    //         route: '/system/roleManagement'
    //       },
    //       {
    //         id: 26,
    //         children: [],
    //         name: '系统消息',
    //         route: '/system/systemMessage'
    //       },
    //       {
    //         id: 27,
    //         children: [],
    //         name: '服务协议',
    //         route: '/system/agreementManagement'
    //       }
    //     ]
    //   },
    //   {
    //     id: 7,
    //     name: '审核中心',
    //     route: '/examine',
    //     children: [
    //       {
    //         id: 13,
    //         children: [],
    //         name: '物流公司审核',
    //         route: '/examine/companyExamine'
    //       },
    //       {
    //         id: 28,
    //         children: [],
    //         name: '车主审核',
    //         route: '/examine/ownerExamine'
    //       },
    //       {
    //         id: 29,
    //         children: [],
    //         name: '图片审核',
    //         route: '/examine/imageExamine'
    //       },
    //       {
    //         id: 30,
    //         children: [],
    //         name: '公告审核',
    //         route: '/examine/noticeExamine'
    //       }
    //     ]
    //   }
    // ]
  },
  watch: {
    editableTabs: {
      deep: true,
      handler(value) {
        localStorage.setItem('tabs', JSON.stringify(value))
      }
    }
  },
  methods: {
    chooseNavState() {
      window.sessionStorage.setItem('activePath', '/main')
      this.activePath = '/main'
      this.editableTabsValue = '首页'
    },
    // 保存链接的激活状态
    saveNavState(item) {
      window.sessionStorage.setItem('activePath', item.route)
      this.activePath = item.route
      var count = 0
      for (var i in this.editableTabs) {
        var dic = this.editableTabs[i]
        if (dic.name === item.name) {
          count++
          this.editableTabsValue = dic.name
        } else {
        }
      }
      if (count === 0) {
        var dict = {
          title: item.name,
          name: item.name,
          path: item.route
        }
        this.editableTabs.push(dict)
        var j = this.editableTabs.length - 1
        this.editableTabsValue = this.editableTabs[j].name
      }
    },
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    tabClick(item) {
      var label = item.label
      for (var i in this.editableTabs) {
        var dic = this.editableTabs[i]
        if (dic.title === label) {
          this.$router.push(dic.path)
          this.activePath = dic.path
          window.sessionStorage.setItem('activePath', dic.path)
        }
      }
    },
    tabRemove(targetName) {
      var arr = this.editableTabs
      for (var i in arr) {
        var dic = arr[i]
        if (dic.name === targetName) {
          this.editableTabs.splice(i, 1)
          var dict = arr[i - 1]
          this.editableTabsValue = dict.name
          this.$router.push(dict.path)
          this.activePath = dict.path
          window.sessionStorage.setItem('activePath', dict.path)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  height: 100%;
}

.el-header {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  font-size: 20px;
  padding: 0;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > div {
    width: 200px;
    height: 100%;
    background-color: rgb(53, 80, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 50%;
    }
  }
  > span {
    font-size: 24px;
    font-weight: bold;
    color: #363636;
    margin-left: 24px;
  }
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > img {
    width: 14px;
    height: 14px;
    margin-right: 30px;
  }
  > span {
    font-size: 16px;
    color: #363636;
    line-height: 40px;
    margin-right: 20px;
  }
}

.el-aside {
  background-color: rgb(53, 80, 255);
  .aside-header {
    width: 100%;
    height: 24px;
  }
  .el-menu {
    border-right: none;
  }
  .el-menu .el-menu-item.is-active {
    background: #6db6ff !important;
  }
  .el-menu .el-submenu__title.is-active {
    background: #6db6ff !important;
  }
  .el-submenu__title:hover,
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: cornflowerblue;
  }
}

.icon-image {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.el-main {
  background-color: #f7f8fa;
}
</style>

<style lang="less">
.el-tabs--border-card > .el-tabs__header {
  background-color: #f2f2f2 !important;
  border-bottom: 0 !important;
}
.el-tabs--border-card {
  box-shadow: none !important;
  border: none;
}
</style>
