<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>数据报表</el-breadcrumb-item>
      <el-breadcrumb-item>充值报表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="充值账号">
              <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单号">
              <el-input v-model="searchForm.orderNo" placeholder="请输入订单号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="充值类型">
              <el-select v-model="searchForm.orderType" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="押金充值" value="DE"></el-option>
                <el-option label="零钱充值" value="BL"></el-option>
                <el-option label="会员充值" value="CZ"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公司名">
              <el-input v-model="searchForm.companyName" placeholder="请输入公司名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="发布时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item style="display: flex;justify-content: center;">
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">零钱总额:{{totalAmount}}</div>
          <div class="reportText">押金会员总额:{{totalDeposit}}</div>
          <div class="reportText">会员总额:{{totalMemberAMount}}</div>
        </div>
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/report/rechargeReport/report')" v-loading.fullscreen.lock="fullscreenLoading" @click="cardExport">导出</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="订单号" align="center" prop="orderNo"></el-table-column>
        <el-table-column label="充值账号" align="center" prop="phone"></el-table-column>
        <el-table-column label="公司名称" align="center" prop="companyName"></el-table-column>
        <el-table-column label="押金金额" align="center" prop="deposit"></el-table-column>
        <el-table-column label="会员金额" align="center" prop="memberAmount"></el-table-column>
        <el-table-column label="零钱" align="center" prop="chargeAmount"></el-table-column>
        <el-table-column label="充值类型" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.orderType == 'DE'">押金充值</div>
            <div v-else-if="scope.row.orderType == 'CZ'&&scope.row.memberLevelId==3">金卡会员</div>
            <div v-else-if="scope.row.orderType == 'CZ'&&scope.row.memberLevelId==2">银卡会员</div>
            <div v-else-if="scope.row.orderType == 'DER'">押金退款</div>
            <div v-else>零钱充值</div>
          </template>
        </el-table-column>
        <el-table-column label="充值方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.payMode == 'ali'">支付宝</div>
            <div v-else-if="scope.row.payMode == 'wx'">微信</div>
            <div v-else-if="scope.row.payMode == 'balance'">零钱</div>
            <div v-else-if="scope.row.payMode == 'cash'">现金</div>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column label="充值时间" align="center" prop="createTime" min-width="140"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      searchForm: {
        orderType: '',
        endTime: '',
        startTime: '',
        phone: '',
        orderNo: '',
        companyName: ''
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10,
        report: 1
      },
      total: 0,
      fullscreenLoading: false,
      totalMemberAMount: 0,
      totalDeposit: 0,
      totalAmount: 0
    }
  },
  created() {
    this.getMemberList(this.queryInfo)
    this.getStatistics()
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/memberOrder/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    async getStatistics() {
      const { data: res } = await this.$http.get('/memberOrder/statistics/all', '')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.totalMemberAMount = res.data.totalMemberAMount || 0
      this.totalDeposit = res.data.totalDeposit || 0
      this.totalAmount = res.data.totalAmount || 0
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.startTime = this.dateRange[0]
        this.searchForm.endTime = this.dateRange[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
    },
    showEditView() {
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.report = this.queryInfo.report
      this.getMemberList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.report = this.queryInfo.report
      this.getMemberList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.report = this.queryInfo.report
      this.getMemberList(this.searchForm)
    },
    refresh() {
      this.searchForm.startTime = ''
      this.searchForm.endTime = ''
      this.searchForm.orderType = ''
      this.dateRange = []
      this.$refs.searchFormRef.resetFields()
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.getMemberList(this.queryInfo)
    },
    cardExport() {
      var that = this
      var token = window.localStorage.getItem('token')
      that.fullscreenLoading = true
      axios
        .get('/memberOrder/export/all', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token // hearder 很重要，Content-Type 要写对
          },
          responseType: 'arraybuffer' // 一定要写
        })
        .then((res) => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: 'application/octet-stream' // word文档为msword,pdf文档为pdf
            })
            var objectUrl = URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.href = objectUrl
            link.setAttribute('download', '充值报表.xlsx')
            setTimeout(() => {
              that.fullscreenLoading = false
            }, 2000)
            document.body.appendChild(link)
            link.click()
          } else {
          }
        })
        .catch((res) => {
          that.$message({
            type: 'error',
            message: '网络连接失败，请重新尝试',
            duration: '1000'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
