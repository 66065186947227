<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>运营中心</el-breadcrumb-item>
      <el-breadcrumb-item>会员等级管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <el-button size="small" type="primary" style="width: 120px; font-size: 14px" v-if="isAuth('/operation/memberLevelManagement/add')" @click="showAddView()">新增会员等级</el-button>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="会员级别名称" align="center" prop="memberName"></el-table-column>
        <el-table-column label="会员标签" align="center" prop="tags">
          <template slot-scope="scope">
            <img v-if="scope.row.tags==='2'" src="../../assets/113@2x.png" width="40px" height="25px" alt="">
            <img v-else-if="scope.row.tags==='1'" src="../../assets/114@2x.png" width="40px" height="25px" alt="">
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="押金金额" align="center" prop="deposit"></el-table-column>
        <el-table-column label="会员金额" align="center" prop="memberAmount"></el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="text" size="medium" style="margin-left: 8px; color: #E33434" v-if="isAuth('/operation/memberLevelManagement/delete')" @click="deleteMemberlevelConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/operation/memberLevelManagement/edit')" @click="showEditView(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加会员等级对话框 -->
    <el-dialog :title="isEdited?'修改会员等级':'新增会员等级'" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="110px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="会员名称:" prop="memberName" style="height:40px">
              <el-input class="dialog-input" v-if="addForm.memberName==='普通会员'||addForm.memberName==='银卡会员'||addForm.memberName==='金卡会员'" v-model="addForm.memberName" placeholder="请输入会员名称" disabled></el-input>
              <el-input class="dialog-input" v-else v-model="addForm.memberName" placeholder="请输入会员名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="会员标签:" prop="tags">
              <el-radio-group v-model="addForm.tag" style="margin-top: 7px;" @change="chooseTags">
                <el-radio label="2" v-if="addForm.memberName==='普通会员'||addForm.memberName==='银卡会员'||addForm.memberName==='金卡会员'" disabled>
                  <template>
                    <img src="../../assets/113@2x.png" width="40px" height="25px" alt="">
                  </template>
                </el-radio>
                <el-radio label="2" v-else>
                  <template>
                    <img src="../../assets/113@2x.png" width="40px" height="25px" alt="">
                  </template>
                </el-radio>
                <el-radio label="1" v-if="addForm.memberName==='普通会员'||addForm.memberName==='银卡会员'||addForm.memberName==='金卡会员'" disabled>
                  <template>
                    <img src="../../assets/114@2x.png" width="40px" height="25px" alt="">
                  </template>
                </el-radio>
                <el-radio label="1" v-else>
                  <template>
                    <img src="../../assets/114@2x.png" width="40px" height="25px" alt="">
                  </template>
                </el-radio>
                <el-radio label="0" v-if="addForm.memberName==='普通会员'||addForm.memberName==='银卡会员'||addForm.memberName==='金卡会员'" disabled>无</el-radio>
                <el-radio label="0" v-else>无</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="押金金额:" prop="deposit" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.deposit" placeholder="请输入押金金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="会员金额:" prop="memberAmount" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.memberAmount" placeholder="请输入会员金额"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="isEdited === true" @click="submitForm()">保 存</el-button>
        <el-button type="primary" v-else @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      isEdited: false,
      // 添加设备表单数据
      addForm: {
        tags: '0',
        tag: '0',
        memberName: '',
        memberAmount: '',
        deposit: '',
        id: ''
      },
      addFormRules: {
        memberName: [
          { required: true, message: '请填写会员名称', trigger: 'change' }
        ],
        memberAmount: [
          { required: true, message: '请填写会员金额', trigger: 'change' }
        ],
        deposit: [
          { required: true, message: '请填写押金金额', trigger: 'change' }
        ],
        tags: [{ required: true, message: '请选择会员标签', trigger: 'change' }]
      }
    }
  },
  created() {
    this.getMemberList(this.queryInfo)
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/memberLevel/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    showAddView() {
      this.addForm.tag = '0'
      this.addForm.tags = '0'
      this.addForm.memberName = ''
      this.addForm.memberAmount = ''
      this.addForm.deposit = ''
      this.addForm.id = ''
      this.addDialogVisible = false
      this.isEdited = false
      this.addDialogVisible = true
    },
    showEditView(row) {
      this.isEdited = true
      this.addForm.tags = row.tags
      if (row.tags === '1') {
        this.addForm.tag = '1'
      } else if (row.tags === '2') {
        this.addForm.tag = '2'
      } else {
        this.addForm.tag = '0'
      }
      this.addForm.deposit = row.deposit
      this.addForm.memberAmount = row.memberAmount
      this.addForm.id = row.id
      this.addForm.memberName = row.memberName
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addForm.tag = '0'
        this.addForm.tags = '0'
        this.addForm.memberName = ''
        this.addForm.memberAmount = ''
        this.addForm.deposit = ''
        this.addForm.id = ''
        this.addDialogVisible = false
        this.isEdited = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    chooseTags(e) {
      console.log(e)
      if (e === '0') {
        this.addForm.tags = '0'
      } else if (e === '1') {
        this.addForm.tags = '1'
      } else {
        this.addForm.tags = '2'
      }
    },
    deleteMemberlevelConfirm(id) {
      this.$confirm('此操作将删除该会员信息, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteLevel(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async deleteLevel(id) {
      const { data: res } = await this.$http.get('/memberLevel/delete/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getMemberList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getMemberList(this.queryInfo)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getMemberList(this.queryInfo)
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        if (this.isEdited === false) {
          if (
            this.addForm.memberName === '普通会员' ||
            this.addForm.memberName === '银卡会员' ||
            this.addForm.memberName === '金卡会员'
          ) {
            this.$message.info('您添加的会员已存在,请重新添加')
            return false
          }
        }
        const { data: res } = await this.$http.post(
          '/memberLevel/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        if (this.isEdited) {
          this.$message.success('修改成功')
        } else {
          this.$message.success('添加成功')
        }
        this.addDialogVisible = false
        this.getMemberList(this.queryInfo)
        this.isEdited = false
      })
    }
  }
}
</script>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.el-radio {
  font-weight: 400;
}
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.unit {
  width: 50%;
  text-align: center;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.2);
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.dialog-row {
  margin-bottom: 0px;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
</style>
