<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑物流公司</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tabView">
      <!-- <el-button size="small" :type="companyTabType" style="margin-left:20px" @click="changeTab(0)">认证资料</el-button> -->
      <el-button size="small" :type="memberTabType" style="margin:10px 20px" @click="changeTab(1)">物流信息</el-button>
      <el-button size="small" :type="lineTabType" style="margin:10px 0px" @click="changeTab(2)">发布专线</el-button>
      <el-button size="small" :type="supplyTabType" style="margin-left:20px" @click="changeTab(3)">发布货源</el-button>
    </div>
    <!-- 物流卡片视图区 -->
    <el-card :hidden="tabId===1?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="90px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="head">基本资料</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="公司名称:" prop="companyName">
              <el-input class="dialog-input" v-model="addForm.companyName" disabled placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="业务经理:" prop="manager">
              <el-input class="dialog-input" v-model="addForm.manager" placeholder="请输入业务经理姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="手机号码:" prop="managerPhone">
              <el-input class="dialog-input" v-model="addForm.managerPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司座机:" prop="telephone">
              <el-input class="dialog-input" v-model="addForm.telephone" placeholder="请输入公司座机"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="查货电话:" prop="hotline">
              <el-input class="dialog-input" v-model="addForm.hotline" placeholder="请输入查货电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="询价电话:" prop="enquiryPhone">
              <el-input class="dialog-input" v-model="addForm.enquiryPhone" placeholder="请输入询价电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <el-form-item label="公司地址:" prop="address">
              <el-input class="dialog-input" v-model="addForm.address" disabled placeholder="请输入公司地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <el-form-item label="公告:" prop="announcement">
              <el-input class="dialog-input" type="textarea" v-model="addForm.announcement" placeholder="请输入公告信息"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="公司缩略图：">
              <el-upload :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handlePhotoExceed" :before-upload="beforeAvatarUpload" :on-remove="handlePhotoRemove" :on-success="handlePhotoSuccess" :file-list="photoFileList" multiple :class="{hide:photoHide}">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-form :model="addBranchOfficeForm" ref="addBranchOfficeFormRef" label-width="90px" size='mini' label-position="right">
        <el-row class="dialog-row-title">
          <el-col :span="8" style="display:flex;align-items: center;justify-content: space-between;">
            <div class="head" style="margin-bottom: 10px;">分公司资料</div>
            <el-button size="mini" type="primary" @click="addOfficeView()">添加</el-button>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <template>
            <div class="branchOfficeView" v-for="(item,index) in addBranchOfficeForm.branchCompanyList" :key="index">
              <img src="../../assets/close@2x.png" class="closeImg" @click="closeImg(index)" alt="">
              <el-form-item label="分公司名称:" prop="companyName" style="margin-bottom:5px;">
                <el-input class="dialog-input" v-model="item.companyName" placeholder="请输入分公司名称"></el-input>
              </el-form-item>
              <el-form-item label="分公司座机:" prop="telephone" style="margin-bottom:5px;">
                <el-input class="dialog-input" v-model="item.telephone" placeholder="请输入分公司座机"></el-input>
              </el-form-item>
              <el-form-item label="分公司地址:" prop="address" style="margin-bottom:20px;">
                <el-input class="dialog-input" v-model="item.address" placeholder="请输入分公司地址"></el-input>
              </el-form-item>
            </div>
          </template>
        </el-row>
      </el-form>
      <el-row class="dialog-row-title">
        <el-col :span="8">
          <div class="head" style="margin-bottom: 10px;">公司图片</div>
        </el-col>
      </el-row>
      <el-form :model="addImageForm" ref="addImageForm" label-width="90px" size='mini' label-position="right">
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="办公场景：">
              <el-upload style="display:flex;" :action="uploadUrl" :headers="headers" :auto-upload="true" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='8' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess" :file-list="busFileList" multiple :class="{hide:hideUpload}">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="员工风采：">
              <el-upload style="display:flex;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='8' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleMemberRemove" :on-success="handleMemberSuccess" :file-list="memberFileList" multiple :class="{hide:memberHide}">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete" @click="handleMemberRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="运力展示：">
              <el-upload style="display:flex;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='8' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleAbilityRemove" :on-success="handleAbilitySuccess" :file-list="abilityFileList" multiple :class="{hide:abilityHide}">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete" @click="handleAbilityRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24">
            <el-form-item label="资质证书：">
              <el-upload style="display:flex;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='8' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleCertificationRemove" :on-success="handleCertificationSuccess" :file-list="certificationFileList" multiple :class="{hide:certificationHide}">
                <i class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-delete" @click="handleCertificationRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <i class="el-icon-zoom-in"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row-title">
          <el-col :span="8">
            <div class="head" style="margin-bottom: 10px;">互动信息</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="auth-item" style="margin-bottom:10px;">
              <span class="item-left" style="width:85px;">阅读数量:</span>
              <el-input class="dialog-input" style="width:100%;" v-model="addForm.readNum" placeholder="请输入阅读数量"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="auth-item" style="margin-bottom:10px;">
              <span class="item-left" style="width:85px;">分享数量:</span>
              <el-input class="dialog-input" style="width:100%;" v-model="addForm.sharedNum" placeholder="请输入分享数量"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="auth-item" style="margin-bottom:10px;">
              <span class="item-left" style="width:85px;">收藏数量:</span>
              <el-input class="dialog-input" style="width:100%;" v-model="addForm.storedNum" placeholder="请输入收藏数量"></el-input>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button @click="goBack">取 消</el-button>
        <el-button type="primary" @click="submitAddForm()">保 存</el-button>
      </span>
    </el-card>
    <el-card :hidden="tabId===2?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addLineForm" label-width="90px" size='mini' label-position="right">
        <el-row class="dialog-row-title">
          <el-col :span="24" style="display:flex;align-items: center;justify-content: space-between;">
            <div class="head" style="margin-bottom: 10px;">专线信息</div>
            <el-button size="mini" type="primary" @click="addLineView()">添加专线</el-button>
          </el-col>
        </el-row>
        <el-row class="dialog-row" v-for="(item,index) in addLineForm.lineList" :key="index">
          <el-col :span="16">
            <div style="display: flex;align-items: center;justify-content: space-around;padding-bottom:20px;">
              <div class="lineView">
                <img src="../../assets/close@2x.png" class="closeImg" @click="closeLineImg(index)" alt="">
                <el-cascader clearable style="width:200px;" v-model="item.lineStartAddress" :props="defultProp" :options="address" @change=" (val) =>{handleStartChange(val,index);}"></el-cascader>
                <img src="../../assets/arrow-forward@2x.png" width="16px" height="16px" alt="">
                <el-cascader clearable style="width:200px;" :props="defultProp" :options="address" @change=" (val) =>{handleDestinationChange(val,index);}"></el-cascader>
              </div>
              <el-switch v-model="item.type" style="margin-left:20px;" active-text="发布" inactive-text="下架" @change="(val) => {handleSwitchChange(val,index)}">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button @click="goBack">取 消</el-button>
        <el-button type="primary" @click="submitLineForm()">保 存</el-button>
      </span>
    </el-card>
    <el-card :hidden="tabId===3?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addGoodsForm" ref="addGoodsFormRef" :rules="addGoodsFormRules" label-width="140px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="head">添加货源</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="发货人昵称:">
              <el-input class="dialog-input" v-model="addGoodsForm.shipperName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发货人账号:">
              <el-input class="dialog-input" v-model="addGoodsForm.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="发货人身份:">
              <el-input class="dialog-input" value="物流公司" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="出发地:" prop="startCity">
              <template>
                <el-cascader clearable style="width:100%;" v-model="startAddress" :props="defultProp" :options="address" @change="handleGoodsStartChange"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="目的地:" prop="destinationCity">
              <template>
                <el-cascader clearable style="width:100%;" v-model="destinationAddress" :props="defultProp" :options="address" @change="handleGoodsDestinationChange"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="货物名称:" prop="goodsName">
              <el-input class="dialog-input" v-model="addGoodsForm.goodsName" placeholder="请输入货物名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="装货时间:" prop="loadingTime">
              <el-date-picker style="width:100%;" v-model="addGoodsForm.loadingTime" type="datetime" value-format="yyyy-MM-dd hh:mm:ss" placeholder="选择日期时间" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="车辆类型:" prop="vehicleType">
              <el-cascader clearable style="width:100%" class="dialog-input" :props="defultCarProp" :options="carTypeList" @change="handleCarTypeChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货物重量(吨)" prop="goodsWeight">
              <el-input class="dialog-input" v-model="addGoodsForm.goodsWeight" placeholder="请输入货物重量"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="装货地址:" prop="loadingAddress">
              <el-input class="dialog-input" v-model="addGoodsForm.loadingAddress" placeholder="请输入装货地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货物体积(立方米):" prop="goodsVolume">
              <el-input class="dialog-input" v-model="addGoodsForm.goodsVolume" placeholder="请输入货物体积"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="运费预算:" prop="freightBudget">
              <el-input class="dialog-input" v-model="addGoodsForm.freightBudget" placeholder="请输入运费预算"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系方式:" prop="contact">
              <el-input class="dialog-input" v-model="addGoodsForm.contact" placeholder="请输入联系方式"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <el-form-item label="特别说明:" prop="remarks">
              <el-input class="dialog-input" v-model="addGoodsForm.remarks" placeholder="请输入特别说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button type="primary" @click="submitGoodsForm()">发 布</el-button>
        <el-button @click="goBack">取 消</el-button>
      </span>
    </el-card>
    <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      memberTabType: 'primary',
      supplyTabType: '',
      lineTabType: '',
      tabId: 1,
      userInfo: {},
      uploadUrl: 'https://api.sansongkeji.com/files/upload/logo',
      // uploadUrl: 'http://mirrortest.lzs-iot.com:8806/files/upload/logo',
      addressStr: '',
      // 添加设备表单数据
      addForm: {
        companyName: '',
        address: '',
        announcement: '',
        enquiryPhone: '',
        hotline: '',
        manager: '',
        telephone: '',
        managerPhone: '',
        id: '',
        city: '',
        district: '',
        province: '',
        photo: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      addFormRules: {
        managerPhone: [
          {
            required: true,
            message: '请填写业务经理手机号',
            trigger: 'change'
          },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ],
        telephone: [
          {
            required: true,
            message: '请填写公司座机号码',
            trigger: 'change'
          }
        ],
        enquiryPhone: [
          {
            required: true,
            message: '请填写询价手机号',
            trigger: 'change'
          }
        ],
        hotline: [
          {
            required: true,
            message: '请填写查货手机号',
            trigger: 'change'
          }
        ],
        manager: [
          { required: true, message: '请填写业务经理姓名', trigger: 'change' }
        ]
      },
      addImageForm: {
        officePictureList: [],
        abilityPictureList: [],
        certificationPictureList: [],
        staffPictureList: []
      },
      addBranchOfficeForm: {
        branchCompanyList: []
      },
      addLineForm: {
        lineList: [
          {
            startCity: '',
            startDistrict: '',
            startProvince: '',
            destinationCity: '',
            destinationDistrict: '',
            destinationProvince: '',
            state: 1,
            type: true,
            logisticsCompanyId: '',
            userId: '',
            lineStartAddress: []
          }
        ]
      },
      addGoodsForm: {
        shipperName: '',
        phone: '',
        startProvince: '',
        startCity: '',
        startDistrict: '',
        destinationCity: '',
        destinationDistrict: '',
        destinationProvince: '',
        goodsName: '',
        loadingTime: '',
        loadingAddress: '',
        vehicleType: '',
        goodsWeight: '',
        goodsVolume: '',
        freightBudget: '',
        contact: '',
        remarks: '',
        logisticsCompanyId: '',
        userType: '02.logistics',
        userId: ''
      },
      startAddress: '',
      destinationAddress: '',
      addGoodsFormRules: {
        phone: [
          { required: true, message: '请填写发货人手机号', trigger: 'change' }
        ],
        userType: [
          { required: true, message: '请选择发货人身份', trigger: 'change' }
        ],
        startCity: [
          { required: true, message: '请选择出发地', trigger: 'change' }
        ],
        destinationCity: [
          { required: true, message: '请选择目的地', trigger: 'change' }
        ],
        goodsName: [
          { required: true, message: '请填写货物名称', trigger: 'change' }
        ],
        loadingTime: [
          { required: true, message: '请选择装货时间', trigger: 'change' }
        ],
        vehicleType: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        loadingAddress: [
          { required: true, message: '请填写装货地址', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '请填写联系方式', trigger: 'change' },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ]
      },
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      defultCarProp: {
        label: 'name',
        value: 'name',
        children: 'lengthList',
        expandTrigger: 'hover'
      },
      address: [],
      headers: {},
      busFileList: [],
      memberFileList: [],
      abilityFileList: [],
      certificationFileList: [],
      photoFileList: [],
      photoHide: false,
      hideUpload: false,
      memberHide: false,
      abilityHide: false,
      certificationHide: false,
      carTypeList: []
    }
  },
  created() {
    // this.getMemberList(this.queryInfo)
    this.address = json
    this.headers.Authorization = window.localStorage.getItem('token')
    this.loadCompanyDetail(this.$route.query.id)
    this.carTypeList = [
      {
        name: '小面包车',
        lengthList: [
          {
            name: '2.4米'
          }
        ]
      },
      {
        name: '中面包车',
        lengthList: [
          {
            name: '3.2米'
          }
        ]
      },
      {
        name: '厢式车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '平板车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '高栏车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      }
    ]
  },
  methods: {
    goBack() {
      this.$refs.addGoodsFormRef.resetFields()
      this.$router.back(-1)
    },
    addOfficeView() {
      var dic = {
        address: '',
        city: '',
        companyName: '',
        district: '',
        province: '',
        telephone: ''
      }
      this.addBranchOfficeForm.branchCompanyList.push(dic)
    },
    closeImg(index) {
      if (this.addBranchOfficeForm.branchCompanyList.length > 0) {
        this.addBranchOfficeForm.branchCompanyList.splice(index, 1)
      }
    },
    addLineView() {
      var dic = {
        startCity: '',
        startDistrict: '',
        startProvince: '',
        destinationCity: '',
        destinationDistrict: '',
        destinationProvince: '',
        lineStartAddress: [],
        state: 1,
        type: true,
        logisticsCompanyId: this.addGoodsForm.logisticsCompanyId,
        userId: this.userInfo.id
      }
      this.addLineForm.lineList.push(dic)
    },
    closeLineImg(index) {
      if (this.addLineForm.lineList.length > 1) {
        this.addLineForm.lineList.splice(index, 1)
      }
    },
    handlePhotoRemove(file, fileList) {
      this.addForm.photo = ''
      this.photoFileList = fileList
      this.photoHide = fileList.length >= 1
      console.log(this.addForm)
    },
    handleRemove(file) {
      var arr = this.addImageForm.officePictureList
      arr.forEach((item) => {
        item.url = item.picUrl
      })
      for (var i in arr) {
        if (file.id === '' || file.id === null) {
          if (arr[i].picUrl === file.response.data) {
            arr.splice(i, 1)
            break
          }
        } else {
          if (arr[i].picUrl === file.picUrl) {
            arr.splice(i, 1)
            break
          }
        }
      }
      this.addImageForm.officePictureList = arr
      this.busFileList = arr
      this.hideUpload = arr.length >= 8
    },
    handleMemberRemove(file) {
      var arr = this.addImageForm.staffPictureList
      arr.forEach((item) => {
        item.url = item.picUrl
      })
      for (var i in arr) {
        if (file.id === '' || file.id === null) {
          if (arr[i].picUrl === file.response.data) {
            arr.splice(i, 1)
            break
          }
        } else {
          if (arr[i].picUrl === file.picUrl) {
            arr.splice(i, 1)
            break
          }
        }
      }
      this.addImageForm.staffPictureList = arr
      this.memberFileList = arr
      this.memberHide = arr.length >= 8
    },
    handleAbilityRemove(file) {
      var arr = this.addImageForm.abilityPictureList
      arr.forEach((item) => {
        item.url = item.picUrl
      })
      for (var i in arr) {
        if (file.id === '' || file.id === null) {
          if (arr[i].picUrl === file.response.data) {
            arr.splice(i, 1)
            break
          }
        } else {
          if (arr[i].picUrl === file.picUrl) {
            arr.splice(i, 1)
            break
          }
        }
      }
      this.addImageForm.abilityPictureList = arr
      this.abilityFileList = arr
      this.abilityHide = arr.length >= 8
    },
    handleCertificationRemove(file) {
      var arr = this.addImageForm.certificationPictureList
      arr.forEach((item) => {
        item.url = item.picUrl
      })
      for (var i in arr) {
        if (file.id === '' || file.id === null) {
          if (arr[i].picUrl === file.response.data) {
            arr.splice(i, 1)
            break
          }
        } else {
          if (arr[i].picUrl === file.picUrl) {
            arr.splice(i, 1)
            break
          }
        }
      }
      this.addImageForm.certificationPictureList = arr
      this.certificationFileList = arr
      this.certificationHide = arr.length >= 8
    },
    handleSuccess(res, file, fileList) {
      this.hideUpload = fileList.length >= 8
      var dic = {
        picType: 'office',
        picUrl: res.data,
        companyBaseId: this.addGoodsForm.logisticsCompanyId,
        state: 1
      }
      this.busFileList = fileList
      console.log(this.busFileList)
      this.addImageForm.officePictureList.push(dic)
    },
    handlePhotoSuccess(res, file, fileList) {
      this.photoHide = fileList.length >= 1
      this.photoFileList = fileList
      this.addForm.photo = res.data
      console.log(this.addForm)
    },
    handleMemberSuccess(res, file, fileList) {
      this.memberHide = fileList.length >= 8
      var dic = {
        picType: 'staff',
        picUrl: res.data,
        companyBaseId: this.addGoodsForm.logisticsCompanyId,
        state: 1
      }
      this.memberFileList = fileList
      this.addImageForm.staffPictureList.push(dic)
    },
    handleAbilitySuccess(res, file, fileList) {
      this.abilityHide = fileList.length >= 8
      var dic = {
        picType: 'ability',
        picUrl: res.data,
        companyBaseId: this.addGoodsForm.logisticsCompanyId,
        state: 1
      }
      this.abilityFileList = fileList
      this.addImageForm.abilityPictureList.push(dic)
    },
    handleCertificationSuccess(res, file, fileList) {
      this.certificationHide = fileList.length >= 8
      var dic = {
        picType: 'certification',
        picUrl: res.data,
        companyBaseId: this.addGoodsForm.logisticsCompanyId,
        state: 1
      }
      this.certificationFileList = fileList
      this.addImageForm.certificationPictureList.push(dic)
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      var testmsg = /^image\/(jpeg|png|jpg|gif|bmp)$/.test(file.type)
      const isLt1M = file.size / 1024 / 1024 < 3
      if (!testmsg) {
        this.$message.error('上传图片格式不正确！')
      }
      if (!isLt1M) {
        this.$message.warning('上传图片大小不能超过3MB!')
      }
      return isLt1M
    },
    handleExceed() {
      this.$message.error('只能上传8张图片')
    },
    handlePhotoExceed() {
      this.$message.error('只能上传1张图片')
    },
    handleStartChange(city, index) {
      var arr = this.addLineForm.lineList
      for (let i = 0; i < arr.length; i++) {
        if (i === index) {
          var dic = arr[i]
          if (city[2] === '全市') {
            dic.lineStartAddress = []
            this.$message.info('请重新选择具体的区县')
            return false
          }
          dic.startCity = city[1]
          dic.startDistrict = city[2]
          dic.startProvince = city[0]
        }
      }
    },
    handleDestinationChange(city, index) {
      var arr = this.addLineForm.lineList
      for (let i = 0; i < arr.length; i++) {
        if (i === index) {
          var dic = arr[i]
          dic.destinationCity = city[1]
          dic.destinationDistrict = city[2]
          dic.destinationProvince = city[0]
        }
      }
    },
    handleSwitchChange(val, index) {
      var arr = this.addLineForm.lineList
      for (let i = 0; i < arr.length; i++) {
        if (i === index) {
          var dic = arr[i]
          dic.type = val
          if (val) {
            dic.state = 1
          } else {
            dic.state = 0
          }
        }
      }
    },
    handleGoodsStartChange(city) {
      this.addGoodsForm.startCity = city[1]
      this.addGoodsForm.startDistrict = city[2]
      this.addGoodsForm.startProvince = city[0]
    },
    handleGoodsDestinationChange(city) {
      this.addGoodsForm.destinationCity = city[1]
      this.addGoodsForm.destinationDistrict = city[2]
      this.addGoodsForm.destinationProvince = city[0]
    },
    handleCarTypeChange(e) {
      this.addGoodsForm.vehicleType = e[0] + e[1]
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    async loadCompanyDetail(id) {
      const { data: res } = await this.$http.get(
        '/logisticsCompany/detail/' + id
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.addForm = res.data.logisticsCompanyBaseInfo
      if (this.addForm.photo !== '' && this.addForm.photo !== null) {
        var picDic = {
          url: res.data.logisticsCompanyBaseInfo.photo
        }
        this.photoFileList.push(picDic)
        this.photoHide = true
      }
      res.data.logisticsCompanyBaseInfo.officePictureList.forEach((item) => {
        var dic = {
          picType: item.picType,
          picUrl: item.picUrl,
          companyBaseId: item.companyBaseId,
          id: item.id
        }
        this.addImageForm.officePictureList.push(dic)
      })
      res.data.logisticsCompanyBaseInfo.abilityPictureList.forEach((item) => {
        var dic = {
          picType: item.picType,
          picUrl: item.picUrl,
          companyBaseId: item.companyBaseId,
          id: item.id
        }
        this.addImageForm.abilityPictureList.push(dic)
      })
      res.data.logisticsCompanyBaseInfo.certificationPictureList.forEach(
        (item) => {
          var dic = {
            picType: item.picType,
            picUrl: item.picUrl,
            companyBaseId: item.companyBaseId,
            id: item.id
          }
          this.addImageForm.certificationPictureList.push(dic)
        }
      )
      res.data.logisticsCompanyBaseInfo.staffPictureList.forEach((item) => {
        var dic = {
          picType: item.picType,
          picUrl: item.picUrl,
          companyBaseId: item.companyBaseId,
          id: item.id
        }
        this.addImageForm.staffPictureList.push(dic)
      })
      this.addBranchOfficeForm.branchCompanyList =
        res.data.logisticsCompanyBaseInfo.branchCompanyList
      this.busFileList = res.data.logisticsCompanyBaseInfo.officePictureList
      this.busFileList.forEach((item) => {
        item.url = item.picUrl
      })
      this.memberFileList = res.data.logisticsCompanyBaseInfo.staffPictureList
      this.abilityFileList =
        res.data.logisticsCompanyBaseInfo.abilityPictureList
      this.certificationFileList =
        res.data.logisticsCompanyBaseInfo.certificationPictureList
      this.memberFileList.forEach((item) => {
        item.url = item.picUrl
      })
      this.abilityFileList.forEach((item) => {
        item.url = item.picUrl
      })
      this.certificationFileList.forEach((item) => {
        item.url = item.picUrl
      })
      this.userInfo = res.data.userInfo
      this.addLineForm.lineList[0].userId = res.data.userInfo.id
      this.addLineForm.lineList[0].logisticsCompanyId =
        res.data.certificationInfo.id
      this.addGoodsForm.userId = res.data.userInfo.id
      this.addGoodsForm.shipperName = res.data.userInfo.companyName
      this.addGoodsForm.phone = res.data.userInfo.phone
      this.addGoodsForm.logisticsCompanyId = res.data.certificationInfo.id
    },
    submitAddForm() {
      var dic = this.addForm
      var list = this.addImageForm.officePictureList
      if (list.length === 0) {
        this.$message.info('请上传至少一张公司图片')
        return false
      }
      dic.photo = list[0].picUrl
      dic.branchCompanyList = this.addBranchOfficeForm.branchCompanyList
      dic.abilityPictureList = this.addImageForm.abilityPictureList
      dic.certificationPictureList = this.addImageForm.certificationPictureList
      dic.officePictureList = list
      dic.staffPictureList = this.addImageForm.staffPictureList
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        const { data: res } = await this.$http.post(
          '/logisticsCompany/update/base',
          dic
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('更新成功')
        this.goBack()
      })
    },
    submitLineForm() {
      var arr = this.addLineForm.lineList
      arr.forEach(async (item) => {
        if (item.startCity === '' || item.destinationCity === '') {
          this.$message.info('请选择省市区')
          return false
        }
        const { data: res } = await this.$http.post('/logisticsLine/save', item)
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
      })
    },
    changeTab(id) {
      this.tabId = id
      if (id === 1) {
        this.memberTabType = 'primary'
        this.supplyTabType = ''
        this.lineTabType = ''
      } else if (id === 2) {
        this.memberTabType = ''
        this.supplyTabType = ''
        this.lineTabType = 'primary'
      } else {
        this.memberTabType = ''
        this.supplyTabType = 'primary'
        this.lineTabType = ''
      }
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.searchForm.type = this.queryInfo.type
      this.getMemberList(this.searchForm)
    },
    handleChange() {},
    submitGoodsForm() {
      console.log(this.addGoodsForm)
      this.$refs.addGoodsFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        const { data: res } = await this.$http.post(
          '/goodsSource/save',
          this.addGoodsForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addGoodsFormRef.resetFields()
        this.$router.back(-1)
      })
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.branchOfficeView {
  width: 400px;
  padding: 20px 20px 0 0;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.lineView {
  width: 450px !important;
  padding: 20px;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.closeImg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
}
.el-form-item__content {
  display: flex;
  flex-direction: row;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 100%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}
.el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
}
</style>

<style lang="less" scoped>
.tabView {
  background-color: white;
  margin-bottom: 10px;
}

.dialog-row {
  margin-bottom: 0;
}
.dialog-row-title {
  margin-top: 20px;
  margin-bottom: 0;
}

/* .dialog-input >>> .el-input__inner {
  height: 50px;
} */
.examine-Image {
  position: absolute;
  width: 146px;
  height: 50px;
  top: 50px;
  left: 0;
}
</style>

<style>
.hide .el-upload--picture-card {
  display: none !important;
}
