import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入NProgress 包对应的JS和CSS
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 导入全局样式表
import './assets/css/global.css'
import './assets/icons/iconfont.css'
import axios from 'axios'
// 引入 echarts
import * as echarts from 'echarts'
import {
  Loading
} from 'element-ui'
import {
  isAuth
} from '@/utils/auth.js'
Vue.use(Loading.directive)
// 注册组件
Vue.prototype.$echarts = echarts
Vue.prototype.isAuth = isAuth

// 配置数据请求跟路径
axios.defaults.baseURL = 'https://api.sansongkeji.com'
// axios.defaults.baseURL = 'http://192.168.2.2:9089'
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers['Content-Type'] = 'application/json'
  config.data = JSON.stringify(config.data)
  var token = window.localStorage.getItem('token')
  if (config.url !== '/user/login/web') {
    config.headers.Authorization = token
  }
  return config
})
axios.interceptors.response.use(config => {
  NProgress.done()
  if (config.data.code === 5001 || config.data.code === 5005) {
    sessionStorage.clear()
    router.replace({
      path: '/login'
    })
  }
  return config
})
Vue.prototype.$http = axios

// Vue.component('icon', Icon)

Vue.config.productionTip = false

Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return y - m - d - hh - mm - ss
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
