<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>车主管理</el-breadcrumb-item>
      <el-breadcrumb-item>车主详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <div class="bodyView">
        <div class="itemView">
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="head">身份证信息</div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">头像:</span>
                <el-image v-if="driverIdentityInfo.avatar==''||driverIdentityInfo.avatar==null" src="../../assets/userImage9-show.png" style="width:50px;height:50px;border-radius:50px;" alt="" :preview-src-list="avatarList">
                </el-image>
                <el-image v-else :src="driverIdentityInfo.avatar.length>10?driverIdentityInfo.avatar:require('../../assets/'+driverIdentityInfo.avatar+'-show.png')" alt="" style="width:40px;height:40px;border-radius:40px;" :preview-src-list="avatarList">
                </el-image>
                <!-- <img v-if="driverIdentityInfo.avatar==''||driverIdentityInfo.avatar==null" src="../../assets/userImage9-show.png" width="50px" height="50px" style="border-radius:50px;" alt="">
                <img v-else :src="driverIdentityInfo.avatar.length>10?driverIdentityInfo.avatar:require('../../assets/'+driverIdentityInfo.avatar+'-show.png')" alt="" style="width:40px;height:40px;border-radius:40px;"> -->
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">昵称/姓名:</span>
                <span class="item-right">{{driverIdentityInfo.nickName}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">手机号:</span>
                <span class="item-right">{{driverIdentityInfo.phone}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">身份证号:</span>
                <span class="item-right">{{driverIdentityInfo.idCardNo}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">身份证照片:</span>
                <!-- <img :src="driverIdentityInfo.idCardFrontUrl" width="80px" height="40px" alt="">
                <img :src="driverIdentityInfo.idCardBackUrl" width="80px" height="40px" style="margin-left:20px;" alt=""> -->
                <el-image :src="driverIdentityInfo.idCardFrontUrl" style="width:80px;height:40px;" :preview-src-list="idCardList">
                </el-image>
                <el-image :src="driverIdentityInfo.idCardBackUrl" style="width:80px;height:40px;margin-left:20px;" :preview-src-list="idCardList">
                </el-image>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="itemView">
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="head">车辆信息</div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">车牌号:</span>
                <span class="item-right">{{vehicleInfo.licenseNo}}</span>
              </div>
            </el-col>
          </el-row>
          <!-- <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">长宽高(米):</span>
                <span class="item-right">{{vehicleInfo.vehicleSize}}</span>
              </div>
            </el-col>
          </el-row> -->
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">载重(吨):</span>
                <span class="item-right">{{vehicleInfo.loadCapacity}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">车辆类型:</span>
                <span class="item-right">{{vehicleInfo.vehicleType}}</span>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">行驶证照片:</span>
                <!-- <img :src="vehicleInfo.vehicleLicenseOriginalUrl" width="80px" height="40px" alt="">
                <img :src="vehicleInfo.vehicleLicenseEctypeUrl" width="80px" height="40px" style="margin-left:20px;" alt=""> -->
                <el-image :src="vehicleInfo.vehicleLicenseOriginalUrl" style="width:80px;height:40px;" :preview-src-list="carList">
                </el-image>
                <el-image :src="vehicleInfo.vehicleLicenseEctypeUrl" style="width:80px;height:40px;margin-left:20px;" :preview-src-list="carList">
                </el-image>
              </div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">车辆照片:</span>
                <!-- <img :src="vehicleInfo.vehicleFrontUrl" width="80px" height="40px" alt="">
                <img :src="vehicleInfo.vehicleBackUrl" width="80px" height="40px" style="margin-left:20px;" alt=""> -->
                <el-image :src="vehicleInfo.vehicleFrontUrl" style="width:80px;height:40px;" :preview-src-list="carList">
                </el-image>
                <el-image :src="vehicleInfo.vehicleBackUrl" style="width:80px;height:40px;margin-left:20px;" :preview-src-list="carList">
                </el-image>
              </div>
            </el-col>
          </el-row>
          <!-- <div class="unit">*上传车辆左前方和右后方照片</div> -->
        </div>
        <div class="itemView">
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="head">驾驶证信息</div>
            </el-col>
          </el-row>
          <el-row class="dialog-row">
            <el-col :span="24">
              <div class="item">
                <span class="item-left">驾驶证照片:</span>
                <!-- <img :src="vehicleInfo.drivingLicenseOriginalUrl" width="80px" height="40px" alt="">
                <img :src="vehicleInfo.drivingLicenseEctypeUrl" width="80px" height="40px" style="margin-left:20px;" alt=""> -->
                <el-image :src="vehicleInfo.drivingLicenseOriginalUrl" style="width:80px;height:40px;" :preview-src-list="drivingList">
                </el-image>
                <el-image :src="vehicleInfo.drivingLicenseEctypeUrl" style="width:80px;height:40px;margin-left:20px;" :preview-src-list="drivingList">
                </el-image>
              </div>
            </el-col>
          </el-row>
          <!-- <div class="unit">*上传驾驶证正本和副本照片</div> -->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      driverIdentityInfo: {},
      vehicleInfo: {},
      avatarList: [],
      idCardList: [],
      carList: [],
      drivingList: []
    }
  },
  created() {
    this.loadOwnerDetail(this.$route.query.id)
  },
  methods: {
    goBack() {
      this.$router.back(-1)
    },
    async loadOwnerDetail(id) {
      const { data: res } = await this.$http.get('/driverIdentity/detail/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.driverIdentityInfo = res.data.driverIdentityInfo
      this.vehicleInfo = res.data.vehicleInfo
      this.avatarList.push(this.driverIdentityInfo.avatar)
      this.idCardList.push(this.driverIdentityInfo.idCardFrontUrl)
      this.idCardList.push(this.driverIdentityInfo.idCardBackUrl)
      this.carList.push(this.vehicleInfo.vehicleLicenseOriginalUrl)
      this.carList.push(this.vehicleInfo.vehicleLicenseEctypeUrl)
      this.carList.push(this.vehicleInfo.vehicleFrontUrl)
      this.carList.push(this.vehicleInfo.vehicleBackUrl)
      this.drivingList.push(this.vehicleInfo.drivingLicenseOriginalUrl)
      this.drivingList.push(this.vehicleInfo.drivingLicenseEctypeUrl)
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 100%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.addfrom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.bodyView {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemView {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.unit {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.2);
}
.item {
  display: flex;
  align-items: center;
}
.item-left {
  width: 75px;
  font-size: 14px;
  text-align: right;
  margin-right: 20px;
}
.item-right {
  font-size: 14px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.dialog-row {
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
