<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>业务中心</el-breadcrumb-item>
      <el-breadcrumb-item>货源管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" :rules="searchFormRules" class="searchForm" size="mini " label-width="90px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="货源状态" prop="state">
              <el-select v-model="searchForm.state" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="已下架" value="0"></el-option>
                <el-option label="已发布" value="1"></el-option>
                <el-option label="已结束" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发货人账号" prop="phone">
              <el-input class="searchInput" v-model="searchForm.phone" placeholder="请输入发货人手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="货源ID" prop="id">
              <el-input class="searchInput" v-model="searchForm.id" placeholder="请输入货源ID" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="发布时间" prop="dateRange">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">货源数量：{{total}}</div>
          <div class="reportText">今日新增：{{todayQuantity}}</div>
        </div>
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/business/supplyManagement/add')" v-loading.fullscreen.lock="fullscreenLoading" @click="cardExport">导出</el-button>
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/business/supplyManagement/add')" @click="showEditView()">添加货源</el-button>
        </div>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="supplyList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column type="selection" width="40">
        </el-table-column>
        <el-table-column label="ID" align="center" prop="id"></el-table-column>
        <el-table-column label="出发地" align="center" prop="startCity"></el-table-column>
        <el-table-column label="目的地" align="center" prop="destinationCity"></el-table-column>
        <el-table-column label="货物名称" align="center" prop="goodsName"></el-table-column>
        <el-table-column label="车辆类型" align="center" prop="vehicleType" min-width="120"></el-table-column>
        <el-table-column label="运费预算" align="center" prop="freightBudget"></el-table-column>
        <el-table-column label="装货时间" align="center" prop="loadingTime" min-width="140"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">已发布</div>
            <div v-else-if="scope.row.state == 2">已结束</div>
            <div v-else>已下架</div>
          </template>
        </el-table-column>
        <el-table-column label="发货人身份" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.userType == '02.logistics'">物流公司</div>
            <div v-else-if="scope.row.userType == '03.driver'">车主</div>
            <div v-else>货主</div>
          </template>
        </el-table-column>
        <el-table-column label="发货人昵称" align="center" prop="shipperName"></el-table-column>
        <el-table-column label="发货人账号" align="center" prop="phone" min-width="100"></el-table-column>
        <el-table-column label="发布时间" align="center" prop="createTime" min-width="140"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="150px">
          <template slot-scope="scope">
            <!-- <el-button type="text" size="mini" v-if="scope.row.state == 2||scope.row.state == 1" style="color: #979cb2" disabled>激活</el-button>
            <el-button type="text" size="mini" v-else @click="disableDevice(scope.row)">激活</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1ac47c" @click="editDevice(scope.row)">修改</el-button>
            <el-button type="text" size="mini" v-if="scope.row.state == 0" style="margin-left: 8px; color: #979cb2" disabled>禁用</el-button>
            <el-button type="text" size="mini" v-else style="margin-left: 8px; color: #ff5252" @click="disableDevice(scope.row)">禁用</el-button> -->
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/business/supplyManagement/delete')" @click="deleteDeviceConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/business/supplyManagement/detail')" @click="showSupplyDetail(scope.row.id)">查看</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/business/supplyManagement/edit')" @click="editSupplyDetail(scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

  </div>
</template>

<script>
import json from '../../components/address.json'
import axios from 'axios'
export default {
  data() {
    return {
      searchForm: {
        state: '',
        phone: '',
        createTimeEnd: '',
        createTimeStart: '',
        id: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      supplyList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      todayQuantity: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        go: '',
        arrive: '',
        name: '',
        loadtime: '',
        cartype: '',
        weight: '',
        address: '',
        volume: '',
        price: '',
        phone: '',
        explain: ''
      },
      addFormRules: {
        phone: [{ required: true, message: '请填写手机号', trigger: 'change' }],
        nickName: [
          { required: true, message: '请填写昵称', trigger: 'change' }
        ],
        type: [{ required: true, message: '请选择身份', trigger: 'change' }]
      },
      selectValue: [],
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList'
      },
      address: [],
      fullscreenLoading: false
    }
  },
  created() {
    this.getSupplyList(this.queryInfo)
    this.getSupplyNumber()
    this.address = json
  },
  methods: {
    async getSupplyList(dic) {
      const { data: res } = await this.$http.get('/goodsSource/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.supplyList = res.data
      this.total = res.total
    },
    async getSupplyNumber() {
      const { data: res } = await this.$http.get('/goodsSource/statistics', '')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.todayQuantity = res.data.todayQuantity || 0
      this.total = res.data.total || 0
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.createTimeStart = this.dateRange[0]
        this.searchForm.createTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.createTimeStart = ''
        this.searchForm.createTimeEnd = ''
      }
    },
    showEditView() {
      this.$router.push('/business/addSupply')
    },
    showSupplyDetail(id) {
      this.$router.push({
        path: '/business/supplyDetail',
        query: { id: id }
      })
    },
    editSupplyDetail(id) {
      this.$router.push({
        path: '/business/editSupplyDetail',
        query: { id: id }
      })
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getSupplyList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getSupplyList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getSupplyList(this.searchForm)
    },
    refresh() {
      this.searchForm.createTimeStart = ''
      this.searchForm.createTimeEnd = ''
      this.dateRange = []
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.$refs.searchFormRef.resetFields()
      this.getSupplyList(this.queryInfo)
    },
    deleteDeviceConfirm(id) {
      this.$confirm('确定要删除当前货源信息吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async delete(id) {
      const { data: res } = await this.$http.get('/goodsSource/delete/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功!')
      this.getSupplyList(this.queryInfo)
    },
    cardExport() {
      var that = this
      var token = window.localStorage.getItem('token')
      that.fullscreenLoading = true
      axios
        .get('/goodsSource/export', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token // hearder 很重要，Content-Type 要写对
          },
          responseType: 'arraybuffer' // 一定要写
        })
        .then((res) => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: 'application/octet-stream' // word文档为msword,pdf文档为pdf
            })
            var objectUrl = URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.href = objectUrl
            link.setAttribute('download', '货源列表.xlsx')
            setTimeout(() => {
              that.fullscreenLoading = false
            }, 2000)
            document.body.appendChild(link)
            link.click()
          } else {
          }
        })
        .catch((res) => {
          that.$message({
            type: 'error',
            message: '网络连接失败，请重新尝试',
            duration: '1000'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0px;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-cascader--mini {
  width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
