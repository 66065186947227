<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>业务中心</el-breadcrumb-item>
      <el-breadcrumb-item>货源管理</el-breadcrumb-item>
      <el-breadcrumb-item>货源详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <div class="bodyView">
        <!-- 内容主题区域 -->
        <el-row class="dialog-row">
          <el-col :span="24">
            <div class="head">货源详情</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">出发地:</span>
              <span class="item-right">{{supplyDetail.startProvince}}{{supplyDetail.startCity}}{{supplyDetail.startDistrict}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">目的地:</span>
              <span class="item-right">{{supplyDetail.destinationProvince}}{{supplyDetail.destinationCity}}{{supplyDetail.destinationDistrict}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">货物名称:</span>
              <span class="item-right">{{supplyDetail.goodsName}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">装货时间:</span>
              <span class="item-right">{{supplyDetail.loadingTime}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">车辆类型:</span>
              <span class="item-right">{{supplyDetail.vehicleType}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">货物重量(吨):</span>
              <span class="item-right">{{supplyDetail.goodsWeight}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">装货地址:</span>
              <span class="item-right">{{supplyDetail.loadingAddress}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">货物体积(立方米):</span>
              <span class="item-right">{{supplyDetail.goodsVolume}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">运费预算:</span>
              <span class="item-right">{{supplyDetail.freightBudget}}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">联系方式:</span>
              <span class="item-right">{{supplyDetail.contact}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <div class="auth-item">
              <span class="item-left">特别说明:</span>
              <span class="item-right">{{supplyDetail.remarks}}</span>
            </div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="12">
            <div class="auth-item">
              <span class="item-left">货源状态:</span>
              <span class="item-right" v-if="supplyDetail.state==0">已下架</span>
              <span class="item-right" v-else-if="supplyDetail.state==1">已发布</span>
              <span class="item-right" v-else>已结束</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supplyDetail: {}
    }
  },
  created() {
    console.log(this.$route.query.id)
    this.getSupplyDetail(this.$route.query.id)
  },
  methods: {
    goBack() {
      this.$router.back(-1)
    },
    async getSupplyDetail(id) {
      const { data: res } = await this.$http.get('/goodsSource/detail/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.supplyDetail = res.data
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 100%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.bodyView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.unit {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.2);
}
.auth-item {
  display: flex;
  align-items: center;
}
.item-left {
  width: 120px;
  font-size: 14px;
  text-align: right;
  margin-right: 30px;
}
.item-right {
  font-size: 14px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.dialog-row {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-bottom: 10px; */
}
</style>
