<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统消息</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="发送时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
        </div>
        <div class="report">
          <el-button size="small" type="danger" style="width: 100px; font-size: 14px" v-if="isAuth('/system/agreementManagement/delete')" @click="deleteMessagesConfirm">批量删除</el-button>
          <el-button size="small" type="primary" style="width: 100px; font-size: 14px" v-if="isAuth('/system/agreementManagement/add')" @click="showEditView()">添加消息</el-button>
        </div>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="messageList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" @selection-change="handleSelectionChange" stripe>
        <el-table-column type="selection" width="40">
        </el-table-column>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="消息名称" align="center" prop="title"></el-table-column>
        <el-table-column label="消息内容" align="center" prop="content"></el-table-column>
        <el-table-column label="发送人" align="center" prop="senderName"></el-table-column>
        <el-table-column label="发送时间" align="center" prop="sendTime" min-width="130px"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/system/agreementManagement/delete')" @click="deleteMessageConfirm(scope.row.id)">删除</el-button>
            <!-- <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" @click="deleteDeviceConfirm(scope.row.id)">编辑</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog title="添加消息" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="95px" size='mini' class="addfrom" label-position="top">
        <el-row class="dialog-row" style="height:100px;">
          <el-col :span="24" style="height:100%">
            <el-form-item label="消息名称:" prop="title" style="height:40px">
              <el-input class="dialog-input" v-model="addForm.title" placeholder="请输入消息标题"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row" style="height:140px;">
          <el-col :span="24" style="height:100%;width:100%;">
            <el-form-item label="消息内容:" prop="content" style="height:100px;width:95%;">
              <el-input style="width:95%;" type="textarea" :rows='4' class="dialog-input" v-model="addForm.content" placeholder="请输入消息内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="接收人:">
              <el-checkbox-group v-model="checkList" @change="boxChange">
                <el-checkbox label="0">全部用户</el-checkbox>
                <el-checkbox label="1">车主用户</el-checkbox>
                <el-checkbox label="2">物流公司</el-checkbox>
                <el-checkbox label="3">会员用户</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="24" style="height:100%">
            <el-form-item label="推送时间:">
              <el-radio-group v-model="addForm.sendType" @change="redioChange">
                <el-radio label="1">立即推送</el-radio>
                <el-radio label="2">定时推送</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" style="height:100%">
            <el-form-item label="">
              <el-date-picker style="width:100%;" v-model="addForm.sendTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" clearable :disabled="dateDisabled">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        sendTimeEnd: '',
        sendTimeStart: ''
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      messageList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        content: '',
        title: '',
        messageType: '02.app',
        receiver: '0',
        sendType: '1',
        sendTime: '',
        sender: ''
      },
      addFormRules: {
        title: [
          { required: true, message: '请输入消息标题', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请输入消息内容', trigger: 'change' }
        ],
        sendType: [
          { required: true, message: '请选择发送类型', trigger: 'change' }
        ]
      },
      checkList: ['0'],
      dateDisabled: true,
      messageIds: []
    }
  },
  created() {
    this.getMessageList(this.queryInfo)
    var userId = window.localStorage.getItem('userId')
    this.addForm.sender = userId
  },
  methods: {
    async getMessageList(dic) {
      const { data: res } = await this.$http.get('/sysMessage/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.messageList = res.data
      this.total = res.total
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.sendTimeStart = this.dateRange[0]
        this.searchForm.sendTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.sendTimeStart = ''
        this.searchForm.sendTimeEnd = ''
      }
    },
    showEditView() {
      this.addForm.sendType = '1'
      this.addForm.sendTime = ''
      this.checkList = ['0']
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    searchBtn() {
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getMessageList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getMessageList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getMessageList(this.searchForm)
    },
    boxChange(e) {
      this.checkList = e
      for (var i in e) {
        if (e[i] === '0') {
          this.addForm.receiver = '0'
          break
        } else {
          var str = e.toString()
          this.addForm.receiver = str
        }
      }
    },
    redioChange(e) {
      if (e === '2') {
        this.dateDisabled = false
      }
      this.addForm.sendType = e
    },
    getCurrentTime() {
      var yy = new Date().getFullYear()
      var mm = new Date().getMonth() + 1
      var dd = new Date().getDate()
      var hh = new Date().getHours()
      var mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      var ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      var gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
      this.addForm.sendTime = gettime
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请输入正确的数据')
          return false
        }
        console.log(this.addForm)
        if (this.addForm.sendType === '1') {
          this.getCurrentTime()
        } else {
          if (this.addForm.sendTime === '') {
            this.$message.info('请输入定时发送时间')
            return false
          }
        }
        this.addForm.sendType = this.addForm.sendType - 0
        const { data: res } = await this.$http.post(
          '/sysMessage/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.addDialogVisible = false
        this.queryInfo.pageNum = 1
        this.getMessageList(this.queryInfo)
      })
    },
    refresh() {
      this.searchForm.sendTimeStart = ''
      this.searchForm.sendTimeEnd = ''
      this.dateRange = []
      this.$refs.searchFormRef.resetFields()
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.getMessageList(this.queryInfo)
    },
    deleteMessagesConfirm() {
      if (this.feedbackIds.length === 0) {
        this.$message.error('请选择需要删除的系统信息！')
      } else {
        this.$confirm('此操作将删除这些系统信息, 是否继续?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var list = this.messageIds
            var arr = []
            list.forEach((item) => {
              arr.push(item.id)
            })
            this.deleteMessages(arr)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      }
    },
    async deleteMessages(ids) {
      var dic = {
        ids: ids
      }
      const { data: res } = await this.$http.post('/sysMessage/delete', dic)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.queryInfo.pageNum = 1
      this.getMessageList(this.queryInfo)
    },
    deleteMessageConfirm(id) {
      this.$confirm('此操作将删除这个系统消息, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.feedbackIds = []
          this.feedbackIds.push(id)
          this.deleteMessages(this.feedbackIds)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSelectionChange(e) {
      this.messageIds = e
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-radio {
  margin-bottom: 10px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  /* height: 100px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.dialog-input >>> .el-input__inner {
  width: 100%;
  height: 40px;
}
.el-form-item el-form-item--mini {
  width: 100%;
}
</style>
