<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>车主管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" :rules="searchFormRules" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="车辆类型">
              <el-cascader clearable class="elCacader" v-model="searchCartype" :props="defultProp" :options="carTypeList" @change="handleChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车牌号">
              <el-input v-model="searchForm.licenseNo" placeholder="请输入车牌号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10">
            <el-form-item label="审核时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 物流卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">车主数量: {{total}}</div>
        </div>
        <el-button size="small" type="primary" style="width: 80px; font-size: 14px" @click="showEditView(0)" v-if="isAuth('/user/ownerManagement/add')">添加车主</el-button>
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="ID" align="center" prop="id"></el-table-column>
        <el-table-column label="注册手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar==''||scope.row.avatar==null" src="../../assets/userImage9-show.png" width="30px" height="30px" style="border-radius:30px;" alt="">
            <img v-else :src="scope.row.avatar.length>10?scope.row.avatar:require('../../assets/'+scope.row.avatar+'-show.png')" alt="" style="margin-top:8px;width:30px;height:30px;border-radius:30px;">
          </template>
        </el-table-column>
        <el-table-column label="昵称" align="center" prop="nickname"></el-table-column>
        <el-table-column label="车辆类型" align="center" prop="vehicleType"></el-table-column>
        <el-table-column label="申请时间" align="center" prop="createTime" min-width="125"></el-table-column>
        <el-table-column label="车牌号" align="center" prop="licenseNo"></el-table-column>
        <el-table-column label="审核时间" align="center" prop="approveTime" min-width="125"></el-table-column>
        <el-table-column label="审核人" align="center" prop="approveUserName"></el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待审核</div>
            <div v-else-if="scope.row.state == 2" style="color:#E33434">审核中</div>
            <div v-else-if="scope.row.state == 3" style="color:#E33434">已审核</div>
            <div v-else style="color:#3BC202">已驳回</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF"  v-if="scope.row.state == 3||scope.row.state == 0&&isAuth('/user/ownerManagement/edit')" @click="editOwnerDetail(scope.row.id)">编辑</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF"  v-else disabled>编辑</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF"  v-if="scope.row.state == 3&&isAuth('/user/ownerManagement/detail')" @click="showOwnerDetail(scope.row.id)">查看</el-button>
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF"  v-else disabled>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      searchCartype: '',
      searchForm: {
        vehicleType: '',
        approveTimeEnd: '',
        approveTimeStart: '',
        phone: '',
        licenseNo: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      defultProp: {
        label: 'name',
        value: 'name',
        children: 'lengthList',
        expandTrigger: 'hover'
      },
      carTypeList: [],
      disable: true,
      disable1: true,
      disable2: true
    }
  },
  created() {
    this.getMemberList(this.queryInfo)
    this.address = json
    this.loadMainRole()
    this.carTypeList = [
      {
        name: '小面包车',
        lengthList: [
          {
            name: '2.4米'
          }
        ]
      },
      {
        name: '中面包车',
        lengthList: [
          {
            name: '3.2米'
          }
        ]
      },
      {
        name: '厢式车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '平板车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '高栏车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      }
    ]
    // this.memberList = [
    //   {
    //     id: 1,
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '货主',
    //     channel: 'app',
    //     createdTime: '2022-01-12 12:33:03',
    //     typeText: '正常',
    //     state: 1,
    //     type: true
    //   },
    //   {
    //     id: 2,
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '车主',
    //     channel: '小程序',
    //     createdTime: '2022-01-12 12:33:03',
    //     typeText: '冻结',
    //     state: 2,
    //     type: true
    //   },
    //   {
    //     id: 3,
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '物流公司',
    //     channel: 'web端',
    //     createdTime: '2022-01-12 12:33:03',
    //     typeText: '禁用',
    //     state: 3,
    //     type: false
    //   }
    // ]
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/driverIdentity/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    loadMainRole() {
      var elements = window.localStorage.getItem('elements')
      var list = JSON.parse(elements) || []
      list.forEach((item) => {
        if (item.includes('ownerManagement')) {
          if (item.includes('add')) {
            this.disable = false
          } else if (item.includes('edit')) {
            this.disable1 = false
          } else if (item.includes('detail')) {
            this.disable2 = false
          }
        }
      })
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.approveTimeStart = this.dateRange[0]
        this.searchForm.approveTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.approveTimeStart = ''
        this.searchForm.approveTimeEnd = ''
      }
    },
    showEditView(id) {
      this.$router.push({
        path: '/user/addOwner',
        query: { id: id }
      })
    },
    editOwnerDetail(id) {
      this.$router.push({
        path: '/user/editOwner',
        query: { id: id }
      })
    },
    showOwnerDetail(id) {
      this.$router.push({
        path: '/user/ownerDetail',
        query: { id: id }
      })
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      // console.log(this.searchForm)
      this.getMemberList(this.searchForm)
    },
    refresh() {
      this.searchForm.approveTimeStart = ''
      this.searchForm.approveTimeEnd = ''
      this.dateRange = []
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.$refs.searchFormRef.resetFields()
      this.getMemberList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getMemberList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getMemberList(this.searchForm)
    },
    handleChange(e) {
      if (e.length > 0) {
        this.searchForm.vehicleType = e[0] + e[1]
      } else {
        this.searchForm.vehicleType = ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.tabView {
  background-color: white;
  margin-bottom: 10px;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
