import { render, staticRenderFns } from "./NoticeExamine.vue?vue&type=template&id=15a32391&scoped=true&"
import script from "./NoticeExamine.vue?vue&type=script&lang=js&"
export * from "./NoticeExamine.vue?vue&type=script&lang=js&"
import style0 from "./NoticeExamine.vue?vue&type=style&index=0&id=15a32391&prod&lang=less&scoped=true&"
import style1 from "./NoticeExamine.vue?vue&type=style&index=1&id=15a32391&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a32391",
  null
  
)

export default component.exports