<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>业务中心</el-breadcrumb-item>
      <el-breadcrumb-item>专线管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" :rules="searchFormRules" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6" class="el-search-col">
            <el-form-item label="出发地">
              <el-cascader clearable class="elCacader" v-model="searchStartCity" :props="defultProp" :options="address" @change="handleStartChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="el-search-col">
            <el-form-item label="目的地">
              <el-cascader clearable class="elCacader" v-model="searchDestinationCity" :props="defultProp" :options="address" @change="handleDestinationChange"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.state" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="已下架" value="0"></el-option>
                <el-option label="已发布" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input class="searchInput" v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="发布时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <!-- <div class="report">
          <div class="reportText">货源数量：700</div>
          <div class="reportText">今日新增：700</div>
        </div> -->
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/business/lineManagement/report')" v-loading.fullscreen.lock="fullscreenLoading" @click="cardExport">导出</el-button>
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/business/lineManagement/add')" @click="showEditView()">添加专线</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column type="selection" width="40">
        </el-table-column>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="出发地" align="center" prop="startCity"></el-table-column>
        <el-table-column label="目的地" align="center" prop="destinationCity"></el-table-column>
        <el-table-column label="所属公司" align="center" prop="companyName"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 0">已下架</div>
            <div v-else>已发布</div>
          </template>
        </el-table-column>
        <el-table-column label="发布人账号" align="center" prop="phone" min-width="100"></el-table-column>
        <el-table-column label="发布时间" align="center" prop="createTime" min-width="140"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="150px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/business/lineManagement/delete')" @click="deleteDeviceConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" v-if="scope.row.state == 0&&isAuth('/business/lineManagement/state')" style="margin-left: 8px; color: #1890FF" @click="showLineDetail(scope.row.id,scope.row.state)">上架</el-button>
            <el-button type="text" size="mini" v-if="scope.row.state == 1&&isAuth('/business/lineManagement/state')" style="margin-left: 8px; color: #1890FF" @click="showLineDetail(scope.row.id,scope.row.state)">下架</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/business/lineManagement/edit')" @click="editLineDetail(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

  </div>
</template>

<script>
import json from '../../components/city.json'
import axios from 'axios'
export default {
  data() {
    return {
      searchDestinationCity: [],
      searchStartCity: [],
      searchForm: {
        startCity: '',
        startDistrict: '',
        startProvince: '',
        destinationCity: '',
        destinationDistrict: '',
        destinationProvince: '',
        createTimeStart: '',
        phone: '',
        state: '',
        createTimeEnd: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      // 时间选择器查询
      dateRange: [],
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList'
      },
      address: [],
      fullscreenLoading: false
    }
  },
  created() {
    this.getLineList(this.queryInfo)
    this.address = json
    // this.memberList = [
    //   {
    //     id: 1,
    //     go: '合肥市',
    //     arrive: '唐山',
    //     name: '猪肉',
    //     carType: '厢式货车4.2米',
    //     price: '900',
    //     loadTime: '2022-01-20 08:30:00',
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '货主',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 1
    //   },
    //   {
    //     id: 2,
    //     go: '合肥市',
    //     arrive: '唐山',
    //     name: '猪肉',
    //     carType: '厢式货车4.2米',
    //     price: '900',
    //     loadTime: '2022-01-20 08:30:00',
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '货主',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0
    //   },
    //   {
    //     id: 3,
    //     go: '合肥市',
    //     arrive: '唐山',
    //     name: '猪肉',
    //     carType: '厢式货车4.2米',
    //     price: '900',
    //     loadTime: '2022-01-20 08:30:00',
    //     phone: '152516676760',
    //     nickName: '老三',
    //     identity: '货主',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 1
    //   }
    // ]
  },
  methods: {
    async getLineList(dic) {
      const { data: res } = await this.$http.get('/logisticsLine/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.createTimeStart = this.dateRange[0]
        this.searchForm.createTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.createTimeStart = ''
        this.searchForm.createTimeEnd = ''
      }
    },
    showEditView() {
      this.$router.push('/business/addLine')
    },
    // 专线的上架与下架
    async showLineDetail(id, state) {
      var type = 0
      if (state === 0) {
        type = 1
      } else {
        type = 0
      }
      const { data: res } = await this.$http.get(
        '/logisticsLine/switch/' + id + '/' + type
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getLineList(this.queryInfo)
    },
    editLineDetail(row) {
      this.$router.push({
        path: '/business/editLineDetail',
        query: { id: row.id, name: row.companyName, phone: row.phone }
      })
    },
    deleteDeviceConfirm(id) {
      this.$confirm('此操作将删除该专线信息, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteLine(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async deleteLine(id) {
      const { data: res } = await this.$http.get('/logisticsLine/delete/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      var dic = JSON.parse(JSON.stringify(this.searchForm))
      dic.pageNum = this.queryInfo.pageNum
      dic.pageSize = this.queryInfo.pageSize
      this.getLineList(dic)
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getLineList(this.searchForm)
    },
    refresh() {
      this.searchForm.createTimeStart = ''
      this.searchForm.createTimeEnd = ''
      this.dateRange = []
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.$refs.searchFormRef.resetFields()
      this.getLineList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getLineList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getLineList(this.searchForm)
    },
    handleDestinationChange(e) {
      this.searchForm.destinationProvince = e[0]
      this.searchForm.destinationCity = e[1]
      // this.searchForm.destinationDistrict = e[2]
    },
    handleStartChange(e) {
      this.searchForm.startProvince = e[0]
      this.searchForm.startCity = e[1]
      // this.searchForm.startDistrict = e[2]
    },
    cardExport() {
      var that = this
      var token = window.localStorage.getItem('token')
      that.fullscreenLoading = true
      axios
        .get('/logisticsLine/export', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token // hearder 很重要，Content-Type 要写对
          },
          responseType: 'arraybuffer' // 一定要写
        })
        .then((res) => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: 'application/octet-stream' // word文档为msword,pdf文档为pdf
            })
            var objectUrl = URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.href = objectUrl
            link.setAttribute('download', '专线列表.xlsx')
            setTimeout(() => {
              that.fullscreenLoading = false
            }, 2000)
            document.body.appendChild(link)
            link.click()
          } else {
          }
        })
        .catch((res) => {
          that.$message({
            type: 'error',
            message: '网络连接失败，请重新尝试',
            duration: '1000'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-cascader--mini {
  width: 100%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
