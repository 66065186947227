<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>数据报表</el-breadcrumb-item>
      <el-breadcrumb-item>提现审核</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <el-col :span="6">
            <el-form-item label="审核状态">
              <el-select v-model="searchForm.state" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="待审核" value="1"></el-option>
                <el-option label="已通过" value="3"></el-option>
                <el-option label="已驳回" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单ID">
              <el-input v-model="searchForm.orderNo" placeholder="请输入订单ID" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="公司名称">
              <el-input v-model="searchForm.companyName" placeholder="请输入公司名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="提交时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item style="display: flex;justify-content: center;">
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">待审核:{{pending}}</div>
          <div class="reportText">已通过:{{approved}}</div>
          <div class="reportText">已驳回:{{rejected}}</div>
        </div>
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/report/withdrawalReport/report')" v-loading.fullscreen.lock="fullscreenLoading" @click="cardExport">导出</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="withdrawalList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="ID" align="center" prop="orderNo"></el-table-column>
        <el-table-column label="注册手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="会员名称" align="center" prop="name"></el-table-column>
        <el-table-column label="类型" align="center">零钱提现</el-table-column>
        <el-table-column label="提现总额" align="center" prop="amount"></el-table-column>
        <el-table-column label="支付方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.payMode == 'wx'">微信</div>
            <div v-else>支付宝</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待审核</div>
            <div v-else-if="scope.row.state == 0" style="color:#E33434">已驳回</div>
            <div v-else style="color:#3BC202">已通过</div>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center" prop="createTime" min-width="140"></el-table-column>
        <el-table-column label="审核人" align="center" prop="approveUserName"></el-table-column>
        <el-table-column label="审核时间" align="center" prop="approveTime" min-width="140"></el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="scope.row.state == 1&&isAuth('/report/withdrawalReport/examine')" @click="showEditView(scope.row.id)">驳回</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="scope.row.state == 1&&isAuth('/report/withdrawalReport/examine')" @click="confirmBtn(scope.row.id)">通过</el-button>
            <!-- <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="scope.row.state == 0" disabled>驳回</el-button> -->
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="scope.row.state == 0&&isAuth('/report/withdrawalReport/examine')" @click="confirmBtn(scope.row.id)">通过</el-button>
            <!-- <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="scope.row.state == 3" disabled>驳回</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="scope.row.state == 3" disabled>通过</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog title="审核操作" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="95px" size='mini' class="addfrom" label-position="left">
        <el-row>
          <el-col :span="24">
            <el-form-item label="驳回理由:" prop="reason">
              <el-input v-model="addForm.reason" placeholder="请输入驳回理由" clearable=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      searchForm: {
        state: '',
        createTimeEnd: '',
        createTimeStart: '',
        phone: '',
        orderNo: '',
        companyName: ''
      },
      approveId: '',
      // 时间选择器查询
      dateRange: [],
      select: '',
      withdrawalList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        reason: ''
      },
      addFormRules: {
        reason: [
          { required: true, message: '请输入驳回理由', trigger: 'change' }
        ]
      },
      fullscreenLoading: false,
      approved: 0,
      pending: 0,
      rejected: 0
    }
  },
  created() {
    this.getWithdrawalListList(this.queryInfo)
    this.getStatistics()
  },
  methods: {
    async getWithdrawalListList(dic) {
      const { data: res } = await this.$http.get('/withdrawOrder/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.withdrawalList = res.data
      this.total = res.total
    },
    async getStatistics() {
      const { data: res } = await this.$http.get(
        '/withdrawOrder/statistics',
        ''
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.approved = res.data.approved || 0
      this.pending = res.data.pending || 0
      this.rejected = res.data.rejected || 0
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.createTimeStart = this.dateRange[0]
        this.searchForm.createTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.createTimeStart = ''
        this.searchForm.createTimeEnd = ''
      }
    },
    showEditView(id) {
      this.approveId = id
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    refresh() {
      this.searchForm.createTimeStart = ''
      this.searchForm.createTimeEnd = ''
      this.searchForm.state = ''
      this.dateRange = []
      this.$refs.searchFormRef.resetFields()
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.getWithdrawalListList(this.queryInfo)
    },
    searchBtn() {
      // var dic = JSON.parse(JSON.stringify(this.searchForm))
      this.searchForm.pageNum = 1
      this.queryInfo.pageNum = 1
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getWithdrawalListList(this.searchForm)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getWithdrawalListList(this.searchForm)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.searchForm.pageNum = this.queryInfo.pageNum
      this.searchForm.pageSize = this.queryInfo.pageSize
      this.getWithdrawalListList(this.searchForm)
    },
    async confirmBtn(id) {
      var dic = {
        billType: 'withdraw',
        id: id,
        state: 3,
        sendPush: 1
      }
      const { data: res } = await this.$http.post('/withdrawOrder/approve', dic)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('审核成功')
      this.queryInfo.pageNum = 1
      this.getWithdrawalListList(this.queryInfo)
    },
    async submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写驳回理由')
          return false
        }
        var dic = {
          billType: 'withdraw',
          id: this.approveId,
          rejectReason: this.addForm.reason,
          state: 0,
          sendPush: 1
        }
        const { data: res } = await this.$http.post(
          '/withdrawOrder/approve',
          dic
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('审核成功')
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
        this.queryInfo.pageNum = 1
        this.getWithdrawalListList(this.queryInfo)
      })
    },
    cardExport() {
      var that = this
      var token = window.localStorage.getItem('token')
      that.fullscreenLoading = true
      axios
        .get('/withdrawOrder/提现订单列表导出', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token // hearder 很重要，Content-Type 要写对
          },
          responseType: 'arraybuffer' // 一定要写
        })
        .then((res) => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: 'application/octet-stream' // word文档为msword,pdf文档为pdf
            })
            var objectUrl = URL.createObjectURL(blob)
            var link = document.createElement('a')
            link.href = objectUrl
            link.setAttribute('download', '提现报表.xlsx')
            setTimeout(() => {
              that.fullscreenLoading = false
            }, 2000)
            document.body.appendChild(link)
            link.click()
          } else {
          }
        })
        .catch((res) => {
          that.$message({
            type: 'error',
            message: '网络连接失败，请重新尝试',
            duration: '1000'
          })
        })
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
