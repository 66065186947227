<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>数据报表</el-breadcrumb-item>
      <el-breadcrumb-item>会员报表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" class="searchForm" size="mini " label-width="100px">
        <el-row type="flex">
          <el-col :span="6" class="el-search-col">
            <el-form-item label="汇总时间">
              <!-- <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"  range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker> -->
              <el-date-picker v-model="searchForm.startTime" format="yyyy-MM-dd " value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="选择日期" @change="changeTime">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item style="display: flex;justify-content: center;">
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="会员级别" align="center" prop="memberName">
        </el-table-column>
        <el-table-column label="今日新增会员数" align="center" prop="todayAdd"></el-table-column>
        <el-table-column label="今日新增金额" align="center" prop="todayAmount"></el-table-column>
        <el-table-column label="会员总数" align="center" prop="totalQuantity"></el-table-column>
        <el-table-column label="总额" align="center" prop="totalAmount"></el-table-column>
        <el-table-column label="汇总时间" align="center" prop="createTime" min-width="140"></el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination> -->
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        startTime: '',
        endTime: ''
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      memberList: [],
      queryInfo: {
        report: 1
      },
      total: 0
    }
  },
  created() {
    this.getMemberList(this.queryInfo, 0)
  },
  methods: {
    async getMemberList(dic, index) {
      const { data: res } = await this.$http.get('/memberOrder/member/report', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.forEach((item) => {
        if (index === 0) {
          var year = new Date().getFullYear()
          var month = new Date().getMonth() + 1
          var day = new Date().getDate()
          var time = year + '-' + month + '-' + day
          item.createTime = time
        } else {
          item.createTime = this.searchForm.startTime.substr(0, 11)
        }
      })
      this.memberList = arr
      this.total = res.total
    },
    changeTime() {
      var time = this.searchForm.startTime
      var startTime = time
      var endTime = time
      this.searchForm.startTime = startTime
      this.searchForm.endTime = endTime
    },
    showEditView() {
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    searchBtn() {
      this.searchForm.report = this.queryInfo.report
      this.getMemberList(this.searchForm, 1)
    },
    refresh() {
      this.searchForm.startTime = ''
      this.searchForm.endTime = ''
      this.dateRange = []
      this.$refs.searchFormRef.resetFields()
      this.getMemberList(this.queryInfo, 0)
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
