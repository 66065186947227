<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/system/roleManagement/add')" @click="addDialogVisible = true">新建</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="roleList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="角色名称" align="center" prop="roleName"></el-table-column>
        <el-table-column label="创建人" align="center" prop="createName"></el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime" min-width="130px"></el-table-column>
        <el-table-column label="当前状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">正常</div>
            <div v-else style="color:#E33434">停用</div>
          </template>
        </el-table-column>
        <el-table-column label="更改状态" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" @change="changeType(scope.row)" v-if="isAuth('/system/roleManagement/state')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="text" size="mini" style="margin-left: 8px; color: #E33434" v-if="isAuth('/system/roleManagement/delete')" @click="deleteRoleConfirm(scope.row.id)">删除</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/system/roleManagement/reset')" @click="showSetRightDialog(scope.row)">分配权限</el-button>
            <el-button type="text" size="mini" style="margin-left: 8px; color: #1890FF" v-if="isAuth('/system/roleManagement/edit')" @click="showEditDialog(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 添加设备对话框 -->
    <el-dialog :title="isEdited?'修改角色':'添加角色'" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="95px" size='mini' class="addfrom" label-position="left">
        <el-form-item label="角色名称:" prop="roleName" style="height:40px">
          <el-input class="dialog-input" v-model="addForm.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色说明:" prop="description" style="height:40px">
          <el-input class="dialog-input" v-model="addForm.description" placeholder="请输入角色说明"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" v-if="isEdited === true" @click="submitForm()">保 存</el-button>
        <el-button type="primary" v-else @click="submitForm()">添 加</el-button>
      </span>
    </el-dialog>

    <!--  权限分配对话框 -->
    <el-dialog title="分配权限" :visible.sync="setRightDialogVisible" @close="setRightDialogClosed" width="50%">
      <el-tree :data="rightsList" :props="treeProps" ref="treeRef" node-key="id" show-checkbox default-expand-all :default-checked-keys="defKeys"></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setRightDialogConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roleList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      total: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        description: '',
        roleName: ''
      },
      addFormRules: {
        roleName: [
          { required: true, message: '请填写角色名称', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请填写角色说明', trigger: 'change' }
        ]
      },
      setRightDialogVisible: false,
      rightsList: [],
      treeProps: {
        label: 'name',
        children: 'children'
      },
      // 默认选中的权限数组
      defKeys: [],
      isEdited: false,
      defaultDic: {}
    }
  },
  created() {
    this.getRoleList(this.queryInfo)
    // this.memberList = [
    //   {
    //     id: 1,
    //     acount: 'root',
    //     name: 'BOSS',
    //     role: '超级管理员',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 2,
    //     acount: 'admin',
    //     name: '小三',
    //     role: '管理员',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 3,
    //     acount: 'bobo',
    //     name: '产品',
    //     role: '财务',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 0,
    //     type: true
    //   },
    //   {
    //     id: 4,
    //     acount: 'mimu',
    //     name: '测试',
    //     role: '测试',
    //     member: '大发',
    //     phone: '152516676760',
    //     createdTime: '2022-01-12 12:33:03',
    //     state: 1,
    //     type: false
    //   }
    // ]
  },
  methods: {
    async getRoleList(dic) {
      const { data: res } = await this.$http.get('/role/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      var arr = res.data
      arr.forEach((item) => {
        if (item.state === 0) {
          item.type = false
        } else {
          item.type = true
        }
      })
      this.roleList = arr
      this.total = res.total
    },
    // 冻结解封账号
    changeType(item) {
      if (item.state === 1) {
        this.$confirm('此操作将设置该角色为停用状态, 是否继续?', '停用', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.blockRole(item.id, 0)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消设置'
            })
            this.getRoleList(this.queryInfo)
          })
      } else {
        this.$confirm('此操作将设置该角色为正常状态, 是否继续?', '解禁', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.blockRole(item.id, 1)
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消设置'
            })
            this.getRoleList(this.queryInfo)
          })
      }
    },
    async blockRole(id, type) {
      const { data: res } = await this.$http.get(
        '/role/state?id=' + id + '&state=' + type
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('设置成功')
      this.getRoleList(this.queryInfo)
    },
    deleteRoleConfirm(id) {
      this.$confirm('此操作将删除该角色, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteRole(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消设置'
          })
        })
    },
    async deleteRole(id) {
      const { data: res } = await this.$http.get('/role/delete?roleId=' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getRoleList(this.queryInfo)
    },
    showEditView() {
      this.addDialogVisible = true
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    // 监听权限对话框关闭
    setRightDialogClosed() {
      this.defaultDic = {}
      this.defKeys = []
    },
    // 展示权限对话框
    async showSetRightDialog(role) {
      this.defaultDic = role
      this.getRightsList()
      var list = role.authList
      if (list === undefined || list.length <= 0) {
        const { data: res } = await this.$http.get('/role/auth/list', {
          params: { roleId: role.id }
        })
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.defKeys = []
        this.getLeafkeys(res.data, this.defKeys)
      } else {
        this.getLeafkeys(list, this.defKeys)
      }
      this.setRightDialogVisible = true
    },
    // 获取角色下所有三级权限ID，并保存到数组中
    getLeafkeys(authList, arr) {
      authList.forEach((dic) => {
        dic.children.forEach((dict) => {
          if (dict.children.length > 0) {
            dict.children.forEach((item) => {
              if (item.children || item.children.length > 0) {
                return arr.push(item.id)
              }
            })
          } else {
            return arr.push(dict.id)
          }
        })
      })
    },
    async getRightsList() {
      const { data: res } = await this.$http.get('/resource/list')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.rightsList = res.data
    },
    showEditDialog(dic) {
      this.isEdited = true
      this.addForm.roleName = dic.roleName
      this.addForm.description = dic.description
      this.addForm.id = dic.id
      this.addDialogVisible = true
    },
    submitForm() {
      if (this.isEdited) {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            this.$message.info('请填写正确的数据')
            return false
          }
          const { data: res } = await this.$http.post(
            '/role/edit',
            this.addForm
          )
          if (res.code !== 1000) return this.$message.error(res.msg)
          this.$message.success('修改成功')
          this.addDialogVisible = false
          this.isEdited = false
          this.queryInfo.pageNum = 1
          this.getRoleList(this.queryInfo)
        })
      } else {
        this.$refs.addFormRef.validate(async (valid) => {
          if (!valid) {
            this.$message.info('请填写正确的数据')
            return false
          }
          const { data: res } = await this.$http.post('/role/add', this.addForm)
          if (res.code !== 1000) return this.$message.error(res.msg)
          this.$message.success('添加成功')
          this.addDialogVisible = false
          this.queryInfo.pageNum = 1
          this.getRoleList(this.queryInfo)
        })
      }
    },
    // 确认分配权限
    async setRightDialogConfirm() {
      const keys = [
        ...this.$refs.treeRef.getCheckedKeys(),
        ...this.$refs.treeRef.getHalfCheckedKeys()
      ]
      var parms = {}
      parms.id = this.defaultDic.id
      parms.roleName = this.defaultDic.roleName
      parms.description = this.defaultDic.description
      parms.resourceIds = keys
      const { data: res } = await this.$http.post('/role/auth', parms)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('权限分配成功')
      this.setRightDialogVisible = false
      this.getRoleList(this.queryInfo)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getMemberList(this.queryInfo)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getMemberList(this.queryInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-radio {
  margin-bottom: 10px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: flex-end;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.dialog-input >>> .el-input__inner {
  width: 100%;
  height: 40px;
}
.el-form-item el-form-item--mini {
  width: 100%;
}
</style>
