<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加物流公司</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="tabView">
      <el-button size="small" :type="companyTabType" style="margin-left:20px" @click="changeTab(0)">认证资料</el-button>
      <el-button size="small" :type="memberTabType" style="margin:10px 20px" @click="changeTab(1)">物流信息</el-button>
      <el-button size="small" :type="lineTabType" style="margin:10px 0px" @click="changeTab(2)">发布专线</el-button>
      <el-button size="small" :type="supplyTabType" style="margin-left:20px" @click="changeTab(3)">发布货源</el-button>
    </div> -->
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size='mini' class="addfrom" label-position="right">
        <el-row class="dialog-row">
          <el-col :span="8">
            <div class="head">认证资料</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="选择头像:" prop="avatar">
              <el-popover placement="bottom" width="200" v-model="visible">
                <div v-for="(item,index) in imagesList" :key="index" class="imageView" @click="chooseImage(index)">
                  <img :src="item.selectUrl" v-if="item.checked" width="60" height="60" alt="">
                  <img :src="item.url" v-else width="60" height="60" alt="">
                </div>
                <img v-if="addForm.avatar===''||addForm.avatar===null" slot="reference" src="../../assets/userImage9-show.png" alt="" style="width:60px;height:60px;">
                <img v-else :src="addForm.avatar" slot="reference" alt="" style="width:60px;height:60px;">
              </el-popover>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="注册手机号:" prop="phone">
              <el-input class="dialog-input" v-model="addForm.phone" placeholder="请输入手机号" @change="phoneInput($event)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司名称:" prop="companyName">
              <el-input class="dialog-input" v-model="addForm.companyName" placeholder="请输入公司名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="所在城市:" prop="city">
              <template>
                <el-cascader clearable style="width:100%;" v-model="cityaddress" :props="defultProp" :options="address" @change="handleChange"></el-cascader>
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司地址:" prop="address">
              <el-input class="dialog-input" v-model="addForm.address" placeholder="请输入公司地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="联系人:" prop="contact">
              <el-input class="dialog-input" v-model="addForm.contact" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话:" prop="telephone">
              <el-input class="dialog-input" v-model="addForm.telephone" placeholder="请输入联系电话" @change="telephoneInput($event)"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="上传营业执照:" prop="businessLicense">
              <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleRemove" :on-success="handleSuccess" :file-list="busFileList" multiple :class="{hide:hideUpload}">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="上传法人身份证:" prop="legalPersonIdFront">
              <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdFrontRemove" :on-success="handLelegalPersonIdFrontSuccess" :file-list="frontFileList" multiple :class="{hide:hideLelegalPersonIdFrontUpload}">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdBackRemove" :on-success="handLelegalPersonIdBackSuccess" :file-list="backFileList" multiple :class="{hide:hideLelegalPersonIdBackUpload}">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row-title">
          <el-col :span="8">
            <div class="head">充值会员</div>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="会员等级:" prop="memberLevelId">
              <el-select v-model="addForm.memberName" placeholder="请选择" @change="leverChange" style="width:100%;" clearable>
                <el-option v-for="(item, index) in memberList" :key="index" :label="item.memberName" :value="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="支付方式" prop="payMode">
              <el-select v-model="addForm.payMode" placeholder="请选择" style="width:100%;" disabled>
                <el-option label="现金" value="3"></el-option>
                <el-option label="支付宝" value="1"></el-option>
                <el-option label="微信" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="支付宝账号:" prop="aliAccount">
              <el-input class="dialog-input" v-model="addForm.aliAccount" placeholder="请输入支付宝邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="微信账号:" prop="wechatAccount">
              <el-input class="dialog-input" v-model="addForm.wechatAccount" placeholder="请输入微信账号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="押金金额:" prop="deposit">
              <el-input class="dialog-input" v-model="addForm.deposit" placeholder="押金金额" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="会员金额:" prop="memberAmount">
              <el-input class="dialog-input" v-model="addForm.memberAmount" placeholder="会员金额" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="8">
            <el-form-item label="充值时间:" prop="memberPayDate">
              <el-input class="dialog-input" v-model="addForm.memberPayDate" placeholder="充值时间" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到期时间:" prop="expireDate">
              <el-input class="dialog-input" v-model="addForm.expireDate" placeholder="到期时间" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dialog-row">
          <el-col :span="17">
            <el-form-item label="备注:" prop="remark">
              <el-input class="dialog-input" v-model="addForm.remark" placeholder="请输入备注信息"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button @click="goBack">取 消</el-button>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
      </span>
    </el-card>

  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  data() {
    return {
      addDialogVisible: false,
      visible: false,
      cityaddress: [],
      uploadUrl: 'https://api.sansongkeji.com/files/upload/logo',
      // uploadUrl: 'http://mirrortest.lzs-iot.com:8806/files/upload/logo',
      // 添加设备表单数据
      addForm: {
        address: '',
        avatar: require('../../assets/userImage9.png'),
        payMode: '3',
        businessLicense: '',
        phone: '',
        city: '',
        companyName: '',
        contact: '',
        district: '',
        expireDate: '',
        legalPersonIdBack: '',
        legalPersonIdFront: '',
        memberLevelId: '2',
        memberPayDate: '',
        memberName: '',
        province: '',
        remark: '',
        telephone: '',
        aliAccount: '',
        wechatAccount: '',
        memberAmount: 0,
        deposit: 0,
        addMode: 'web'
      },
      addFormRules: {
        phone: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ],
        aliAccount: [
          { required: true, message: '请填写支付宝账号', trigger: 'change' }
        ],
        wechatAccount: [
          { required: true, message: '请填写微信账号', trigger: 'change' }
        ],
        telephone: [
          { required: true, message: '请填写联系人电话', trigger: 'change' },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ],
        address: [
          { required: true, message: '请填写公司地址', trigger: 'change' }
        ],
        businessLicense: [
          { required: true, message: '请上传营业执照', trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择所在城市', trigger: 'change' }
        ],
        companyName: [
          { required: true, message: '请填写公司名称', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '请填写联系人姓名', trigger: 'change' }
        ],
        legalPersonIdBack: [
          { required: true, message: '请上传身份证反面', trigger: 'change' }
        ],
        legalPersonIdFront: [
          { required: true, message: '请上传身份证正面', trigger: 'change' }
        ],
        memberLevelId: [
          { required: true, message: '请选择会员等级', trigger: 'change' }
        ]
      },
      imagesList: [],
      addImageForm: {
        list: [],
        url: ''
      },
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      address: [],
      headers: {},
      memberList: [],
      busFileList: [],
      backFileList: [],
      frontFileList: [],
      hideUpload: false,
      hideLelegalPersonIdBackUpload: false,
      hideLelegalPersonIdFrontUpload: false
    }
  },
  created() {
    this.headers.Authorization = window.localStorage.getItem('token')
    this.address = json
    this.imagesList = [
      {
        url: require('../../assets/userImage1.png'),
        checked: false,
        selectUrl: require('../../assets/userImage1-checked.png')
      },
      {
        url: require('../../assets/userImage2.png'),
        checked: false,
        selectUrl: require('../../assets/userImage2-checked.png')
      },
      {
        url: require('../../assets/userImage3.png'),
        checked: false,
        selectUrl: require('../../assets/userImage3-checked.png')
      },
      {
        url: require('../../assets/userImage4.png'),
        checked: false,
        selectUrl: require('../../assets/userImage4-checked.png')
      },
      {
        url: require('../../assets/userImage5.png'),
        checked: false,
        selectUrl: require('../../assets/userImage5-checked.png')
      },
      {
        url: require('../../assets/userImage6.png'),
        checked: false,
        selectUrl: require('../../assets/userImage6-checked.png')
      },
      {
        url: require('../../assets/userImage7.png'),
        checked: false,
        selectUrl: require('../../assets/userImage7-checked.png')
      },
      {
        url: require('../../assets/userImage8.png'),
        checked: false,
        selectUrl: require('../../assets/userImage8-checked.png')
      },
      {
        url: require('../../assets/userImage9.png'),
        checked: true,
        selectUrl: require('../../assets/userImage9-checked.png')
      }
    ]
    this.getMemberList()
    if (this.$route.query.id !== '0') {
      this.loadCompanyDetail(this.$route.query.id)
    }
  },
  methods: {
    goBack() {
      this.$refs.addFormRef.resetFields()
      this.$router.back(-1)
    },
    async loadCompanyDetail(id) {
      this.id = id
      const { data: res } = await this.$http.get(
        '/logisticsCompany/detail/' + id
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      var info = res.data.certificationInfo
      var arr = []
      arr.splice(0, 1, info.province)
      arr.splice(1, 1, info.city)
      arr.splice(2, 1, info.district)
      this.cityaddress = arr
      if (info.avatar.length < 11) {
        var url = require('../../assets/' + info.avatar + '.png')
        info.avatar = url
      }
      this.addForm = info
      this.addForm.payMode = JSON.stringify(info.payMode)
      var picDic = {
        url: info.businessLicense
      }
      this.busFileList.push(picDic)
      this.hideUpload = true
      var picDic1 = {
        url: info.legalPersonIdFront
      }
      this.frontFileList.push(picDic1)
      this.hideLelegalPersonIdFrontUpload = true
      var picDic2 = {
        url: info.legalPersonIdBack
      }
      this.backFileList.push(picDic2)
      this.hideLelegalPersonIdBackUpload = true
      var list = this.memberList
      list.forEach((item) => {
        if (item.id === info.memberLevelId) {
          this.addForm.deposit = parseInt(item.deposit)
          this.addForm.memberAmount = parseInt(item.memberAmount)
        }
      })
    },
    chooseImage(index) {
      var arr = this.imagesList
      var id = JSON.stringify(index)
      for (var i in arr) {
        var dic = arr[i]
        if (i === id) {
          dic.checked = !dic.checked
          this.addForm.avatar = dic.url
        } else {
          dic.checked = false
        }
      }
      this.imagesList = arr
    },
    async getMemberList() {
      var dic = {
        pageNum: 1,
        pageSize: 10
      }
      const { data: res } = await this.$http.get('/memberLevel/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
    },
    // 会员等级选择， e==1
    leverChange(e) {
      var list = this.memberList
      for (var i in list) {
        if (parseInt(i) === e) {
          this.addForm.deposit = parseFloat(list[i].deposit)
          this.addForm.memberAmount = parseFloat(list[i].memberAmount)
          this.addForm.memberLevelId = '' + list[i].id
          this.addForm.memberName = list[i].memberName
        }
      }
      this.getCurrentTime()
    },
    phoneInput(e) {
      this.checkPhone(e, 1)
    },
    telephoneInput(e) {
      this.checkPhone(e, 2)
    },
    getCurrentTime() {
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf =
        new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()
      const ss =
        new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds()
      this.addForm.memberPayDate =
        yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
      const lastyy = yy + 1
      this.addForm.expireDate =
        lastyy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    },
    handleRemove(file, fileList) {
      this.addForm.businessLicense = ''
      this.busFileList = fileList
      this.hideUpload = fileList.length >= 1
    },
    handleLelegalPersonIdFrontRemove(file, fileList) {
      this.addForm.legalPersonIdFront = ''
      this.frontFileList = fileList
      this.hideLelegalPersonIdFrontUpload = fileList.length >= 1
    },
    handleLelegalPersonIdBackRemove(file, fileList) {
      this.addForm.legalPersonIdBack = ''
      this.backFileList = fileList
      this.hideLelegalPersonIdBackUpload = fileList.length >= 1
    },
    handleSuccess(res, file, fileList) {
      this.hideUpload = fileList.length >= 1
      this.addForm.businessLicense = res.data
    },
    handLelegalPersonIdFrontSuccess(res, file, fileList) {
      this.addForm.legalPersonIdFront = res.data
      this.hideLelegalPersonIdFrontUpload = fileList.length >= 1
    },
    handLelegalPersonIdBackSuccess(res, file, fileList) {
      this.addForm.legalPersonIdBack = res.data
      this.hideLelegalPersonIdBackUpload = fileList.length >= 1
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      var testmsg = /^image\/(jpeg|png|jpg|gif|bmp)$/.test(file.type)
      const isLt1M = file.size / 1024 / 1024 < 2
      if (!testmsg) {
        this.$message.error('上传图片格式不正确！')
      }
      if (!isLt1M) {
        this.$message.warning('上传图片大小不能超过2MB!')
      }
      return isLt1M
    },
    handleExceed() {
      this.$message.error('只能上传一张图片')
    },
    handleChange(city) {
      this.addForm.city = city[1]
      this.addForm.province = city[0]
      this.addForm.district = city[2]
    },
    submitForm() {
      // console.log(this.addForm)
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的数据')
          return false
        }
        var avatar = this.addForm.avatar
        var str = avatar.substr(avatar.lastIndexOf('/') + 1, 10)
        this.addForm.avatar = str
        this.addForm.memberLevelId = parseInt(this.addForm.memberLevelId)
        this.addForm.payMode = parseInt(this.addForm.payMode)
        const { data: res } = await this.$http.post(
          '/logisticsCompany/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.$router.back(-1)
      })
    },
    async checkPhone(phone, id) {
      const { data: res } = await this.$http.get(
        '/user/check/phone/' + phone,
        ''
      )
      if (res.code !== 1000) {
        if (id === 1) {
          this.addForm.phone = ''
        } else {
          this.addForm.telephone = ''
        }
        return this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="less">
// .avatar-uploader .el-upload {
//   width: 148px;
//   height: 148px;
//   line-height: 44px;
//   margin-right: 20px;
// }
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.branchOfficeView {
  width: 400px;
  padding: 20px 20px 0 0;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.lineView {
  width: 300px;
  padding: 20px;
  border: 1px dashed #999;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.closeImg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
}
.imageView {
  float: left;
  margin-right: 6px;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 90%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.el-upload--picture-card {
  margin-right: 8px;
  width: 100% !important;
  height: 100% !important;
  line-height: 0% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="less" scoped>
.tabView {
  background-color: white;
  margin-bottom: 10px;
}

.dialog-row {
  margin-bottom: 0;
}
.dialog-row-title {
  margin-top: 20px;
  margin-bottom: 0;
}

/* .dialog-input >>> .el-input__inner {
  height: 50px;
} */
</style>

<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
