<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>审核中心</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司审核</el-breadcrumb-item>
      <el-breadcrumb-item>审核认证资料</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <div class="head">
        审核认证资料
      </div>
      <!-- 公司信息 -->
      <div style="margin-top:16px;">
        <el-row>
          <el-col :span="8">
            <div class="left">公司头像:</div>
            <img v-if="companyDetail.avatar==''||companyDetail.avatar==null" src="../../assets/userImage9-show.png" width="30px" height="30px" alt="">
            <img v-else :src="companyDetail.avatar.length>10?companyDetail.avatar:require('../../assets/'+companyDetail.avatar+'-show.png')" width="30px" height="30px" alt="">
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="left">注册手机号:</div>
            <div class="right">{{companyDetail.phone}}</div>
          </el-col>
          <el-col :span="8">
            <div class="left">所在城市:</div>
            <div class="right">{{companyDetail.city}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="left">联系电话:</div>
            <div class="right">{{companyDetail.telephone}}</div>
          </el-col>
          <el-col :span="8">
            <div class="left">联系人:</div>
            <div class="right">{{companyDetail.contact}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="left">公司名称:</div>
            <div class="right">{{companyDetail.companyName}}</div>
          </el-col>
          <el-col :span="8">
            <div class="left">公司地址:</div>
            <div class="right">{{companyDetail.address}}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="left">营业执照照片:</div>
            <el-image class="examineImage" :src="companyDetail.businessLicense" :preview-src-list="srcList">
            </el-image>
          </el-col>
          <el-col :span="8">
            <div class="left">法人身份证照片:</div>
            <el-image class="examineImage" :src="companyDetail.legalPersonIdFront" :preview-src-list="srcList">
            </el-image>
            <el-image class="examineImage" :src="companyDetail.legalPersonIdBack" :preview-src-list="srcList">
            </el-image>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="left">申请时间:</div>
            <div class="right">{{companyDetail.createTime}}</div>
          </el-col>
        </el-row>
      </div>

      <div class="body">
        <div style="width:50%;">
          <div class="head">审核信息</div>
          <el-row class="bodyRow">
            <el-col :span="24">
              <div class="leftTitle">审核状态:</div>
              <div class="rightTitle">待审核</div>
            </el-col>
          </el-row>
          <el-row class="bodyRow">
            <el-col :span="24">
              <div class="leftTitle">审核次数:</div>
              <div class="rightTitle">{{examineInfo.approveQuantity}}次</div>
            </el-col>
          </el-row>
          <el-row class="bodyRow">
            <el-col :span="24">
              <div class="leftTitle">审核时间:</div>
              <div class="rightTitle">{{examineInfo.approveTime}}</div>
            </el-col>
          </el-row>
          <el-row class="bodyRow">
            <el-col :span="24">
              <div class="leftTitle">审核人:</div>
              <div class="rightTitle">{{examineInfo.approveUserName}}</div>
            </el-col>
          </el-row>
          <el-row class="bodyRow">
            <el-col :span="24">
              <div class="leftTitle">审核结果:</div>
              <div class="rightTitle">{{examineInfo.approveQuantity===0?'':'审核不通过'}}</div>
            </el-col>
          </el-row>
        </div>
        <div style="width:50%">
          <div class="head">审核操作</div>
          <el-radio style="margin-top:20px;" v-model="radio" label="1">审核通过</el-radio>
          <el-radio style="margin-top:20px;" v-model="radio" label="2">审核不通过</el-radio>
        </div>
      </div>

      <div class="foot">
        <el-button class="btn" style="background-color:#E33434;" v-if="radio==1" disabled>驳回</el-button>
        <el-button class="btn" style="background-color:#E33434;" v-else-if="radio==''" disabled>驳回</el-button>
        <el-button class="btn" style="background-color:#E33434;" v-else @click="showExamineView()">驳回</el-button>
        <el-button class="btn" style="background-color:#6686FA;" v-if="radio==1" @click="confirmAndNext()">通过并下一条</el-button>
        <el-button class="btn" style="background-color:#6686FA;" v-else-if="radio==''" disabled>通过并下一条</el-button>
        <el-button class="btn" style="background-color:#6686FA;" v-else disabled>通过并下一条</el-button>
      </div>
    </el-card>

    <!-- 驳回理由对话框 -->
    <el-dialog title="审核操作" :visible.sync="addDialogVisible" width="35%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" label-width="70px" size='mini' class="addfrom" label-position="left">
        <el-form-item label="操作选项">
          <el-checkbox-group v-model="addForm.type" style="width:100%;">
            <el-checkbox v-for="(item,index) in reasonList" :key="index" :label="item.rejectReason" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="其他原因">
          <el-input style="width:200px;margin-right:10px;" v-model="addForm.reason" placeholder="请输入其他原因" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="addForm.isNotice">通知申请人</el-checkbox>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [],
      radio: '',
      addDialogVisible: false,
      addForm: {
        type: [],
        reason: '',
        isNotice: true
      },
      companyDetail: {},
      id: '',
      examineInfo: {},
      reasonList: []
    }
  },
  created() {
    this.loadCompanyDetail(this.$route.query.id)
    this.loadExamineInfo(this.$route.query.id)
  },
  methods: {
    async loadCompanyDetail(id) {
      this.id = id
      const { data: res } = await this.$http.get(
        '/logisticsCompany/detail/' + id
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.userInfo = res.data.userInfo
      this.companyDetail = res.data.certificationInfo
      this.srcList.push(res.data.certificationInfo.businessLicense)
      this.srcList.push(res.data.certificationInfo.legalPersonIdBack)
      this.srcList.push(res.data.certificationInfo.legalPersonIdFront)
      this.logisticsCompanyBaseInfo = res.data.logisticsCompanyBaseInfo
    },
    async loadExamineInfo(id) {
      var dic = {
        billId: id,
        billType: 'company'
      }
      const { data: res } = await this.$http.post(
        '/approveHistory/billInfo',
        dic
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.examineInfo = res.data
    },
    async getReasonList() {
      const { data: res } = await this.$http.get('/rejectReason/list')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.reasonList = res.data
    },
    showExamineView() {
      this.getReasonList()
      this.addDialogVisible = true
    },
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    goBack() {
      this.$router.back(-1)
    },
    boxChange(e) {
      this.addForm.type = e
    },
    confirmAndNext() {
      var dic = {}
      dic.id = this.id
      dic.billType = 'company'
      dic.state = 3
      dic.sendPush = 1
      this.approve(dic)
    },
    submitForm() {
      var arr = this.addForm.type
      var dic = {}
      if (arr.length === 0) {
        if (this.addForm.reason === '') {
          this.$message.error('请选择或填写驳回理由')
          return false
        } else {
          dic.rejectReason = this.addForm.reason
        }
      } else {
        dic.rejectReason = arr.toString()
        if (this.addForm.reason !== '') {
          dic.rejectReason = dic.rejectReason + ',' + this.addForm.reason
        }
      }
      dic.id = this.id
      dic.billType = 'company'
      dic.state = 0
      if (this.addForm.isNotice) {
        dic.sendPush = 1
      } else {
        dic.sendPush = 0
      }
      this.approve(dic)
    },
    async approve(dic) {
      console.log(dic)
      const { data: res } = await this.$http.post(
        '/logisticsCompany/approve',
        dic
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.$message.success('审核成功')
      this.addDialogVisible = false
      this.loadNextOwnerList()
    },
    async loadNextOwnerList() {
      var dic = {
        pageNum: 1,
        pageSize: 1,
        state: 1
      }
      const { data: res } = await this.$http.get('/logisticsCompany/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      if (res.data.length !== 0) {
        this.loadCompanyDetail(res.data[0].id)
        this.loadExamineInfo(res.data[0].id)
      } else {
        this.goBack()
      }
    }
  }
}
</script>

<style lang="less">
.head {
  font-size: 18px;
  color: #303133;
}
.el-row {
  margin-bottom: 24px;
}
.el-col {
  display: flex;
  align-items: center;
}
.left {
  width: 120px;
  text-align: right;
  font-size: 14px;
  color: #666;
  margin-right: 25px;
}
.right {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.examineImage {
  width: 42px;
  height: 24px;
  margin-right: 17px;
}
.body {
  border-top: 2px dashed rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  .bodyRow {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .leftTitle {
    width: 60px;
    text-align: left;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.23);
  }
  .rightTitle {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.23);
  }
}
.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .btn {
    color: white;
    border-radius: 30px;
  }
}
</style>
