<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>审核中心</el-breadcrumb-item>
      <el-breadcrumb-item>车主审核</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tabView">
      <el-button size="small" :type="companyTabType" style="margin-left:20px" @click="changeTab(0)">待审核</el-button>
      <el-button size="small" :type="memberTabType" style="margin:10px 20px" @click="changeTab(1)">已审核</el-button>
    </div>
    <!-- 顶部筛选区域 -->
    <div class="searchView">
      <el-form :inline="true" :model="searchForm" ref="searchFormRef" :rules="searchFormRules" class="searchForm" size="mini " label-width="70px">
        <el-row type="flex">
          <!-- <el-col :span="6">
            <el-form-item label="审核状态">
              <el-select v-model="searchForm.type" placeholder="请选择" style="width:100%;" clearable>
                <el-option label="待审核" value="0"></el-option>
                <el-option label="已通过" value="1"></el-option>
                <el-option label="已驳回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车牌号">
              <el-input v-model="searchForm.licenseNo" placeholder="请输入车牌号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="10" class="el-search-col">
            <el-form-item label="申请时间">
              <el-date-picker size="mini" unlink-panels v-model="dateRange" type="daterange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item style="display: flex;justify-content: center;">
              <el-button size="small" type="primary" class="searchBtns" @click="searchBtn">查 询</el-button>
              <el-button size="small" style="margin-left:40px" @click="refresh">刷 新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 卡片视图区 -->
    <el-card>
      <!-- 更新和添加区域 -->
      <div class="infoHeader">
        <div class="report">
          <div class="reportText">待审核：{{beTotal}}</div>
          <div class="reportText">已审核：{{allTotal}}</div>
        </div>
        <div class="report">
          <el-button size="small" type="primary" style="width: 80px; font-size: 14px" v-if="isAuth('/examine/ownerExamine/add')" @click="showEditView()">审核设置</el-button>
        </div>
        <!-- <el-button class="el-icon-refresh" size="mini" type="primary" style="width: 80px; font-size: 14px">更新</el-button> -->
      </div>
      <!-- 用户信息列表 -->
      <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="注册手机号" align="center" prop="phone"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <img v-if="scope.row.avatar==''||scope.row.avatar==null" src="../../assets/userImage9-show.png" width="40px" height="40px" style="border-radius:40px;" alt="">
            <img v-else :src="scope.row.avatar.length>10?scope.row.avatar:require('../../assets/'+scope.row.avatar+'-show.png')" alt="" style="margin-top:8px;width:40px;height:40px;border-radius:40px;">
          </template>
        </el-table-column>
        <el-table-column label="昵称" align="center" prop="nickname"></el-table-column>
        <el-table-column label="车牌号" align="center" prop="licenseNo"></el-table-column>
        <el-table-column label="申请时间" align="center" prop="createTime" min-width="140"></el-table-column>
        <el-table-column label="审核状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待审核</div>
            <div v-else-if="scope.row.state == 2" style="color:#E33434">审核中</div>
            <div v-else-if="scope.row.state == 3" style="color:#E33434">已审核</div>
            <div v-else style="color:#3BC202">已驳回</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.state==1&&isAuth('/examine/ownerExamine/examine')" size="mini" style="margin-left: 8px; color: #1890FF" @click="showExamineView(scope.row)">进入审核</el-button>
            <el-button type="text" v-else size="mini" style="margin-left: 8px; color: #eeeeee" disabled>进入审核</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>

    <!-- 审核设置对话框 -->
    <el-dialog title="审核设置" :visible.sync="addDialogVisible" width="35%" @close="addDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="110px" size='mini' class="addfrom" label-position="right">
        <el-form-item label="操作选项">
          <el-checkbox-group v-model="addForm.type" style="width:100%;">
            <el-checkbox v-for="(item,index) in reasonList" :key="index" :label="item.rejectReason" name="type" disabled></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
          <el-input v-show="!hidden" style="width:200px;margin-right:10px;" placeholder="请输入审核选项" clearable v-model="addForm.rejectReason"></el-input>
          <el-button v-show="hidden" icon="el-icon-plus" @click="addReason">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchForm: {
        licenseNo: '',
        createTimeEnd: '',
        createTimeStart: '',
        phone: ''
      },
      searchFormRules: {
        phone: {
          min: 0,
          max: 11,
          message: '手机号长度11位数字',
          trigger: 'blur'
        }
      },
      // 时间选择器查询
      dateRange: [],
      select: '',
      memberList: [],
      queryInfo: {
        // 当前页码
        pageNum: 1,
        // 当前每页显示多少条数据
        pageSize: 10
      },
      companyTabType: 'primary',
      memberTabType: '',
      tabId: 0,
      total: 0,
      beTotal: 0,
      allTotal: 0,
      // 控制添加设备对话框的显示隐藏
      addDialogVisible: false,
      // 添加设备表单数据
      addForm: {
        rejectReason: ''
      },
      addFormRules: {
        rejectReason: [
          { required: true, message: '请填写审核选项理由', trigger: 'change' }
        ]
      },
      hidden: true,
      reasonList: []
    }
  },
  created() {
    var dic = this.queryInfo
    dic.state = 1
    this.getMemberList(dic)
    this.getOwnerStatistics()
  },
  methods: {
    async getMemberList(dic) {
      const { data: res } = await this.$http.get('/driverIdentity/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      this.total = res.total
    },
    async getOwnerStatistics() {
      const { data: res } = await this.$http.get('/driverIdentity/statistics/approve', '')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.beTotal = res.data.pendingNum
      this.allTotal = res.data.approvedNum
    },
    async getReasonList() {
      const { data: res } = await this.$http.get('/rejectReason/list')
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.reasonList = res.data
    },
    changeTab(id) {
      this.tabId = id
      var dic = {}
      if (id === 0) {
        this.companyTabType = 'primary'
        this.memberTabType = ''
        this.queryInfo.pageNum = 1
        dic.pageNum = 1
        dic.pageSize = this.queryInfo.pageSize
        dic.state = 1
      } else {
        this.companyTabType = ''
        this.memberTabType = 'primary'
        dic.pageNum = 1
        this.queryInfo.pageNum = 1
        dic.pageSize = this.queryInfo.pageSize
        dic.state = -1
      }
      this.getMemberList(dic)
    },
    changeTime() {
      if (this.dateRange !== null) {
        this.searchForm.createTimeStart = this.dateRange[0]
        this.searchForm.createTimeEnd = this.dateRange[1]
      } else {
        this.searchForm.createTimeStart = ''
        this.searchForm.createTimeEnd = ''
      }
    },
    showEditView() {
      this.addDialogVisible = true
    },
    showExamineView(row) {
      console.log(row)
      this.$router.push({
        path: '/examine/ownerExamineDetail',
        query: { id: row.id, time: row.createTime }
      })
    },
    // 监听添加用户对话框的关闭
    addDialogClosed() {
      setTimeout(() => {
        this.addDialogVisible = false
        this.addForm.rejectReason = ''
        this.hidden = true
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    examineDialogClosed() {
      setTimeout(() => {
        this.examineDialogVisible = false
        this.$refs.addFormRef.resetFields()
      }, 100)
    },
    searchBtn() {
      var dic = this.searchForm
      dic.pageNum = 1
      this.queryInfo.pageNum = 1
      dic.pageSize = this.queryInfo.pageSize
      if (this.tabId === 0) {
        dic.state = 1
      } else {
        dic.state = -1
      }
      this.getMemberList(dic)
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      var dic = this.searchForm
      dic.pageNum = 1
      this.queryInfo.pageNum = newSize
      if (this.tabId === 0) {
        dic.state = 1
      } else {
        dic.state = -1
      }
      this.getMemberList(dic)
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      var dic = this.searchForm
      dic.pageNum = newPage
      dic.pageSize = this.queryInfo.pageSize
      if (this.tabId === 0) {
        dic.state = 1
      } else {
        dic.state = -1
      }
      this.getMemberList(dic)
    },
    refresh() {
      this.searchForm.createTimeStart = ''
      this.searchForm.createTimeEnd = ''
      this.searchForm.phone = ''
      this.searchForm.licenseNo = ''
      this.dateRange = []
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 10
      this.$refs.searchFormRef.resetFields()
      var dic = {}
      if (this.tabId === 0) {
        dic.pageNum = 1
        dic.pageSize = 10
        dic.state = 1
      } else {
        dic.pageNum = 1
        dic.pageSize = 10
        dic.state = -1
      }
      this.getMemberList(dic)
    },
    addReason() {
      this.hidden = false
    },
    submitForm() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请输入审核选项理由')
          return false
        }
        const { data: res } = await this.$http.post(
          '/rejectReason/save',
          this.addForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('添加成功')
        this.$refs.addFormRef.resetFields()
        this.addDialogVisible = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchView {
  width: 98%;
  background-color: #fff;
  flex-wrap: wrap;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
  padding: 10px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  .searchForm {
    width: 100% !important;
    margin-top: 5px;
    .el-row {
      height: 50px;
      margin-bottom: 0;
      .el-form-item {
        display: flex;
        flex-wrap: nowrap;
        .el-input,
        .el-select {
          max-width: 200px !important;
        }
        .primaryBtn {
          font-size: 14px;
          margin-top: -25px;
        }
      }
    }
  }
  .el-date-picker {
    padding-right: 40px;
  }
  .el-row el-row--flex {
    display: flex;
    align-items: center;
  }
  .el-search-col {
    // padding-left: 20px;
    padding-top: 2px;
  }
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.infoHeader {
  display: flex;
  justify-content: space-between;
}
.report {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.reportText {
  margin-left: 20px;
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-input >>> .el-input__inner {
  height: 50px;
}
</style>
