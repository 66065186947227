<template>
  <div class="login_container">
    <img class="logoBg" alt="" />
    <div class="login_box">
      <img src="../../assets/319@2x.png" width="75px" height="75px" alt="">
      <div class="login_text">登录</div>
      <el-form ref="loginFormRef" :model="loginForm" :rules="rules" class="login_form">
        <el-form-item prop="account">
          <el-input class="s2" v-model="loginForm.account" prefix-icon="iconfont icon-yonghuming" placeholder="请输入账号" clearable></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input class="s2" v-model="loginForm.password" prefix-icon="iconfont icon-denglu-mima" placeholder="请输入密码" type="password" clearable></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="goon" @click="login()">登 录</el-button>
        </el-form-item>
      </el-form>

      <div class="bottomView">
        <el-button type="rem" :icon="rememberIcon" @click="remember">记住密码</el-button>
        <el-button type="rem" @click="showResetView">重置密码</el-button>
      </div>
    </div>
    <!-- 重置密码对话框 -->
    <el-dialog title="重置密码" :visible.sync="addResetDialogVisible" width="30%" @close="addResetDialogClosed">
      <!-- 内容主题区域 -->
      <el-form :model="addResetForm" ref="addResetFormRef" :rules="addResetFormRules" label-width="70px" size='mini' label-position="left">
        <el-form-item label="账号:" prop="acount" style="height:40px">
          <el-input  v-model="addResetForm.acount" placeholder="请输入登录账号" disabled></el-input>
        </el-form-item>
        <el-form-item label="旧密码:" prop="oldPassword" style="height:40px">
          <el-input  v-model="addResetForm.oldPassword" placeholder="请输入旧密码" disabled></el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="newPassword" style="height:40px">
          <el-input  v-model="addResetForm.newPassword" placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetPassword()">确 定</el-button>
        <el-button @click="addResetDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rememberIcon: 'iconfont icon-xuanzekuang-duoxuanweixuan',
      remembered: false,
      // 登录表单的数据绑定对象
      loginForm: {
        account: '',
        password: ''
      },
      // 表单的验证规则对象
      rules: {
        // 验证账号是否合法
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          {
            min: 3,
            max: 11,
            message: '长度在 3 到 11 个字符',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 6, message: '密码长度在 6 个字符', trigger: 'blur' }
        ]
      },
      addResetDialogVisible: false,
      addResetForm: {
        oldPassword: '',
        acount: '',
        newPassword: ''
      },
      addResetFormRules: {
        newPassword: [
          { required: true, message: '请填写新的登录密码', trigger: 'change' },
          { min: 6, max: 6, message: '密码长度在 6 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.loadNameAndPassword()
  },
  methods: {
    remember() {
      this.remembered = !this.remembered
      if (this.remembered) {
        this.rememberIcon = 'iconfont icon-xuanzekuang-duoxuanyixuan'
      } else {
        this.rememberIcon = 'iconfont icon-xuanzekuang-duoxuanweixuan'
      }
    },
    loadNameAndPassword() {
      var password = window.localStorage.getItem('password')
      var name = window.localStorage.getItem('userName')
      if (password !== '' && password !== null) {
        this.remembered = true
        this.rememberIcon = 'iconfont icon-xuanzekuang-duoxuanyixuan'
        this.loginForm.account = name
        this.loginForm.password = password
      }
    },
    showResetView() {
      this.addResetDialogVisible = true
      this.addResetForm.acount = window.localStorage.getItem('userName')
      this.addResetForm.oldPassword = window.localStorage.getItem('password')
    },
    addResetDialogClosed() {
      setTimeout(() => {
        this.addResetDialogVisible = false
        this.$refs.addResetFormRef.resetFields()
      }, 100)
    },
    resetPassword() {
      this.$confirm('此操作将重置登录密码, 是否继续?', '重置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resetAcount()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置密码'
          })
          this.addResetDialogVisible = false
        })
    },
    async resetAcount() {
      this.$refs.addResetFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写新密码')
          return false
        }
        var dic = {
          id: window.localStorage.getItem('userId'),
          password: this.addResetForm.newPassword
        }
        const { data: res } = await this.$http.post('/user/reset', dic)
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('重置成功')
        this.addResetDialogVisible = false
        this.loginForm.password = ''
      })
    },
    login() {
      // this.$router.push('/home')
      // window.sessionStorage.setItem('activePath', '/main')
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的账号密码')
          return false
        }
        const { data: res } = await this.$http.post(
          '/user/login/web',
          this.loginForm
        )
        if (res.code !== 1000) return this.$message.error(res.msg)
        this.$message.success('登录成功')
        window.localStorage.setItem('token', res.data.token)
        window.localStorage.setItem('userId', res.data.userId)
        window.localStorage.setItem('userName', this.loginForm.account)
        console.log(this.loginForm.password)
        if (this.remembered) {
          window.localStorage.setItem('password', this.loginForm.password)
        }
        const navigatorStr = JSON.stringify(res.data.navigator)
        window.localStorage.setItem('navigator', navigatorStr)
        const elements = JSON.stringify(res.data.elements)
        window.localStorage.setItem('elements', elements)
        this.$router.push('/home')
        window.sessionStorage.setItem('activePath', '/main')
      })
    }
  }
}
</script>
<style lang="less">
.login_container {
  width: 100%;
  height: 100%;
}
.logoBg {
  background: url('~@/assets/loginBg@2x.png');
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}
.login_box {
  height: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .login_text {
    font-size: 24px;
    color: white;
    margin-top: 4px;
  }
}
.login_form {
  width: 366px;
  margin-top: 46px;
  box-sizing: border-box;
}

.icon-yonghuming {
  color: #353eff;
  padding: 0 7px;
}
.icon-denglu-mima {
  color: #353eff;
  padding: 0 7px;
}
.icon-xuanzekuang-duoxuanweixuan {
  color: #8f8f8f;
  margin-right: 20px;
}
.icon-xuanzekuang-duoxuanyixuan {
  color: #8f8f8f;
  margin-right: 20px;
}
.btns {
  margin-top: 43px;
  .el-button--goon {
    width: 366px;
    height: 47px;
    font-size: 24px;
    color: #ffffff;
    background: url('~@/assets/2639.png') no-repeat;
    background-color: rgba(0, 0, 0, 0) !important;
    border-style: none;
  }
}
.bottomView {
  width: 366px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-button--rem {
    font-size: 17px;
    color: #eeeeee;
    background-color: rgba(0, 0, 0, 0) !important;
    border-style: none;
  }
  .el-button--rem:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}
.el-input__prefix {
  left: 10px;
  transition: all 0.3s;
}
.el-input--prefix .el-input__inner {
  padding-left: 40px !important;
}
</style>

<style scoped>
.s2 {
  width: 366px;
  height: 47px;
}
.s2 >>> .el-input__inner {
  border-radius: 47px;
}
</style>
