<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司管理</el-breadcrumb-item>
      <el-breadcrumb-item>物流公司详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tabView">
      <el-button size="small" :type="memberTabType" style="margin-left:20px" @click="changeTab(0)">会员信息</el-button>
      <el-button size="small" :type="companyTabType" style="margin-left:20px" @click="changeTab(1)">认证资料</el-button>
      <el-button size="small" :type="logisticsTabType" style="margin:10px 20px" @click="changeTab(2)">物流信息</el-button>
      <el-button size="small" :type="lineTabType" style="margin:10px 0px" @click="changeTab(3)">发布专线</el-button>
      <el-button size="small" :type="supplyTabType" style="margin-left:20px" @click="changeTab(4)">发布货源</el-button>
    </div>
    <!-- 物流卡片视图区 -->
    <el-card :hidden="tabId===0?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="head">基本信息</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">头像:</span>
            <img v-if="companyDetail.avatar==''||companyDetail.avatar==null" src="../../assets/userImage9-show.png" width="30px" height="30px" alt="">
            <img v-else :src="companyDetail.avatar.length>10?companyDetail.avatar:require('../../assets/'+companyDetail.avatar+'-show.png')" alt="" style="width:40px;height:40px;">
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">公司名称:</span>
            <span class="item-right">{{userInfo.companyName}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">ID:</span>
            <span class="item-right">{{userInfo.id}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">注册手机号:</span>
            <span class="item-right">{{userInfo.phone}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">会员级别:</span>
            <span class="item-right">{{userInfo.memberName}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row" style="margin-bottom:20px">
        <el-col :span="8">
          <div class="item">
            <span class="item-left">会员标志:</span>
            <img v-if="userInfo.memberLevelId===3" src="../../assets/113@2x.png" alt="" style="width:30px;height:22px;">
            <img v-else-if="userInfo.memberLevelId===2" src="../../assets/silver.png" alt="" style="width:30px;height:22px;">
            <span v-else></span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8" style="display:flex;align-items: center;">
          <div class="head">会员卡</div>
        </el-col>
      </el-row>
      <el-row>
        <el-table :data="memberList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%;margin-top:0;" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
          <el-table-column label="ID" align="center" prop="id"></el-table-column>
          <el-table-column label="会员卡级别" align="center" prop="memberName"></el-table-column>
          <el-table-column label="金额" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.memberAmount!==0&&scope.row.memberAmount!==null">{{scope.row.memberAmount}}</div>
              <div v-else-if="scope.row.deposit!==0&&scope.row.deposit!==null">{{scope.row.deposit}}</div>
            </template>
          </el-table-column>
          <el-table-column label="支付方式" align="center" prop="payMode">
            <template slot-scope="scope">
              <div v-if="scope.row.payMode==='wx'">微信支付</div>
              <div v-else-if="scope.row.payMode==='ali'">支付宝支付</div>
              <div v-else-if="scope.row.payMode==='balance'">零钱支付</div>
              <div v-else>现金支付</div>
            </template>
          </el-table-column>
          <el-table-column label="充值时间" align="center" prop="createTime"></el-table-column>
          <el-table-column label="到期时间" align="center" prop="expireDate"></el-table-column>
          <el-table-column label="状态" align="center" prop="state">
            <template slot-scope="scope">
              <div v-if="scope.row.state===0">已到期</div>
              <div v-if="scope.row.state===3">正常</div>
              <div v-if="scope.row.state===5">已退卡</div>
              <div v-if="scope.row.state===1">未支付</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="12" style="display:flex;align-items: center;">
          <div class="head">交易明细</div>
        </el-col>
        <el-col :span="12" style="display:flex;align-items: center;justify-content: flex-end;">
          <div class="reportText">零钱余额：{{balance===null?0:balance}}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-table :data="orderList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%;margin-top:0;" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
          <el-table-column label="订单号" align="center" prop="orderNo"></el-table-column>
          <el-table-column label="交易类型" align="center" prop="orderType">
            <template slot-scope="scope">
              <div v-if="scope.row.orderType==='DE'">支付押金</div>
              <div v-else-if="scope.row.orderType==='CZ'">开通会员</div>
              <div v-else-if="scope.row.orderType==='BL'">零钱充值</div>
              <div v-else>押金退款到零钱</div>
            </template>
          </el-table-column>
          <el-table-column label="金额" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.deposit!==null&&scope.row.deposit!==0">{{scope.row.deposit}}</div>
              <div v-else-if="scope.row.chargeAmount!==null&&scope.row.chargeAmount!==0">{{scope.row.chargeAmount}}</div>
              <div v-else>{{scope.row.memberAmount}}</div>
            </template>
          </el-table-column>
          <el-table-column label="支付方式" align="center" prop="payMode">
            <template slot-scope="scope">
              <div v-if="scope.row.payMode==='wx'">微信支付</div>
              <div v-else-if="scope.row.payMode==='ali'">支付宝支付</div>
              <div v-else-if="scope.row.payMode==='balance'">零钱支付</div>
              <div v-else-if="scope.row.payMode==='cash'">现金支付</div>
              <div v-else>押金退款到零钱</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="零钱余额" align="center" prop="chargeAmount"></el-table-column> -->
          <el-table-column label="交易时间" align="center" prop="createTime"></el-table-column>
        </el-table>
      </el-row>
    </el-card>
    <!-- 认证资料 -->
    <el-card :hidden="tabId===1?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="head">认证资料</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">头像:</span>
            <img v-if="companyDetail.avatar==''||companyDetail.avatar==null" src="../../assets/userImage9-show.png" width="30px" height="30px" alt="">
            <img v-else :src="companyDetail.avatar.length>10?companyDetail.avatar:require('../../assets/'+companyDetail.avatar+'-show.png')" alt="" style="width:60px;height:60px;">
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">注册手机号:</span>
            <span class="item-right">{{companyDetail.phone}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">公司名称:</span>
            <span class="item-right">{{companyDetail.companyName}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">所在城市:</span>
            <span class="item-right">{{companyDetail.city}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">公司地址:</span>
            <span class="item-right">{{companyDetail.address}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">联系人:</span>
            <span class="item-right">{{companyDetail.contact}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">联系电话:</span>
            <span class="item-right">{{companyDetail.telephone}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">营业执照:</span>
            <!-- <img :src="companyDetail.businessLicense" :preview-src-list="srcList" width="80px" height="40px" alt=""> -->
            <el-image style="width:80px;height:40px" :src="companyDetail.businessLicense" :preview-src-list="srcList">
            </el-image>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">法人身份证照片:</span>
            <!-- <img :src="companyDetail.legalPersonIdFront" :preview-src-list="srcList" width="80px" height="40px" alt="">
            <img :src="companyDetail.legalPersonIdBack" :preview-src-list="srcList" width="80px" height="40px" style="margin-left:20px;" alt=""> -->
            <el-image style="width:80px;height:40px" :src="companyDetail.legalPersonIdFront" :preview-src-list="srcList">
            </el-image>
            <el-image style="width:80px;height:40px;margin-left:20px;" :src="companyDetail.legalPersonIdBack" :preview-src-list="srcList">
            </el-image>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row-title">
        <el-col :span="8">
          <div class="head">充值会员</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">会员等级:</span>
            <span class="item-right">{{userInfo.memberName}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">支付方式:</span>
            <span class="item-right" v-if="companyDetail.payMode==='ali'">支付宝</span>
            <span class="item-right" v-else-if="companyDetail.payMode==='wx'">微信</span>
            <span class="item-right" v-else-if="companyDetail.payMode==='balance'">零钱</span>
            <span class="item-right" v-else-if="companyDetail.payMode==='cash'||companyDetail.payMode===3">现金</span>
            <span class="item-right" v-else></span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">押金金额:</span>
            <span class="item-right">{{companyDetail.deposit}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">会员金额:</span>
            <span class="item-right">{{companyDetail.memberAmount}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">支付宝账号:</span>
            <span class="item-right">{{companyDetail.aliAccount}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">微信账号:</span>
            <span class="item-right">{{companyDetail.wechatAccount}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">充值时间:</span>
            <span class="item-right">{{companyDetail.createTime}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">到期时间:</span>
            <span class="item-right">{{companyDetail.expireDate}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="17">
          <div class="auth-item">
            <span class="item-left">备注:</span>
            <span class="item-right">{{companyDetail.remark}}</span>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 物流信息 -->
    <el-card :hidden="tabId===2?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="head">基本资料</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">公司名称:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.companyName}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">业务经理:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.manager}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">手机号码:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.managerPhone}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">公司座机:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.telephone}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">查货电话:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.hotline}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="auth-item">
            <span class="item-left">询价电话:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.enquiryPhone}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="17">
          <div class="auth-item">
            <span class="item-left">公司地址:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.address}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="17">
          <div class="auth-item">
            <span class="item-left">公告:</span>
            <span class="item-right">{{logisticsCompanyBaseInfo.announcement}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="17">
          <div class="auth-item">
            <span class="item-left">公司二维码:</span>
            <vue-qr :logoSrc="imageUrl" :text="QcodeTxet" :size="200"></vue-qr>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row-title">
        <el-col :span="8" style="display:flex;align-items: center;justify-content: space-between;">
          <div class="head" style="margin-bottom: 10px;">分公司资料</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <template>
          <div class="branchOfficeView" v-for="(item,index) in logisticsCompanyBaseInfo.branchCompanyList" :key="index">
            <div class="auth-item">
              <span class="item-left">公司名称:</span>
              <span class="item-right">{{item.companyName}}</span>
            </div>
            <div class="auth-item">
              <span class="item-left">公司座机:</span>
              <span class="item-right">{{item.telephone}}</span>
            </div>
            <div class="auth-item">
              <span class="item-left">公司地址:</span>
              <span class="item-right">{{item.address}}</span>
            </div>
          </div>
        </template>
      </el-row>
      <el-row class="dialog-row-title">
        <el-col :span="8">
          <div class="head" style="margin-bottom: 10px;">公司图片</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">办公场景:</span>
            <div class="imageItem" v-for="(item,index) in logisticsCompanyBaseInfo.officePictureList" :key="index">
              <!-- <img :src="item.picUrl" width="146px" height="146px" alt=""> -->
              <el-image style="width:146px;height:146px" :src="item.picUrl" :preview-src-list="officeList">
            </el-image>
              <img class="detail-Image" v-if="item.state==0" src="../../assets/592@2x.png" alt="">
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">员工风采:</span>
            <div class="imageItem" v-for="(item,index) in logisticsCompanyBaseInfo.staffPictureList" :key="index">
              <!-- <img :src="item.picUrl" width="146px" height="146px" alt=""> -->
              <el-image style="width:146px;height:146px" :src="item.picUrl" :preview-src-list="staffList">
            </el-image>
              <img class="detail-Image" v-if="item.state==0" src="../../assets/592@2x.png" alt="">
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">运力展示:</span>
            <div class="imageItem" v-for="(item,index) in logisticsCompanyBaseInfo.abilityPictureList" :key="index">
              <!-- <img :src="item.picUrl" width="146px" height="146px" alt=""> -->
              <el-image style="width:146px;height:146px" :src="item.picUrl" :preview-src-list="abilityList">
            </el-image>
              <img class="detail-Image" v-if="item.state==0" src="../../assets/592@2x.png" alt="">
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col :span="24">
          <div class="auth-item">
            <span class="item-left">资质证书:</span>
            <div class="imageItem" v-for="(item,index) in logisticsCompanyBaseInfo.certificationPictureList" :key="index">
              <!-- <img :src="item.picUrl" width="146px" height="146px" alt=""> -->
              <el-image style="width:146px;height:146px" :src="item.picUrl" :preview-src-list="certificationList">
            </el-image>
              <img class="detail-Image" v-if="item.state==0" src="../../assets/592@2x.png" alt="">
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 专线信息 -->
    <el-card :hidden="tabId===3?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-row class="dialog-row-title">
        <el-col :span="24" style="display:flex;align-items: center;justify-content: space-between;">
          <div class="head" style="margin-bottom: 10px;">专线信息</div>
        </el-col>
      </el-row>
      <el-row class="dialog-row" v-for="(item,index) in lineList" :key="index">
        <el-col :span="12">
          <div style="display: flex;align-items: center;justify-content: space-around;padding-bottom:20px;">
            <div class="lineView">
              <div class="lineItem">{{item.startCity}}</div>
              <img src="../../assets/arrow-forward@2x.png" width="16px" height="16px" alt="">
              <div class="lineItem">{{item.destinationCity}}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 货源信息 -->
    <el-card :hidden="tabId===4?false:true">
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <el-table :data="goodsList" element-loading-background="rgba(0, 0, 0, 0.5)" style="width: 100%" max-height="580px" ref="tableInfo" :row-style="{ height: '50px' }" :cell-style="{ padding: '0px' }" :header-cell-style="{ color: '#8c8f8f', background: '#f6f9fb' }" stripe>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="ID" align="center" prop="id"></el-table-column>
        <el-table-column label="出发地" align="center" prop="startCity"></el-table-column>
        <el-table-column label="目的地" align="center" prop="destinationCity"></el-table-column>
        <el-table-column label="货物名称" align="center" prop="goodsName"></el-table-column>
        <el-table-column label="车辆类型" align="center" prop="vehicleType"></el-table-column>
        <el-table-column label="运费预算" align="center" prop="freightBudget"></el-table-column>
        <el-table-column label="装货时间" align="center" prop="loadingTime" min-width="125"></el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">已发布</div>
            <div v-else-if="scope.row.state == 2">已结束</div>
            <div v-else>已下架</div>
          </template>
        </el-table-column>
        <el-table-column label="发布时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="备注" align="center" prop="remarks" min-width="125"></el-table-column>
        <!-- <el-table-column label="操作" fixed="right" align="center" width="100px">
          <template slot-scope="scope">
            <el-button type="text" size="medium" style="margin-left: 8px; color: #1890FF" @click="showEditView(scope.row.id)">查看</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>

  </div>
</template>

<script>
import json from '../../components/address.json'
import vueQr from 'vue-qr'
export default {
  name: 'qecode',
  data() {
    return {
      srcList: [],
      officeList: [],
      staffList: [],
      abilityList: [],
      certificationList: [],
      companyTabType: '',
      memberTabType: 'primary',
      supplyTabType: '',
      lineTabType: '',
      logisticsTabType: '',
      tabId: 0,
      orderTotal: 0,
      carTypeList: [],
      companyDetail: {},
      memberList: [],
      orderList: [],
      lineList: [],
      goodsList: [],
      userInfo: {},
      logisticsCompanyBaseInfo: {},
      radio: '1',
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList',
        expandTrigger: 'hover'
      },
      address: [],
      flieList: [],
      uploadDisabled: false,
      show: false,
      addDialogVisible: false,
      imageUrl: '',
      QcodeTxet: '1234',
      balance: 0
    }
  },
  components: {
    vueQr
  },
  created() {
    this.address = json
    this.carTypeList = [{}]
    this.balance = this.$route.query.balance
    this.loadCompanyDetail(this.$route.query.id)
  },
  methods: {
    goBack() {
      this.$router.back(-1)
    },
    async loadCompanyDetail(id) {
      const { data: res } = await this.$http.get(
        '/logisticsCompany/detail/' + id
      )
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.userInfo = res.data.userInfo
      this.companyDetail = res.data.certificationInfo
      this.logisticsCompanyBaseInfo = res.data.logisticsCompanyBaseInfo
      if (res.data.certificationInfo.avatar !== null) {
        this.imageUrl = require('../../assets/' +
          res.data.certificationInfo.avatar +
          '-show.png')
      }
      this.srcList.push(res.data.certificationInfo.businessLicense)
      this.srcList.push(res.data.certificationInfo.legalPersonIdBack)
      this.srcList.push(res.data.certificationInfo.legalPersonIdFront)
      this.logisticsCompanyBaseInfo.officePictureList.forEach((item) => {
        this.officeList.push(item.picUrl)
      })
      this.logisticsCompanyBaseInfo.staffPictureList.forEach((item) => {
        this.staffList.push(item.picUrl)
      })
      this.logisticsCompanyBaseInfo.abilityPictureList.forEach((item) => {
        this.abilityList.push(item.picUrl)
      })
      this.logisticsCompanyBaseInfo.certificationPictureList.forEach((item) => {
        this.certificationList.push(item.picUrl)
      })
      this.getMemberLevelList()
      this.getLineList()
      this.getSupplyList()
      this.getMemberCardList()
      this.getMemberOrderList()
    },
    async getMemberLevelList() {
      var dic = {
        pageNum: 1,
        pageSize: 10
      }
      const { data: res } = await this.$http.get('/memberLevel/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      res.data.forEach((item) => {
        if (item.id === this.userInfo.memberLevelId) {
          this.companyDetail.deposit = item.deposit
          this.companyDetail.memberAmount = item.memberAmount
        }
      })
    },
    async getLineList() {
      const { data: res } = await this.$http.get('/logisticsLine/list', {
        params: {
          pageNum: 1,
          pageSize: 10,
          phone: this.userInfo.phone
        }
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.lineList = res.data
    },
    async getSupplyList() {
      var phone = this.userInfo.phone
      const { data: res } = await this.$http.get('/goodsSource/list', {
        params: {
          pageNum: 1,
          pageSize: 10,
          phone: phone
        }
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.goodsList = res.data
    },
    changeTab(id) {
      this.tabId = id
      console.log(this.tabId)
      if (id === 0) {
        this.companyTabType = ''
        this.memberTabType = 'primary'
        this.supplyTabType = ''
        this.lineTabType = ''
        this.logisticsTabType = ''
      } else if (id === 1) {
        this.companyTabType = 'primary'
        this.memberTabType = ''
        this.supplyTabType = ''
        this.lineTabType = ''
        this.logisticsTabType = ''
      } else if (id === 2) {
        this.companyTabType = ''
        this.memberTabType = ''
        this.supplyTabType = ''
        this.lineTabType = ''
        this.logisticsTabType = 'primary'
      } else if (id === 3) {
        this.companyTabType = ''
        this.memberTabType = ''
        this.supplyTabType = ''
        this.lineTabType = 'primary'
        this.logisticsTabType = ''
      } else {
        this.companyTabType = ''
        this.memberTabType = ''
        this.supplyTabType = 'primary'
        this.lineTabType = ''
        this.logisticsTabType = ''
      }
    },
    async getMemberCardList() {
      var id = this.userInfo.id
      var dic = {
        pageNum: 1,
        pageSize: 10,
        userId: id,
        report: 0
      }
      const { data: res } = await this.$http.get('/memberOrder/card/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberList = res.data
      if (this.companyDetail.payMode === null) {
        var arr = res.data
        this.companyDetail.payMode = arr[0].payMode
        this.companyDetail.expireDate = arr[0].expireDate
      }
    },
    async getMemberOrderList() {
      var id = this.userInfo.id
      var dic = {
        pageNum: 1,
        pageSize: 10,
        userId: id,
        state: 3
      }
      const { data: res } = await this.$http.get('/memberOrder/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.orderList = res.data
    }
  }
}
</script>

<style lang="less">
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.branchOfficeView {
  width: 400px;
  padding: 20px 20px 0 0;
  border: 1px solid #999;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.lineView {
  width: 250px;
  padding: 20px;
  border: 1px solid #999;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.lineItem {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #999;
}
.closeImg {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 90%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  //   margin-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.imageItem {
  position: relative;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
</style>

<style lang="less" scoped>
.tabView {
  background-color: white;
  margin-bottom: 10px;
}

.dialog-row {
  margin-bottom: 10px;
}
.item {
  margin-left: 40px;
  display: flex;
  align-items: center;
}
.auth-item {
  margin-left: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.item-left {
  width: 75px;
  font-size: 14px;
  text-align: left;
  margin-right: 20px;
}
.item-right {
  font-size: 14px;
}
.reportText {
  color: #666666;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 5px 20px;
}
.dialog-row-title {
  margin-top: 20px;
  margin-bottom: 0;
}
.detail-Image {
  position: absolute;
  width: 146px;
  height: 50px;
  top: 50px;
  left: 0;
}
/* .dialog-input >>> .el-input__inner {
  height: 50px;
} */
</style>
