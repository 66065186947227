<template>
  <div class="container">
    <div class="infoHeader">
      <el-cascader clearable class="elCacader" v-model="searchStartCity" :props="defultProp" :options="address" @change="handleStartChange"></el-cascader>
      <el-button size="small" type="primary" style="width: 80px; font-size: 14px;margin-right:20px;" @click="refersh">刷新</el-button>
    </div>
    <div class="main">
      <div class="topView">
        <div class="top-left">
          <div class="title">收支款项统计</div>
          <div class="revenueView">
            <div class="revenueItem">
              <div class="itemTitle" style="font-size:14px;">总累计收款（元）</div>
              <div class="itemTitle">¥{{!isAuth('/home/tab1')?'******': inAndOutData.totalIncome==null? 0:inAndOutData.totalIncome}}</div>
            </div>
            <div class="revenueItem">
              <div class="itemTitle" style="font-size:14px;">年累计收款（元）</div>
              <div class="itemTitle">¥{{!isAuth('/home/tab1')?'******': inAndOutData.yearlyTotalIncome==null? 0:inAndOutData.yearlyTotalIncome}}</div>
            </div>
            <div class="revenueItem">
              <div class="itemTitle" style="font-size:14px;">零钱余额（元）</div>
              <div class="itemTitle">¥{{!isAuth('/home/tab1')?'******': inAndOutData.totalBalance==null? 0:inAndOutData.totalBalance}}</div>
            </div>
            <div class="revenueItem">
              <div class="itemTitle" style="font-size:14px;">提现总金额（元）</div>
              <div class="itemTitle">¥{{!isAuth('/home/tab1')?'******': inAndOutData.totalWithdraw==null? 0:inAndOutData.totalWithdraw}}</div>
            </div>
          </div>
          <div>
            <div class="itemView">
              <div class="item-left" style="background-color:#6686FA;"></div>
              <div class="itemOtherTitle">今日新增押金金额（元）</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.todayDeposit==null? 0:inAndOutData.todayDeposit}}</div>
            </div>
            <div class="itemView">
              <div class="item-left" style="background-color:#6686FA;"></div>
              <div class="itemOtherTitle">当月累计充值零钱（元）</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.currentMonthBalanceTotal==null? 0:inAndOutData.currentMonthBalanceTotal}}</div>
            </div>
            <div class="itemView">
              <div class="item-left" style="background-color:#F59A23;"></div>
              <div class="itemOtherTitle">今日新增金卡总额（元）</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.todayGoldenTotal==null? 0:inAndOutData.todayGoldenTotal}}</div>
            </div>
            <div class="itemView">
              <div class="item-left" style="background-color:#F59A23;"></div>
              <div class="itemOtherTitle">当月累计提现零钱（元）</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.currentMonthWithdrawTotal==null? 0:inAndOutData.currentMonthWithdrawTotal}}</div>
            </div>
            <div class="itemView">
              <div class="item-left" style="background-color:#6686FA;"></div>
              <div class="itemOtherTitle">今日新增银卡总额（元）</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.todaySilverTotal==null? 0:inAndOutData.todaySilverTotal}}</div>
            </div>
            <div class="itemView">
              <div class="item-left" style="background-color:#6686FA;"></div>
              <div class="itemOtherTitle">年累积提现零钱(元)</div>
              <div class="itemNumber">{{!isAuth('/home/tab1')?'******': inAndOutData.yearlyWithdrawTotal==null? 0:inAndOutData.yearlyWithdrawTotal}}</div>
            </div>
          </div>
        </div>
        <div class="top-left" style="margin-right:0;">
          <div class="title">2022全年营收趋势</div>
          <div style="width:98%;display:flex;align-items: center;justify-content: flex-end;margin-right:20px;">
            <div class="unit"></div>
            <div style="font-size:10px">当月营收情况</div>
          </div>
          <div style="width:100%;height:96%;">
            <div id="myChart" style="width:100%;height:100%;"></div>
          </div>
        </div>
      </div>
      <div class="topView">
        <div class="bottom-left">
          <div class="bottom-left-top">
            <div class="title">用户类型统计</div>
            <div class="revenueView">
              <div class="item">
                <div class="memTitle">今日新增用户</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******': userTypeStatistics.todayNewUser}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">今日新增物流</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.todayNewLogistics}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">今日新增车主</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.todayNewDriver}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">今日退还押金</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.todayDepositRefundNum}}元</div>
              </div>
              <div class="item">
                <div class="memTitle">本月退还押金</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.monthlyDepositRefundNum}}元</div>
              </div>
              <div class="item">
                <div class="memTitle">退押金总人数</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.totalDepositRefundNum}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">即将到期会员</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.nearlyExpireMemberNum}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">过期金卡会员</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.expiredGoldenNum}}人</div>
              </div>
              <div class="item">
                <div class="memTitle">过期银卡会员</div>
                <div class="memTitle" style="font-size: 14px;">{{!isAuth('/home/tab3')?'******':userTypeStatistics.expiredSilverNum}}人</div>
              </div>
            </div>
          </div>
          <div class="bottom-left-bottom">
            <div class="title">统计占比图</div>
            <div class="chartView">
              <div style="width:30%;height:96%;">
                <div id="myChart1" style="width:100%;height:100%;"></div>
              </div>
              <div style="width:30%;height:96%;">
                <div id="myChart2" style="width:100%;height:100%;"></div>
              </div>
              <div style="width:30%;height:96%;">
                <div id="myChart3" style="width:100%;height:100%;"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="title" style="margin-left:0;">审核统计</div>
          <div class="examineView">
            <span class="examineType">待审核</span>
            <span class="examineNumber">{{!isAuth('/home/tab5')?'****':approveData.pendingNum}}条</span>
          </div>
          <div class="itemViewOther">
            <div class="item-left" style="background-color:#6686FA;"></div>
            <div class="itemOtherTitle">已审核数量</div>
            <div class="itemNumber">{{!isAuth('/home/tab5')?'****':approveData.approvedNum}}条</div>
          </div>
          <div class="itemViewOther">
            <div class="item-left" style="background-color:#F59A23;"></div>
            <div class="itemOtherTitle">今日新增待审核数量</div>
            <div class="itemNumber">{{!isAuth('/home/tab5')?'****':approveData.todayPendingNum}}条</div>
          </div>
          <div class="itemViewOther">
            <div class="item-left" style="background-color:#6686FA;"></div>
            <div class="itemOtherTitle">今日已审核数量</div>
            <div class="itemNumber">{{!isAuth('/home/tab5')?'****':approveData.todayApprovedNum}}条</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../../components/address.json'
export default {
  name: 'test2',
  data() {
    return {
      myChart: '',
      myChart1: '',
      myChart2: '',
      myChart3: '',
      searchStartCity: [],
      defultProp: {
        label: 'Name',
        value: 'Name',
        children: 'ChildList'
      },
      address: [],
      approveData: {}, // 审核统计
      inAndOutData: {}, // 收支款项
      ratioData: {}, // 统计占比图
      userTypeStatistics: {}, // 用户类型
      yearlyRevenueTrendList: [], // 年度营收趋势
      memberNum: 0,
      userNum: 0,
      quantity: 0,
      goldNum: 0,
      silveNum: 0,
      defaultNum: 0,
      comNum: 0,
      driNum: 0,
      shipNum: 0,
      deNum: 0,
      czNum: 0,
      blNum: 0,
      monthList: [],
      xData: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月'
      ],
      dic1: {},
      dic2: {},
      dic3: {},
      dic4: {},
      dic5: {},
      dic6: {},
      dic7: {},
      dic8: {},
      dic9: {},
      dic10: {},
      dic11: {},
      dic12: {},
      queryInfo: {
        province: '',
        city: ''
      }
    }
  },
  created() {
    this.getMainData()
    var dic = {
      pageNum: 1,
      pageSize: 100000,
      isMember: 1
    }
    this.getMemberNumber(dic)
    this.address = json
  },
  mounted: function () {},
  methods: {
    async getMainData(dic) {
      const { data: res } = await this.$http.get('/homePage/overview', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.approveData = res.data.approveData
      this.inAndOutData = res.data.inAndOutData
      this.ratioData = res.data.ratioData
      this.userTypeStatistics = res.data.userTypeStatistics
      this.yearlyRevenueTrendList = res.data.yearlyRevenueTrendList
      var arr = res.data.ratioData.memberOrderRatioData
      var number = 0
      arr.forEach((item) => {
        number = number + item.quantity
        if (item.groupKey === 'DE') {
          this.deNum = item.quantity
        } else if (item.groupKey === 'CZ') {
          this.czNum = item.quantity
        } else {
          this.blNum = item.quantity
        }
      })
      this.quantity = number.toFixed(2)
      // var arr1 = res.data.ratioData.memberRatioData
      // var number1 = 0
      // arr1.forEach((item) => {
      //   number1 = number1 + item.quantity
      //   if (item.groupKey === '普通会员') {
      //     this.defaultNum = item.quantity
      //   } else if (item.groupKey === '银卡会员') {
      //     this.silveNum = item.quantity
      //   } else {
      //     this.goldNum = item.quantity
      //   }
      // })
      // this.memberNum = number1
      var arr2 = res.data.ratioData.userTypeRatioData
      var number2 = 0
      arr2.forEach((item) => {
        number2 = number2 + item.quantity
        if (item.groupKey === '02.logistics') {
          this.comNum = item.quantity
        } else if (item.groupKey === '03.driver') {
          this.driNum = item.quantity
        } else {
          this.shipNum = item.quantity
        }
      })
      this.userNum = number2
      var arr3 = res.data.yearlyRevenueTrendList
      var arr4 = []
      arr3.forEach((item) => {
        arr4.push(item.totalAmount)
        if (item.month === '1') {
          this.dic1 = item.monthlyData
        } else if (item.month === '2') {
          this.dic2 = item.monthlyData
        } else if (item.month === '3') {
          this.dic3 = item.monthlyData
        } else if (item.month === '4') {
          this.dic4 = item.monthlyData
        } else if (item.month === '5') {
          this.dic5 = item.monthlyData
        } else if (item.month === '6') {
          this.dic6 = item.monthlyData
        } else if (item.month === '7') {
          this.dic7 = item.monthlyData
        } else if (item.month === '8') {
          this.dic8 = item.monthlyData
        } else if (item.month === '9') {
          this.dic9 = item.monthlyData
        } else if (item.month === '10') {
          this.dic10 = item.monthlyData
        } else if (item.month === '11') {
          this.dic11 = item.monthlyData
        } else if (item.month === '12') {
          this.dic12 = item.monthlyData
        }
      })
      this.monthList = arr4
      this.drawLine()
      this.drawLine1()
      this.drawLine2()
      this.drawLine3()
    },
    drawLine1() {
      // console.log("开始画饼图")
      // 基于准备好的dom，初始化echarts实例
      this.myChart1 = this.$echarts.init(document.getElementById('myChart1'))
      // 绘制图表
      var text = ''
      var format = ''
      if (!this.isAuth('/home/tab4')) {
        text = '会员用户：*****人'
        format = '{**} <br/>{**} : {**}人 ({**}%)'
      } else {
        text = '会员用户：' + this.memberNum + '人'
        format = '{a} <br/>{b} : {c}人 ({d}%)'
      }
      this.myChart1.setOption({
        title: {
          text: text, // 主标题
          subtext: '', // 副标题
          x: 'left',
          textStyle: {
            fontSize: 14
          } // x轴方向对齐方式
        },
        tooltip: {
          trigger: 'item',
          formatter: format
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.defaultNum,
                name: '普通会员',
                itemStyle: {
                  color: '#6686FA'
                }
              },
              {
                value: this.silveNum,
                name: '银卡会员',
                itemStyle: {
                  color: '#1890FF'
                }
              },
              {
                value: this.goldNum,
                name: '金卡会员',
                itemStyle: {
                  color: '#F59A23'
                }
              }
            ],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3
            }
          }
        ]
      })
    },
    drawLine2() {
      // console.log("开始画饼图")
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(document.getElementById('myChart2'))
      // 绘制图表
      var text = ''
      var format = ''
      if (!this.isAuth('/home/tab4')) {
        text = '注册用户：*****人'
        format = '{**} <br/>{**} : {**}人 ({**}%)'
      } else {
        text = '注册用户：' + this.userNum + '人'
        format = '{a} <br/>{b} : {c}人 ({d}%)'
      }
      this.myChart2.setOption({
        title: {
          text: text, // 主标题
          subtext: '', // 副标题
          x: 'left',
          textStyle: {
            fontSize: 14
          } // x轴方向对齐方式
        },
        tooltip: {
          trigger: 'item',
          formatter: format
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            data: [
              {
                value: this.shipNum,
                name: '货主',
                itemStyle: {
                  color: '#F59A23'
                }
              },
              {
                value: this.driNum,
                name: '车主',
                itemStyle: {
                  color: '#1890FF'
                }
              },
              {
                value: this.comNum,
                name: '物流公司',
                itemStyle: {
                  color: '#6686FA'
                }
              }
            ],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3
            }
          }
        ]
      })
    },
    drawLine3() {
      // console.log("开始画饼图")
      // 基于准备好的dom，初始化echarts实例
      this.myChart3 = this.$echarts.init(document.getElementById('myChart3'))
      // 绘制图表
      var text = ''
      var format = ''
      if (!this.isAuth('/home/tab4')) {
        text = '充值金额：*****元'
        format = '{**} <br/>{**} : {**}元 ({**}%)'
      } else {
        text = '充值金额：' + this.quantity + '元'
        format = '{a} <br/>{b} : {c}元 ({d}%)'
      }
      this.myChart3.setOption({
        title: {
          text: text, // 主标题
          subtext: '', // 副标题
          x: 'left',
          textStyle: {
            fontSize: 14
          } // x轴方向对齐方式
        },
        tooltip: {
          trigger: 'item',
          formatter: format
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            center: '50%,50%',
            data: [
              {
                value: this.deNum,
                name: '押金充值',
                itemStyle: {
                  color: '#1890FF'
                }
              },
              {
                value: this.czNum,
                name: '会员充值',
                itemStyle: {
                  color: '#6686FA'
                }
              },
              {
                value: this.blNum,
                name: '零钱充值',
                itemStyle: {
                  color: '#F59A23'
                }
              }
            ],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 3
            }
          }
        ]
      })
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      var format = ''
      if (this.isAuth('/home/tab2')) {
        format = null
      } else {
        format = '***'
      }
      var that = this
      this.myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: '#F2F2F2', // 背景颜色（此时为默认色）
          borderRadius: 8, // 边框圆角
          padding: 10, // [5, 10, 15, 20] 内边距
          triggerOn: 'mousemove',
          enterable: true,
          formatter: function (params) {
            var name = params[0].name
            var tip = '2022' + ' . ' + name + '<br/>'
            var result = {}
            if (name === '1月') {
              result = that.dic1
            } else if (name === '2月') {
              result = that.dic2
            } else if (name === '3月') {
              result = that.dic3
            } else if (name === '4月') {
              result = that.dic4
            } else if (name === '5月') {
              result = that.dic5
            } else if (name === '6月') {
              result = that.dic6
            } else if (name === '7月') {
              result = that.dic7
            } else if (name === '8月') {
              result = that.dic8
            } else if (name === '9月') {
              result = that.dic9
            } else if (name === '10月') {
              result = that.dic10
            } else if (name === '11月') {
              result = that.dic11
            } else if (name === '12月') {
              result = that.dic12
            }
            if (!that.isAuth('/home/tab2')) {
              tip += '<br/>' + '累计收款' + ' : ¥******'
              tip += '<br/>' + '累计押金' + ' : ¥******'
              tip += '<br/>' + '金卡会员' + ' : ¥******'
              tip += '<br/>' + '银卡会员' + ' : ¥******'
            } else {
              var number =
                parseFloat(result.totalDeposit) +
                parseFloat(result.totalGoldenAmount) +
                parseFloat(result.totalSilverAmount)
              // tip += '<br/>' + '累计收款' + ' : ¥ ' + result.totalIncome
              tip += '<br/>' + '累计收款' + ' : ¥ ' + number

              tip += '<br/>' + '累计押金' + ' : ¥ ' + result.totalDeposit
              tip += '<br/>' + '金卡会员' + ' : ¥ ' + result.totalGoldenAmount
              tip += '<br/>' + '银卡会员' + ' : ¥ ' + result.totalSilverAmount
            }
            return tip
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '10%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.xData,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            axisLabel: { formatter: '{value}元' },
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            barWidth: '60%',
            itemStyle: {
              color: '#8BA4FF'
            },
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              show: true,
              position: 'top',
              formatter: format
            },
            data: this.monthList
          }
        ]
      })
    },
    handleStartChange(e) {
      if (!this.isAuth('/home/tab4')) {
        return false
      }
      this.queryInfo.province = e[0]
      this.queryInfo.city = e[1]
      if (e[2] !== '全市') {
        this.queryInfo.district = e[2]
      } else this.queryInfo.district = ''
      this.getMainData(this.queryInfo)
      var dic = {
        pageNum: 1,
        pageSize: 100000,
        isMember: 1
      }
      dic.city = e[1]
      dic.province = e[0]
      if (e[2] !== '全市') {
        dic.district = e[2]
      } else dic.district = ''
      this.getMemberNumber(dic)
    },
    refersh() {
      if (!this.isAuth('/home/tab4')) {
        return false
      }
      this.queryInfo.province = ''
      this.queryInfo.city = ''
      this.searchStartCity = []
      this.getMainData()
      var dic = {
        pageNum: 1,
        pageSize: 100000,
        isMember: 1
      }
      this.getMemberNumber(dic)
    },
    async getMemberNumber(dic) {
      const { data: res } = await this.$http.get('/logisticsCompany/list', {
        params: dic
      })
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.memberNum = res.total
      var number = 0
      var number1 = 0
      var number2 = 0
      res.data.forEach((item) => {
        if (item.memberLevelId === 3) {
          number2++
        } else if (item.memberLevelId === 2) {
          number1++
        } else {
          number++
        }
      })
      this.defaultNum = number
      this.silveNum = number1
      this.goldNum = number2
      // const myChart = this.$echarts.init(document.getElementById('myChart1'))
      var option = this.myChart1.getOption()
      var list = option.series[0].data
      list.forEach((item) => {
        if (item.name === '普通会员') {
          item.value = this.defaultNum
        } else if (item.name === '银卡会员') {
          item.value = this.silveNum
        } else {
          item.value = this.goldNum
        }
      })
      this.myChart1.clear()
      var text = '会员人数：' + res.total + '人'
      option.title[0].text = text
      this.myChart1.setOption(option)
    }
  }
}
</script>

<style scoped>
.infoHeader {
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.elCacader {
  margin-left: 20px;
}
.main {
  width: 100%;
}
.topView {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.top-left {
  width: 50%;
  height: 302px;
  background-color: white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}
.title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 16px;
  margin-left: 16px;
}
.revenueView {
  width: 100%;
}
.revenueItem {
  float: left;
  width: 21%;
  height: 91px;
  margin-top: 10px;
  margin-left: 3%;
  background-color: #6686fa;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.itemTitle {
  font-size: 20px;
  color: white;
  width: 96%;
  text-align: center;
}
.itemView {
  float: left;
  width: 45%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-left: 3%;
}
.item-left {
  width: 11px;
  height: 29px;
  border-radius: 10px;
}
.itemOtherTitle {
  width: 132px;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}
.itemNumber {
  width: 70px;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}
.unit {
  width: 10px;
  height: 10px;
  background-color: #8ba4ff;
}
.bottom-left {
  width: 83%;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.bottom-left-top {
  width: 100%;
  height: 117px;
  background-color: white;
}
.bottom-left-bottom {
  width: 100%;
  height: 269px;
  background-color: white;
  margin-top: 20px;
}
.bottom-right {
  width: 15%;
  height: 400px;
  margin-left: 2%;
  background-color: white;
  padding: 0 20px;
}
.item {
  float: left;
  width: 8.5%;
  height: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  margin-left: 16px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
}
.memTitle {
  font-size: 10px;
  color: #999;
  font-weight: bold;
}
.chartView {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.examineView {
  width: 100%;
  height: 60px;
  border-radius: 9px;
  background-color: #6686fa;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.examineType {
  font-size: 16px;
  color: white;
}
.examineNumber {
  font-size: 24px;
  color: white;
  font-weight: bold;
}
.itemViewOther {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
</style>
