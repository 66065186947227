<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>车主管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑车主</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 物流卡片视图区 -->
    <el-card>
      <div style="margin-bottom:20px;" @click="goBack">
        <i class="el-icon-back"></i>
        <span style="margin-left:10px;">返回</span>
      </div>
      <div style="display:flex;">
        <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="120px" size='mini' class="addfrom" label-position="right">
          <div class="itemView">
            <el-row class="dialog-row">
              <el-col :span="24">
                <div class="head">身份证信息</div>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="选择头像:" prop="avatar">
                  <!-- <el-upload :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleAvatarRemove" :on-success="handleAvatarSuccess" :file-list="avatarFileList" multiple :class="{hide:avatarHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleAvatarRemove" :on-success="handleAvatarSuccess" :file-list="avatarFileList" multiple :class="{hide:avatarHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleAvatarRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="昵称/姓名:" prop="nickName">
                  <el-input class="dialog-input" v-model="addForm.nickName" placeholder="请输入昵称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="手机号:" prop="phone">
                  <el-input class="dialog-input" v-model="addForm.phone" placeholder="请输入手机号" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="身份证:" prop="idCardNo">
                  <el-input class="dialog-input" v-model="addForm.idCardNo" placeholder="请输入身份证号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="上传身份证:" prop="idCardFrontUrl">
                  <!-- <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdFrontRemove" :on-success="handlelegalPersonIdFrontSuccess" :file-list="frontFileList" multiple :class="{hide:frontHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdBackRemove" :on-success="handlelegalPersonIdBackSuccess" :file-list="backFileList" multiple :class="{hide:backHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdFrontRemove" :on-success="handlelegalPersonIdFrontSuccess" :file-list="frontFileList" multiple :class="{hide:frontHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleLelegalPersonIdFrontRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleLelegalPersonIdBackRemove" :on-success="handlelegalPersonIdBackSuccess" :file-list="backFileList" multiple :class="{hide:backHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <!-- <img class="el-upload-list__item-thumbnail"  :src="file.url" alt="">fit="cover" -->
                      <el-image class="el-upload-list__item-thumbnail"  :src="file.url"></el-image>
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleLelegalPersonIdBackRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-form :model="addVehicleForm" ref="addVehicleFormRef" :rules="addVehicleFormRules" label-width="120px" size='mini' class="addfrom" label-position="right">
          <div class="itemView">
            <el-row class="dialog-row">
              <el-col :span="24">
                <div class="head">车辆信息</div>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="车牌号:" prop="licenseNo">
                  <el-input class="dialog-input" v-model="addVehicleForm.licenseNo" placeholder="请输入车牌号"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="长宽高(米):" prop="vehicleSize">
                  <el-input class="dialog-input" v-model="addVehicleForm.vehicleSize" placeholder="请输入长宽高"></el-input>
                </el-form-item>
              </el-col>
            </el-row> -->
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="载重(吨):" prop="loadCapacity">
                  <el-input class="dialog-input" v-model="addVehicleForm.loadCapacity" placeholder="请输入载重"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="车辆类型:" prop="vehicleType">
                  <el-cascader clearable style="width:100%" class="dialog-input" v-model="carType" :props="defultProp" :options="carTypeList" @change="handleChange"></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="上传行驶证:" prop="vehicleLicenseOriginalUrl">
                  <!-- <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleLicenseOriginalRemove" :on-success="handleVehicleLicenseOriginalSuccess" :file-list="vehicleLicenseOriginalFileList" multiple :class="{hide:vehicleLicenseOriginalHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleLicenseEctypeRemove" :on-success="handleVehicleLicenseEctypeSuccess" :file-list="vehicleLicenseEctypeFileList" multiple :class="{hide:vehicleLicenseEctypeHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleLicenseOriginalRemove" :on-success="handleVehicleLicenseOriginalSuccess" :file-list="vehicleLicenseOriginalFileList" multiple :class="{hide:vehicleLicenseOriginalHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleVehicleLicenseOriginalRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleLicenseEctypeRemove" :on-success="handleVehicleLicenseEctypeSuccess" :file-list="vehicleLicenseEctypeFileList" multiple :class="{hide:vehicleLicenseEctypeHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleVehicleLicenseEctypeRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="dialog-row">
              <el-col :span="24">
                <el-form-item label="车辆照片:" prop="vehicleFrontUrl">
                  <!-- <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleFrontRemove" :on-success="handleVehicleFrontSuccess" :file-list="vehicleFrontFileList" multiple :class="{hide:vehicleFrontHidet}">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleBackRemove" :on-success="handleVehicleBackSuccess" :file-list="vehicleBackFileList" multiple :class="{hide:vehicleBackHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleFrontRemove" :on-success="handleVehicleFrontSuccess" :file-list="vehicleFrontFileList" multiple :class="{hide:vehicleFrontHidet}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleVehicleFrontRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleVehicleBackRemove" :on-success="handleVehicleBackSuccess" :file-list="vehicleBackFileList" multiple :class="{hide:vehicleBackHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleVehicleBackRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="unit">*上传车辆左前方和右后方照片</div>
          </div>
        </el-form>
        <el-form :model="addVehicleForm" ref="addVehicleFormRef" :rules="addVehicleFormRules" label-width="120px" size='mini' class="addfrom" label-position="right">
          <div class="itemView" style="width:500px;margin-left:30px;">
            <el-row class="dialog-row">
              <el-col :span="24">
                <div class="head">驾驶证信息</div>
              </el-col>
            </el-row>
            <el-row class="dialog-row" style="wdith:100%;">
              <el-col :span="24">
                <el-form-item label="上传驾驶证:" prop="drivingLicenseOriginalUrl">
                  <!-- <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleDrivingLicenseOriginalRemove" :on-success="handleDrivingLicenseOriginalSuccess" :file-list="drivingLicenseOriginalFileList" multiple :class="{hide:drivingLicenseOriginalHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <!-- <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleDrivingLicenseEctypeRemove" :on-success="handleDrivingLicenseEctypeSuccess" :file-list="drivingLicenseEctypeList" multiple :class="{hide:drivingLicenseEctypeHide}">
                    <i class="el-icon-plus"></i>
                  </el-upload> -->
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleDrivingLicenseOriginalRemove" :on-success="handleDrivingLicenseOriginalSuccess" :file-list="drivingLicenseOriginalFileList" multiple :class="{hide:drivingLicenseOriginalHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleDrivingLicenseOriginalRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-upload style="width:148px;height:148px;" :action="uploadUrl" :headers="headers" list-type="picture-card" accept="image/png,image/jpg,image/jpeg" :limit='1' :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" :on-remove="handleDrivingLicenseEctypeRemove" :on-success="handleDrivingLicenseEctypeSuccess" :file-list="drivingLicenseEctypeList" multiple :class="{hide:drivingLicenseEctypeHide}">
                    <i class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <img class="examine-Image" v-if="file.state==0" src="../../assets/592@2x.png" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleDrivingLicenseEctypeRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="unit">*上传驾驶证正本和副本照片</div>
          </div>
        </el-form>
      </div>
      <!-- 底部区域 -->
      <span class="dialog-footer">
        <el-button @click="goBack">取 消</el-button>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
      </span>
    </el-card>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 添加设备表单数据
      addForm: {
        avatar: '',
        phone: '',
        nickName: '',
        idCardNo: '',
        idCardBackUrl: '',
        idCardFrontUrl: ''
      },
      addFormRules: {
        phone: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          {
            min: 0,
            max: 11,
            message: '手机号长度11位数字',
            trigger: 'blur'
          }
        ],
        nickName: [
          { required: true, message: '请填写昵称', trigger: 'change' }
        ],
        idCardNo: [
          { required: true, message: '请填写身份证号', trigger: 'change' }
        ],
        idCardFrontUrl: [
          { required: true, message: '请上传身份证图片', trigger: 'change' }
        ]
      },
      uploadUrl: 'https://api.sansongkeji.com/files/upload/logo',
      // uploadUrl: 'http://mirrortest.lzs-iot.com:8806/files/upload/logo',
      addVehicleFormRules: {
        vehicleType: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        licenseNo: [
          { required: true, message: '请填写车牌号', trigger: 'change' }
        ],
        drivingLicenseOriginalUrl: [
          { required: true, message: '请上传驾驶证图片', trigger: 'change' }
        ],
        vehicleFrontUrl: [
          { required: true, message: '请上传车辆图片', trigger: 'change' }
        ],
        vehicleLicenseOriginalUrl: [
          { required: true, message: '请上传行驶证图片', trigger: 'change' }
        ]
      },
      headers: {},
      addVehicleForm: {
        drivingLicenseEctypeUrl: '',
        drivingLicenseOriginalUrl: '',
        licenseNo: '',
        loadCapacity: '',
        vehicleBackUrl: '',
        vehicleFrontUrl: '',
        vehicleLicenseEctypeUrl: '',
        vehicleLicenseOriginalUrl: '',
        vehicleType: ''
      },
      defultProp: {
        label: 'name',
        value: 'name',
        children: 'lengthList',
        expandTrigger: 'hover'
      },
      dialogVisible: false,
      dialogImageUrl: '',
      carTypeList: [],
      carType: [],
      vehicleLicenseOriginalFileList: [],
      vehicleLicenseEctypeFileList: [],
      backFileList: [],
      frontFileList: [],
      vehicleBackFileList: [],
      vehicleFrontFileList: [],
      drivingLicenseEctypeList: [],
      drivingLicenseOriginalFileList: [],
      avatarFileList: [],
      vehicleLicenseOriginalHide: false,
      vehicleLicenseEctypeHide: false,
      backHide: false,
      frontHide: false,
      avatarHide: false,
      vehicleBackHide: false,
      vehicleFrontHidet: false,
      drivingLicenseEctypeHide: false,
      drivingLicenseOriginalHide: false
    }
  },
  created() {
    this.headers.Authorization = window.localStorage.getItem('token')
    this.loadOwnerDetail(this.$route.query.id)
    this.carTypeList = [
      {
        name: '小面包车',
        lengthList: [
          {
            name: '2.4米'
          }
        ]
      },
      {
        name: '中面包车',
        lengthList: [
          {
            name: '3.2米'
          }
        ]
      },
      {
        name: '厢式车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '平板车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      },
      {
        name: '高栏车',
        lengthList: [
          {
            name: '3.7米'
          },
          {
            name: '4.2米'
          },
          {
            name: '5.2米'
          },
          {
            name: '6.2米'
          },
          {
            name: '6.8米'
          },
          {
            name: '7.2米'
          },
          {
            name: '8.6米'
          },
          {
            name: '9.6米'
          },
          {
            name: '12米'
          },
          {
            name: '13米'
          },
          {
            name: '17.5米'
          }
        ]
      }
    ]
  },
  methods: {
    goBack() {
      this.$refs.addFormRef.resetFields()
      this.$refs.addVehicleFormRef.resetFields()
      this.$router.back(-1)
    },
    async loadOwnerDetail(id) {
      const { data: res } = await this.$http.get('/driverIdentity/detail/' + id)
      if (res.code !== 1000) return this.$message.error(res.msg)
      this.addForm = res.data.driverIdentityInfo
      this.addVehicleForm = res.data.vehicleInfo
      var arr = this.addVehicleForm.vehicleType.split('车')
      arr.forEach((item, index) => {
        console.log(index)
        if (index === 0) {
          var str = item + '车'
          arr.splice(0, 1, str)
        }
      })
      this.carType = arr
      this.frontFileList.push({
        url: res.data.driverIdentityInfo.idCardFrontUrl
      })
      this.avatarFileList.push({
        url: res.data.driverIdentityInfo.avatar
      })
      this.backFileList.push({
        url: res.data.driverIdentityInfo.idCardBackUrl
      })
      this.vehicleLicenseOriginalFileList.push({
        url: res.data.vehicleInfo.vehicleLicenseOriginalUrl
      })
      this.vehicleLicenseEctypeFileList.push({
        url: res.data.vehicleInfo.vehicleLicenseEctypeUrl
      })
      this.vehicleFrontFileList.push({
        url: res.data.vehicleInfo.vehicleFrontUrl
      })
      this.vehicleBackFileList.push({
        url: res.data.vehicleInfo.vehicleBackUrl
      })
      this.drivingLicenseOriginalFileList.push({
        url: res.data.vehicleInfo.drivingLicenseOriginalUrl
      })
      this.drivingLicenseEctypeList.push({
        url: res.data.vehicleInfo.drivingLicenseEctypeUrl
      })
      this.vehicleLicenseOriginalHide = true
      this.vehicleLicenseEctypeHide = true
      this.backHide = true
      this.frontHide = true
      this.avatarHide = true
      this.vehicleBackHide = true
      this.vehicleFrontHidet = true
      this.drivingLicenseEctypeHide = true
      this.drivingLicenseOriginalHide = true
    },
    handleLelegalPersonIdFrontRemove(file, fileList) {
      this.addForm.idCardFrontUrl = ''
      this.frontFileList = []
      this.frontHide = false
    },
    handleLelegalPersonIdBackRemove(file, fileList) {
      this.addForm.idCardBackUrl = ''
      this.backFileList = []
      this.backHide = false
    },
    handleVehicleLicenseOriginalRemove(file, fileList) {
      this.addVehicleForm.vehicleLicenseOriginalUrl = ''
      this.vehicleLicenseOriginalFileList = []
      this.vehicleLicenseOriginalHide = false
    },
    handleVehicleLicenseEctypeRemove(file, fileList) {
      this.addVehicleForm.vehicleLicenseEctypeUrl = ''
      this.vehicleLicenseEctypeFileList = []
      this.vehicleLicenseEctypeHide = false
    },
    handleVehicleFrontRemove(file, fileList) {
      this.addVehicleForm.vehicleFrontUrl = ''
      this.vehicleFrontFileList = []
      this.vehicleFrontHidet = false
    },
    handleAvatarRemove(file, fileList) {
      this.addForm.avatar = ''
      this.avatarFileList = []
      this.avatarHide = false
    },
    handleVehicleBackRemove(file, fileList) {
      this.addVehicleForm.vehicleBackUrl = ''
      this.vehicleBackFileList = []
      this.vehicleBackHide = false
    },
    handleDrivingLicenseOriginalRemove(file, fileList) {
      this.addVehicleForm.drivingLicenseOriginalUrl = ''
      this.drivingLicenseOriginalFileList = []
      this.drivingLicenseOriginalHide = false
    },
    handleDrivingLicenseEctypeRemove(file, fileList) {
      this.addVehicleForm.drivingLicenseEctypeUrl = ''
      this.drivingLicenseEctypeList = []
      this.drivingLicenseEctypeHide = false
    },
    handlelegalPersonIdFrontSuccess(res, file, fileList) {
      this.addForm.idCardFrontUrl = res.data
      this.frontHide = fileList.length > 0
    },
    handleAvatarSuccess(res, file, fileList) {
      this.addForm.avatar = res.data
      this.avatarHide = fileList.length > 0
    },
    handlelegalPersonIdBackSuccess(res, file, fileList) {
      this.addForm.idCardBackUrl = res.data
      this.backHide = fileList.length > 0
    },
    handleVehicleLicenseOriginalSuccess(res, file, fileList) {
      this.addVehicleForm.vehicleLicenseOriginalUrl = res.data
      this.vehicleLicenseOriginalHide = fileList.length > 0
    },
    handleVehicleLicenseEctypeSuccess(res, file, fileList) {
      this.addVehicleForm.vehicleLicenseEctypeUrl = res.data
      this.vehicleLicenseEctypeHide = fileList.length > 0
    },
    handleVehicleFrontSuccess(res, file, fileList) {
      this.addVehicleForm.vehicleFrontUrl = res.data
      this.vehicleFrontHidet = fileList.length > 0
    },
    handleVehicleBackSuccess(res, file, fileList) {
      this.addVehicleForm.vehicleBackUrl = res.data
      this.vehicleBackHide = fileList.length > 0
    },
    handleDrivingLicenseOriginalSuccess(res, file, fileList) {
      this.addVehicleForm.drivingLicenseOriginalUrl = res.data
      this.drivingLicenseOriginalHide = fileList.length > 0
    },
    handleDrivingLicenseEctypeSuccess(res, file, fileList) {
      this.addVehicleForm.drivingLicenseEctypeUrl = res.data
      this.drivingLicenseEctypeHide = fileList.length > 0
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      var testmsg = /^image\/(jpeg|png|jpg|gif|bmp)$/.test(file.type)
      const isLt1M = file.size / 1024 / 1024 < 3
      if (!testmsg) {
        this.$message.error('上传图片格式不正确！')
      }
      if (!isLt1M) {
        this.$message.warning('上传图片大小不能超过3MB!')
      }
      return isLt1M
    },
    handleExceed() {
      this.$message.error('只能上传一张图片')
    },
    handleChange(e) {
      this.addVehicleForm.vehicleType = e[0] + e[1]
    },
    submitForm() {
      var uploadDic = {}
      uploadDic.addMode = 'web'
      uploadDic.driverIdentityInfo = this.addForm
      this.addVehicleForm.loadCapacity = parseInt(
        this.addVehicleForm.loadCapacity
      )
      uploadDic.vehicleInfo = this.addVehicleForm
      if (this.addForm.avatar === '') {
        this.$message.info('请上传车主头像')
        return false
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) {
          this.$message.info('请填写正确的车主信息')
          return false
        }
        this.$refs.addVehicleFormRef.validate(async (VehicleValid) => {
          if (!VehicleValid) {
            this.$message.info('请填写正确的车辆信息')
            return false
          }
          const { data: res } = await this.$http.post(
            '/driverIdentity/update',
            uploadDic
          )
          if (res.code !== 1000) return this.$message.error(res.msg)
          this.$message.success('修改成功')
          this.goBack()
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  width: 80px;
  height: 40px;
  line-height: 44px;
  margin-right: 20px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-form-item__content {
  display: flex;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  width: 100%;
}
.head {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.addfrom {
  width: 28%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.unit {
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: rgba(102, 102, 102, 0.2);
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.el-upload--picture-card {
  margin-right: 8px;
}
</style>

<style scoped>
.el-pagination {
  display: flex;
  justify-content: flex-end;
}
.dialog-row {
  width: 98%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<style>
.hide .el-upload--picture-card {
  display: none !important;
}
</style>
